import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { debounceTime, map, Subject } from "rxjs";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { RequestFilter } from "src/common/utilities/request";
import { FunctionCall, isDialogOpen } from "src/common/utilities/utilities";
import { APIListResult, ObjectOrReference } from "src/services/models/api-object";
import { AppNotification } from "src/services/models/appNotification";
import { Case } from "src/services/models/case";
import { WorkflowInstance } from "src/services/models/workflow";
import {
    AssignmentService,
    WorkflowInstanceService,
} from "src/services/program.services";
import { AssignmentComponent } from "../assignment/assignment.component";
import { Assignment } from "src/services/models/assignment";
import { AssignmentGroupComponent } from "../assignment/assignment-group.component";
import { TaskFilterOption, TaskFilterOptionAlt } from "../case/case.component";
import { PrivacySettings } from "src/services/models/organization";

@Component({
    selector: "workflow-stack",
    templateUrl: "./workflow-stack.component.html",
    styleUrls: ["./workflow-stack.component.scss"],
})
export class WorkflowStackComponent extends ObjectAdminComponent<WorkflowInstance> {
    @Input() case?: ObjectOrReference<Case>;
    @Input() viewOnly = true;
    @Input() notifications: AppNotification[] = [];
    @Input() filterMode?: TaskFilterOption | TaskFilterOptionAlt = undefined;
    private _stepUpdate: number | undefined;
    @Input()
    get stepUpdate(): number | undefined {
        return this._stepUpdate;
    }
    set stepUpdate(value: number | undefined) {
        this._stepUpdate = value;
        if (value !== undefined) {
            this.step = value;
            this.stepUpdated.emit();
        }
    }
    @Input() privacySettings: PrivacySettings | undefined;
    @Output() stepUpdated = new EventEmitter();
    @ViewChildren(AssignmentGroupComponent)
    assignmentGroups!: QueryList<AssignmentGroupComponent>;

    get showAll() {
        return (
            this.filterMode === TaskFilterOption.All || this.filterMode === undefined
        );
    }
    _step: number = 0;
    get step(): number {
        return this._step;
    }
    set step(value: number) {
        this._step = value;
    }

    isSubworkflow(instance: ObjectOrReference<WorkflowInstance>): boolean {
        return instance instanceof WorkflowInstance && instance.is_subworkflow;
    }

    protected assignmentService: AssignmentService;
    private readonly clickDebounce: Subject<FunctionCall> = new Subject<FunctionCall>();

    constructor(
        protected service: WorkflowInstanceService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, -1, "workflow-stack");

        this.assignmentService = inject(AssignmentService);

        this.clickDebounce.pipe(debounceTime(400)).subscribe((fc: FunctionCall) => {
            fc.fn(...fc.args);
        });
    }

    get isCaseClosed(): boolean {
        return (
            this.case instanceof Case &&
            this.case?.shared.case_status.attributes?.closes_case
        );
    }
    get isPhysicianStaff(): boolean {
        return this.case instanceof Case && !!this.case?.is_physician;
    }

    protected postSearch(items: WorkflowInstance[]): WorkflowInstance[] {
        items = super.postSearch(items);
        items.sort((a: WorkflowInstance, b: WorkflowInstance) => a.order - b.order);
        return items;
    }

    createTodo(event: MouseEvent, instance: ObjectOrReference<WorkflowInstance>): void {
        if (this.list.items.indexOf(instance) == this.step) event.stopPropagation();
        this.clickDebounce.next({
            fn: this._createTodo.bind(this), // need to bind this to retain context
            args: [event, instance],
        });
    }
    protected _createTodo(
        event: MouseEvent,
        instance: ObjectOrReference<WorkflowInstance>,
    ): void {
        if (isDialogOpen(this.dialog, AssignmentComponent)) return;
        this.assignmentService
            .list({
                workflow: instance.id ?? "0",
                completed: "False",
            })
            .pipe(
                map(
                    (list: APIListResult<Assignment>) =>
                        list as ObjectOrReference<Assignment>[],
                ),
            )
            .subscribe((assignments: ObjectOrReference<Assignment>[]) => {
                // find the assignment group for the workflow instance
                const group = this.assignmentGroups.find(
                    (g: AssignmentGroupComponent) => g.workflow?.id === instance.id,
                );
                const assignment = group?.createObject(event) as AssignmentComponent;
                assignment.shipmentForm = this.asObject(instance)?.shipment_form;
                assignment.otherAssignments = assignments;
            });
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters["case"] = this.case?.id ?? "0";
        return filters;
    }

    protected onObjectCreated(o: ObjectOrReference<WorkflowInstance>): void {
        super.onObjectCreated(o);
        const index = this.list.items.indexOf(o);
        if (index != -1 && o instanceof WorkflowInstance) this.step = o.order;
    }
}
