import { ChangeDetectorRef, Component, Input, SimpleChanges } from "@angular/core";
import { SearchableListComponent } from "src/common/components/searchable-list.component";
import { RequestFilter } from "src/common/utilities/request";
import { CapabilityService } from "src/services/iam.services";
import { Capability } from "src/services/models/capability";
import { Organization } from "src/services/models/organization";
import { CapabilityComponent } from "./capability.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { MatDialogConfig } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";

@Component({
    selector: "capability-admin",
    templateUrl: "./capability-admin.component.html",
    styleUrls: ["../organization.component.scss"],
})
export class CapabilityAdminComponent extends SearchableListComponent<Capability> {
    objectView = CapabilityComponent;
    @Input() organization?: Organization;
    @Input() isPharma = false;
    @Input() hideSystemRoles = false;
    constructor(
        protected service: CapabilityService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "capability-list");
    }

    get displayedColumns(): string[] {
        return ["display_name", "owner_id", "actions"];
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        const ids = [];
        if (this.organization?.id) ids.push(this.organization.id);
        if (!this.hideSystemRoles) ids.push("0");
        if (ids.length == 0) ids.push("-1");
        filters["org"] = ids.join(",");

        filters["capacity"] = this.isPharma ? "pharma" : "physician";
        return filters;
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }
    ngOnChanges(changes: SimpleChanges) {
        if (
            changes?.hideSystemRoles?.currentValue !==
            changes?.hideSystemRoles?.previousValue
        ) {
            this.list.refresh();
        }
    }
    protected objectDialogConfiguration(
        object: Capability,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);

        return {
            ...config,
            width: "50%",
        };
    }

    newObject(data?: any): Capability | undefined {
        const obj = super.newObject(data);
        if (!obj) {
            return undefined;
        }

        obj.owner = this.organization?.asReference;
        obj.capacity = this.isPharma ? "pharma" : "physician";
        return obj;
    }

    canEdit(object: Capability) {
        const isSystemProvided = !object.owner;

        return !isSystemProvided;
    }
}
