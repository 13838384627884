import { Component, Input, inject } from "@angular/core";
import { Case, Status } from "src/services/models/case";
import { ObjectComponent } from "src/common/components/object.component";
import { CaseService, InquiryService } from "src/services/program.services";
import { UntypedFormControl } from "@angular/forms";
import { Inquiry } from "src/services/models/inquiry";
import { OrganizationService } from "src/services/iam.services";
import { CaseTeam, TeamMember } from "src/services/models/team";
import { DataFormService } from "src/services/data.services";
import { BdcWalkService } from "third-party/bdc-walkthrough/src/public-api";

@Component({
    selector: "case-overview",
    templateUrl: "./case-overview.component.html",
    styleUrls: ["./case-overview.component.scss"],
})
export class CaseOverviewComponent extends ObjectComponent<Case> {
    editingProperties: boolean = false;
    nameControl: UntypedFormControl = new UntypedFormControl();
    organizationService: OrganizationService;
    dataFormService: DataFormService;
    organizationLogo?: string;

    _case?: Case;
    @Input() viewOnly = false;
    @Input() set case(v: Case) {
        if (!v.shared.teams) {
            this.nameControl.disable();
        }
        this.nameControl.enable();
        this._case = v;
    }
    get isEditor(): boolean {
        return !!this.case?.isEditor(this.currentAccount);
    }
    get isAdmin(): boolean {
        return !!this.case?.isAdmin(this.currentAccount);
    }
    get case() {
        return this._case!;
    }
    get isPharmaStaff(): boolean {
        return !this.isPhysicianStaff;
    }
    get isPhysicianStaff(): boolean {
        return !!this.fullObject?.is_physician;
    }
    get caseTeam(): CaseTeam | undefined {
        return this.fullObject?.caseTeam(this.currentAccount);
    }

    get isAdministrator(): boolean {
        return !!this.fullObject?.isAdmin(this.currentAccount);
    }
    get inquiry(): Inquiry | undefined {
        return this.fullObject?.shared;
    }
    get physician(): TeamMember | undefined {
        return this.fullObject?.physician;
    }
    get physicianName(): string {
        return this.fullObject?.physicianName ?? "Physician Not Specified";
    }
    get patientInstitution(): string {
        return this.fullObject?.physicianInstitution ?? "Institution Not Specified";
    }
    inquiryService: InquiryService;
    constructor(
        protected service: CaseService,
        private bdcWalkService: BdcWalkService,
    ) {
        super(service);
        this.organizationService = inject(OrganizationService);
        this.dataFormService = inject(DataFormService);
        this.inquiryService = inject(InquiryService);
    }

    get isPhysician(): boolean {
        return !!this.case?.isPhysicianStaff(this.currentAccount);
    }

    get walkthroughEnabled(): boolean {
        return this.walkthroughRendered && !this.completedWalkthrough;
    }

    get caseStatus() {
        return this.fullObject?.shared.case_status;
    }

    toggleEdit(event?: Event): void {
        this.terminateEvent(event);
        if (this.viewOnly) return;
        if (!this.isEditor) return;

        if (!this.editingProperties) this.editingProperties = true;
        else {
            this.editingProperties = false;
            if (this.walkthroughEnabled) {
                this.bdcWalkService.setTaskCompleted("doctorTask10");
            }

            if (this.nameControl.value && this.nameControl.value != "") {
                this.onAutosave({ name: this.nameControl.value });
            }
        }
    }
    handleKeyDown(event: KeyboardEvent): void {
        super.handleKeyDown(event);
        if (this.editingProperties && event.keyCode === 13 && !event.shiftKey) {
            // Commit edit
            this.toggleEdit();
        } else if (this.editingProperties && event.keyCode === 27 && !event.shiftKey) {
            // Cancel Edit
            this.editingProperties = false;
            this.nameControl.setValue(this.fullObject?.name);
        }
    }

    protected setObject(v?: Case): void {
        super.setObject(v);
        this.nameControl.setValue(v?.name);
        if (this.fullObject?.shared.organization) {
            this.organizationService
                .logo(this.fullObject.shared.organization)
                .subscribe({
                    next: (url: string) => {
                        const random = "?random+=" + Math.random();
                        this.organizationLogo =
                            url ?
                                this.organizationService.session.environment.services +
                                url +
                                random
                            :   undefined;
                    },
                    error: () => (this.organizationLogo = undefined),
                });
        } else {
            this.organizationLogo = undefined;
        }
    }
    @Input()
    caseStatuses?: Status[] = [];
    rejectCase(event: MouseEvent | undefined, status?: Status): void {
        this.terminateEvent(event);
        this.inquiryService.close(
            this.fullObject?.shared!,
            false,
            status,
            this.caseStatuses?.filter((s) => s.attributes?.closes_case),
        );
    }
    updateStatus(_event: MouseEvent | undefined, status: Status) {
        if (status.id != this.fullObject?.shared.case_status?.id) {
            if (!status?.attributes.closes_case) {
                const inquiry = this.fullObject!.shared;
                inquiry.case_status = status;
                this.inquiryService.update(inquiry).subscribe((response) => {
                    this.fullObject?.update({ shared: response });
                });
            } else {
                this.rejectCase(undefined, status);
            }
        }
    }

    canUpdateStatus(status: Status): boolean {
        const canCloseCase = ["object.admin", "object.manager", "organization.manager"];

        if (status.attributes.closes_case) {
            const override = this.caseTeam?.overrideForAccount(this.currentAccount);
            const inheritedMember = this.caseTeam?.inherited.find(
                (tm: TeamMember) => tm.account.id == this.currentAccount?.id,
            );

            return (
                canCloseCase.includes(override!.permissionLevel) ||
                (canCloseCase.includes(inheritedMember!.permissionLevel) && !override)
            );
        }

        return this.isAdmin || !status.attributes.closes_case;
    }
}
