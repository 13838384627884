import { TeamComponent } from "./../team/team.component";
import { map, mergeMap } from "rxjs/operators";
import { TeamMemberService } from "./../../../services/program.services";
import {
    AccountService,
    CapabilityService,
    RoleService,
} from "src/services/iam.services";
import { Role, RoleDefinition } from "src/services/models/role";
import { Team, TeamMember, CaseTeam } from "src/services/models/team";
import { AfterViewInit, Component, Inject, inject, OnInit } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import { Account } from "../../../services/models/account";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
} from "@angular/forms";
import { EmailValidator } from "src/common/utilities/validators";
import { HelpDialogComponent } from "../help-dialog/help-dialog.component";
import { forkJoin, Observable, of } from "rxjs";
import { APIListResult, ObjectFactory } from "src/services/models/api-object";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Capability } from "src/services/models/capability";
import { RelatedProgram } from "src/services/models/inquiry";
import { Organization } from "src/services/models/organization";

@Component({
    selector: "add-role",
    templateUrl: "./add-role.dialog.html",
    styleUrls: ["./case.component.scss"],
})
export class AddRoleDialog extends SessionComponent implements OnInit, AfterViewInit {
    protected formBuilder: UntypedFormBuilder;
    protected accountService: AccountService;
    protected teamMemberService: TeamMemberService;
    protected roleService: RoleService;
    protected capabilityService: CapabilityService;
    loading = false;

    formGroup: UntypedFormGroup;
    newMember?: TeamMember;
    readOnly: boolean = false;

    get member(): TeamMember | undefined {
        return this.data.member;
    }
    get wantsInvite(): boolean {
        return this.data.invite;
    }
    get staffTeam(): Team {
        return this.data.staffTeam;
    }
    get caseTeam(): CaseTeam | undefined {
        return this.data.caseTeam;
    }
    get availableStaff(): TeamMember[] {
        const staff = this.member ? [this.member] : (this.staffTeam?.members ?? []);
        const orgCaseStaff = this.orgStaffMember ?? [];
        return [...staff, ...orgCaseStaff, ...this.inviteOnlyMembers].filter(
            (value, index, self) =>
                index === self.findIndex((v) => v.account.id === value.account.id),
        );
    }
    get availableRoles(): RoleDefinition[] {
        return TeamMember.DefinedRoles.filter((rd: RoleDefinition) => {
            return (
                rd.type == "system" ||
                (!this.isExternal && rd.type == this.caseTeam?.capacity) ||
                (this.isExternal && rd.type != this.caseTeam?.capacity)
            );
        }).filter((rd: RoleDefinition) => !this.isExternal || rd.value != "admin");
    }
    get availableAccess(): RoleDefinition[] {
        return Role.roles.filter((rd: RoleDefinition) => rd.type == "object");
    }
    get isPharmaStaff(): boolean {
        return this.caseTeam?.capacity == "pharma";
    }
    get availableTeams(): CaseTeam[] {
        return this.data.object.teams;
    }
    orgStaffMember?: TeamMember[] = [];
    inviteOnlyMembers: TeamMember[] = [];
    pharmaCapabilities: Capability[] = [];
    providerCapabilities: Capability[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected dialogRef: MatDialogRef<AddRoleDialog>,
        public dialog: MatDialog,
    ) {
        super();

        this.formBuilder = inject(UntypedFormBuilder);
        this.accountService = inject(AccountService);
        this.teamMemberService = inject(TeamMemberService);
        this.roleService = inject(RoleService);
        this.capabilityService = inject(CapabilityService);
        this.updateOrgStaff();
        this.updateInviteOnlyMembers();
        this.updateCapabilities();
        this.newMember = ObjectFactory.makeObject<TeamMember>(
            {
                account: ObjectFactory.makeObject<Account>(
                    { first_name: "New", last_name: "User" },
                    Account.object_type,
                ),
                email: undefined,
            },
            TeamMember.object_type,
        ) as TeamMember;
        this.readOnly = this.data?.readOnly;

        this.formGroup = this.formBuilder.group({
            override: [data.caseTeam, Validators.required],
            member: [null, Validators.required],
            email: [null, EmailValidator],
            first_name: [null],
            last_name: [null],
            role: [null, Validators.required],
            capabilities: [null],
            access: [null, Validators.required],
        });
        if (this.readOnly) {
            this.formGroup.disable();
        }

        this.formGroup.controls.override.valueChanges.subscribe((value: CaseTeam) =>
            this.onAccessTypeChanged(value),
        );
        this.formGroup.controls.member.valueChanges.subscribe((value: any) =>
            this.onAccountChanged(value),
        );
        this.formGroup.controls.role.valueChanges.subscribe((value: any) =>
            this.onRoleChanged(value),
        );
    }

    ngOnInit() {
        this.formGroup.get("member")?.valueChanges.subscribe((value) => {
            if (!this.validMemberPermission(this.formGroup.get("access")?.value)) {
                this.formGroup.get("access")?.reset();
            }
        });
    }

    get capabilities(): Capability[] {
        return (
                (this.formGroup?.value.override?.capacity ?? this.caseTeam?.capacity) ==
                    "pharma"
            ) ?
                this.pharmaCapabilities
            :   this.providerCapabilities;
    }

    updateCapabilities() {
        const org = this.caseTeam?.team.organization as Organization;
        const allowSystemRoles = !org.hideSystemRoles;

        const orgIds = [];
        if (allowSystemRoles) orgIds.push("0");
        orgIds.push(
            this.availableTeams.find((ct) => ct.capacity == "pharma")?.team
                ?.organization?.id,
        );
        this.capabilityService
            .list({
                org: orgIds.join(","),
                capacity: "pharma",
            })
            .subscribe((v) => {
                this.pharmaCapabilities = v as Capability[];
            });

        const physicianIds = [];
        if (allowSystemRoles) physicianIds.push("0");
        physicianIds.push(
            this.availableTeams.find((ct) => ct.capacity == "provider")?.team
                ?.organization?.id,
        );
        this.capabilityService
            .list({
                org: physicianIds.join(","),
                capacity: "physician",
            })
            .subscribe((v) => {
                this.providerCapabilities = v as Capability[];
            });
    }

    updateOrgStaff() {
        this.teamMemberService
            .list({ org: this.data.object.owner.id })
            .pipe(map((v: APIListResult<TeamMember>) => v as TeamMember[]))
            .subscribe((v: TeamMember[]) => {
                //any team member added to any of the orgs cases but not added to org settings
                this.orgStaffMember = v;
            });
    }

    updateInviteOnlyMembers() {
        const inviteOnlyRoles = ["object.view", "object.edit"];
        const relatedProgramIds = this.data.object.shared.related_programs.map(
            (p: RelatedProgram) => p.program.id,
        );
        const relatedProgramCountryIds = this.data.object.shared.related_programs
            .map((p: RelatedProgram) => p.program_countries.map((pc: any) => pc.id))
            .flat();
        const relatedTeamOwnerIds = [...relatedProgramIds, ...relatedProgramCountryIds];

        this.teamMemberService
            .list({
                role: inviteOnlyRoles.join(","),
                owned: relatedTeamOwnerIds.join(","),
            })
            .pipe(map((v: APIListResult<TeamMember>) => v as TeamMember[]))
            .subscribe((v: TeamMember[]) => {
                this.inviteOnlyMembers = v;
            });
    }

    validMemberPermission(permission: string): boolean {
        const selectedTeamMember = this.formGroup.controls.member.value;
        const isExplicitTeamMember = !selectedTeamMember?.override;
        const isViewOnlyUser = !!(
            selectedTeamMember?.permission?.role == "object.view"
        );
        const isRestricedUser = isExplicitTeamMember && isViewOnlyUser;
        if (
            isRestricedUser &&
            permission != "object.view" &&
            permission != "object.none"
        ) {
            return false;
        }
        return true;
    }

    ngAfterViewInit(): void {
        this.formGroup.controls.override.setValue(this.caseTeam);

        if (this.member) {
            const capabilities = this.member.capabilities;

            this.formGroup.controls.capabilities.setValue(capabilities);
            this.formGroup.controls.role.setValue(this.member.role);
            // try to find the highest permission value the member has, if any
            const permissions = this.member.permission?.role.split("|");
            let perm = this.availableAccess
                .reverse()
                .find((rd: RoleDefinition) =>
                    permissions?.find((p: string) => p == rd.value),
                );
            if (!perm)
                perm = Role.roles.find((r: RoleDefinition) => r.value == "object.edit");
            this.formGroup.controls.access.setValue(perm?.value);
            this.formGroup.controls.member.setValue(this.member);
        }
    }
    capabilityDisplay(member: TeamMember): string {
        return this.caseTeam?.capabilityDisplayForMember(member) ?? "";
    }
    hasAccess(member: TeamMember): boolean {
        return !!this.caseTeam?.members.find(
            (tm: TeamMember) =>
                tm.account.id == member.account.id && !this.accessRevoked(tm),
        );
    }
    accessRevoked(member: TeamMember): boolean {
        return member.permission?.role == "object.none";
    }
    protected onAccountChanged(account: any): void {
        if (account === this.newMember) {
            this.formGroup.controls.email.setValidators([
                EmailValidator,
                Validators.required,
            ]);
            this.formGroup.controls.first_name.setValidators(Validators.required);
            this.formGroup.controls.last_name.setValidators(Validators.required);
        } else {
            this.formGroup.controls.email.setValidators([]);
            this.formGroup.controls.first_name.setValidators([]);
            this.formGroup.controls.last_name.setValidators([]);
        }
        this.formGroup.controls.email.updateValueAndValidity();
        this.formGroup.controls.first_name.updateValueAndValidity();
        this.formGroup.controls.last_name.updateValueAndValidity();
    }
    protected onAccessTypeChanged(override: CaseTeam): void {
        if (override.id != this.caseTeam?.id) {
            // external users have to be new users
            this.formGroup.controls.member.setValue(this.newMember);
        }
        let editAccess = Role.roles.find(
            (r: RoleDefinition) => r.value == "object.edit",
        );
        let memberRole = TeamMember.DefinedRoles.find(
            (r: RoleDefinition) => r.value == "member",
        );

        if (!this.member) {
            this.formGroup.controls.role.setValue(memberRole?.value);
            this.formGroup.controls.access.setValue(editAccess?.value);
        }
    }
    protected onRoleChanged(role: string): void {
        if (role == "owner") {
            this.formGroup.controls.access.disable(); // owners don't have specific permissions
        } else {
            this.formGroup.controls.access.enable();
        }
    }

    get valid(): boolean {
        return this.formGroup.valid;
    }
    get email(): UntypedFormControl {
        return this.formGroup.controls.email as UntypedFormControl;
    }
    get isExternal(): boolean {
        return (
            this.formGroup.controls.override.value?.id != this.caseTeam?.id ||
            this.data?.isExternal
        );
    }

    get roleDisplay(): string {
        let roles = [];
        for (const r of this.member?.role.split("|") ?? ["member"]) {
            const definition = TeamMember.DefinedRoles.find(
                (rd: RoleDefinition) => rd.value == r,
            );
            if (definition) roles.push(definition.display);
        }
        if (!roles.length) roles = ["Team Member"];
        return roles.join(", ");
    }
    get accessDisplay(): string {
        let roles = [];
        const permission = this.member?.permissionLevel;
        const definition = Role.roles.find(
            (rd: RoleDefinition) => rd.value == permission,
        );
        return definition?.display ?? "Unknown Permission";
    }

    openHelpWindow() {
        this.dialog.open(HelpDialogComponent, {
            width: "25%",
            position: {
                right: "0px",
                bottom: "0px",
            },
        });
    }

    get caseTeamMembers(): any[] {
        return this.allTeamMembers.filter((tm) => {
            return (
                tm.permission?.object?.type == "program.case" ||
                tm.permission?.object?.type == "program.inquiry"
            );
        });
    }
    get orgTeamMembers(): any[] {
        return this.allTeamMembers.filter((tm) => {
            return (
                tm.permission?.object?.type == "program.program" ||
                tm.permission?.object?.type == "program.programcountry" ||
                tm.permission?.object?.type == "iam.organization"
            );
        });
    }

    get allTeamMembers(): any[] {
        const caseTeam1 = this.availableTeams[0]?.members ?? [];
        const caseTeam2 = this.availableTeams[1]?.members ?? [];
        return [...new Set([...caseTeam1, ...caseTeam2])];
    }

    getMemberByEmail(email: string) {
        const teamMembers = this.allTeamMembers.filter((tm) => tm.email == email);
        return (
            teamMembers.find((tm) => tm.permission?.object.type == "program.case") ??
            teamMembers[0] ??
            null
        );
    }

    onSave(): void {
        this.loading = true;

        const newEmail =
            this.formGroup.controls.email.value ??
            this.formGroup.controls.member.value?.email;

        const orgMember = this.orgTeamMembers.find((tm) => tm.email == newEmail);
        const caseMember = this.caseTeamMembers.find((tm) => tm.email == newEmail);

        const memberByEmail = this.getMemberByEmail(newEmail);

        const member = memberByEmail ?? this.formGroup.controls.member.value;

        let obs: Observable<TeamMember | undefined> = of(member);
        if (!orgMember && !caseMember && member == this.newMember) {
            // New access with new account, either internal or external
            const account = ObjectFactory.makeObject<Account>(
                {
                    email: this.formGroup.controls.email.value,
                    first_name: this.formGroup.controls.first_name.value,
                    last_name: this.formGroup.controls.last_name.value,
                    send_invite: this.formGroup.controls.access.value !== "object.none",
                },
                Account.object_type,
            );
            obs = (account ? this.accountService.create(account) : of(undefined)).pipe(
                mergeMap((account: Account | undefined) => {
                    if (!account) {
                        // Error creating account, TODO: handle this
                        return of(undefined);
                    } else {
                        const caseTeam = this.formGroup.controls.override.value;
                        let access =
                            this.isExternal ? "object.view" : (
                                this.formGroup.controls.access.value
                            );
                        const isPrivate = !this.isExternal;
                        const member = ObjectFactory.makeObject<TeamMember>(
                            {
                                override: caseTeam,
                                account: account,
                                role: this.formGroup.controls.role.value,
                                capabilities:
                                    this.formGroup.controls.capabilities.value ?? [],
                                permission: ObjectFactory.makeObject<Role>(
                                    {
                                        object: this.data.object,
                                        account: account,
                                        role: access,
                                        private: isPrivate,
                                    },
                                    Role.object_type,
                                ),
                                private: isPrivate,
                            },
                            TeamMember.object_type,
                        );
                        return member ?
                                this.teamMemberService.create(member)
                            :   of(undefined);
                    }
                }),
            );
        } else if (
            !orgMember &&
            !!caseMember &&
            this.formGroup.controls.access.value == "object.none"
        ) {
            this.teamMemberService.destroy(member).subscribe();
        } else if (!!caseMember) {
            const newMember = ObjectFactory.makeObject<TeamMember>(
                {
                    id: caseMember.id,
                    override: this.formGroup.controls.override.value,
                    account: member.account,
                    role: member?.role ?? "member",
                    capabilities: this.formGroup.controls.capabilities.value ?? [],
                    permission: ObjectFactory.makeObject<Role>(
                        {
                            id: caseMember.permission.id,
                            object: caseMember.permission.object,
                            account: caseMember.account,
                            role: this.formGroup.controls.access.value,
                            private: caseMember.private,
                        },
                        Role.object_type,
                    ),
                    private: caseMember.private,
                },
                TeamMember.object_type,
            );
            obs = newMember ? this.teamMemberService.update(newMember) : of(undefined);
        } else {
            const roles = this.formGroup.controls.role.value.split("|");

            if (!roles.length) roles.push("member");
            if (this.caseTeam)
                TeamComponent.setRoles(
                    this.caseTeam,
                    member,
                    this.teamMemberService,
                    roles,
                    this.formGroup.controls.access.value,
                    this.formGroup.controls.capabilities.value ?? [],
                );
        }

        obs.subscribe((member) => {
            this.dialogRef.close(member);
            this.loading = false;
        });
    }
    get canBeInvited(): boolean {
        return (
            this.caseTeam?.capacity == "provider" &&
            (!this.member?.is_active || this.member?.permission?.role == "object.none")
        );
    }
    get canEditCapabilities(): boolean {
        //per MED-2246, only admin can edit capabilities for members of their team
        return !!(
            this.isAdministrator &&
            this?.caseTeam?.memberForAccount(this.currentAccount)
        );
    }
    get canEditAccess(): boolean {
        const isCaseEditor =
            this.data.object?.shared?.isEditor(this.currentAccount) ?? false;
        const isStaffTeamAdmin =
            this.isAdministrator &&
            !!this.staffTeam?.members?.find(
                (tm) => tm.account.id == this.currentAccount?.id,
            ) &&
            !!this.currentAccount?.roles.find((r) => r.role == "object.admin");
        return isCaseEditor || isStaffTeamAdmin;
    }
    get permissions(): string {
        const tm = this.allTeamMembers.find(
            (tm) => tm.account.id == this.currentAccount?.id,
        );
        return tm?.permissionLevel ?? "object.none";
    }

    get isAdministrator(): boolean {
        return this.permissions === "object.admin";
    }
}
