import { Account } from "src/services/models/account";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
    inject,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, filter, first } from "rxjs/operators";
import { Sort } from "@angular/material/sort";
import { Role } from "src/services/models/role";
import { RoleService } from "src/services/iam.services";
import { RoleComponent } from "./role.component";
import { ObjectAdminComponent } from "../../../common/components/object-admin.component";
import { ObjectViewEntryPoint } from "src/common/components/object.component";
import { TeamMemberService, TeamService } from "src/services/program.services";
import { Team, TeamMember } from "src/services/models/team";
import { Observable, of } from "rxjs";
import { RequestFilter } from "src/common/utilities/request";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatFooterRowDef, MatTable } from "@angular/material/table";
import { APIListResult, ObjectFactory } from "src/services/models/api-object";

@Component({
    selector: "role-admin",
    templateUrl: "./role-list.component.html",
    styleUrls: ["./account.component.scss"],
})
export class RoleAdminComponent extends ObjectAdminComponent<Role> {
    objectView = RoleComponent;
    displayedColumns: string[] = ["object__type", "object__name", "role", "actions"];

    @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;
    @ViewChild(MatFooterRowDef, { static: true }) footerDef?: MatFooterRowDef;
    @ViewChild(MatTable, { static: true }) table?: MatTable<Document>;
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: ElementRef;

    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showSearch: boolean = false;
    teamService: TeamService;
    teamMemberService: TeamMemberService;

    @Input() account?: Account;

    constructor(
        protected service: RoleService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, -1);
        this.teamService = inject(TeamService);
        this.teamMemberService = inject(TeamMemberService);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => this.updateList(term));
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }
    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }
    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }
    onFocusOut(event: any): void {
        this.autoToggleSearch();
    }
    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    newObject(data?: any): Role {
        return {
            ...data,
            account: this.account,
        };
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["account"] = this.account?.id ?? "0";
        return filters;
    }

    editObject(
        event: MouseEvent | undefined,
        object: Role,
        asDialog?: boolean,
        viewOnly?: boolean,
    ): ObjectViewEntryPoint<Role> | undefined {
        const instance = super.editObject(event, object, asDialog, viewOnly);
        instance?.dialogReference
            ?.afterClosed()
            .pipe(first())
            .subscribe((v) => {
                this.handleOrgTeam(v);
            });
        return instance;
    }

    handleOrgTeam(role: Role): void {
        if (role?.object?.type !== "iam.organization") {
            return;
        }

        this.teamService
            .list({ organization: role.object.id! })
            .subscribe((orgTeams: APIListResult<Team>) => {
                const orgTeam = (orgTeams as Team[]).find(
                    (t: Team) => t.team_type === "staff",
                );
                const member = orgTeam?.members?.find(
                    (tm) => tm.account.id === role.account!.id,
                );

                let memberRole = "member";
                if (role.role.includes("admin")) {
                    memberRole = "admin";
                } else if (role.role.includes("manager")) {
                    memberRole = "manager";
                }
                let obs: Observable<undefined | TeamMember> = of(undefined);

                if (member !== undefined) {
                    const updated = ObjectFactory.makeObject(
                        {
                            ...member,
                            permission: role,
                            role: memberRole,
                        },
                        TeamMember.object_type,
                    ) as TeamMember;
                    obs = this.teamMemberService.update(updated);
                } else if (orgTeam) {
                    obs = this.teamMemberService.create(
                        new TeamMember({
                            team: orgTeam,
                            account: role.account,
                            private: role.private,
                            permission: role,
                            role: memberRole,
                        }),
                    );
                }

                obs.subscribe();
            });
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
