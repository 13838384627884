import { Component, inject, Input, ViewChild } from "@angular/core";
import {
    FormControl,
    FormGroup,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { FileUploader } from "ng2-file-upload";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import {
    conditionalUrlValidator,
    InstantErrorStateMatcher,
    OptionalEmailValidator,
} from "src/common/utilities/validators";
import { DataFormService } from "src/services/data.services";
import {
    AccountService,
    APIKeyService,
    OrganizationService,
} from "src/services/iam.services";
import { DataFormSettingsReference } from "src/services/models/data";
import { Organization, SystemObjectSettings } from "src/services/models/organization";
import { CapabilityAdminComponent } from "../capability/capability-admin.component";
import { IntakeService } from "src/services/program.services";
import { MatSelect } from "@angular/material/select";
import { IntakeSettings } from "src/services/models/intake";
import { ObjectReference, APIListResult } from "src/services/models/api-object";
import { debounceTime, filter, map, mergeMap, NEVER, Subscription } from "rxjs";
import { WebsocketObjectAction } from "src/common/utilities/request";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { APIKey } from "src/services/models/api-key";
import { contrast, getDefaultBgColor } from "src/common/utilities/utilities";
@Component({
    selector: "organization-properties",
    templateUrl: "./org-properties.component.html",
    styleUrls: ["../organization.component.scss"],
})
export class OrganizationProperties extends ObjectComponent<Organization> {
    @Input()
    isHcpStaff: boolean = false;

    @ViewChild(CapabilityAdminComponent)
    capabilityAdminComponent?: CapabilityAdminComponent;

    apiKeyService: APIKeyService;
    apiKeys: APIKey[] = [];
    apiKeyColumns: string[] = ["key", "name", "created", "actions"];
    editingAPIKey?: APIKey;
    apiKeyName: FormControl = new FormControl(); // for editing key names
    fileOver: boolean = false;
    uploader?: FileUploader;
    defaultHeaderBgColor: string = getDefaultBgColor();
    defaultHeaderWhite: string = "#ffffff";
    brandingBgColor: string = this.defaultHeaderBgColor;
    brandingTextColor: string = this.defaultHeaderWhite;
    templateMedaLogoColor: string = "brightness(100%)";
    previousSettingsObjectSettings: {
        hide_all?: boolean;
        hide_system_workflow?: boolean;
        hide_system_form?: boolean;
        hide_system_role?: boolean;
        hide_system_case_status?: boolean;
    } = {};

    disablePublicIntake: UntypedFormControl = new UntypedFormControl();
    get publicIntakeDisabled(): boolean {
        return !this.intakeSettingsGroup?.get("hosted_intake")?.value;
    }
    copyToClipboard(value: string): void {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                this.snackbar.open("Copied to clipboard", undefined, {
                    duration: 2000,
                });
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    }

    get intakeWeb(): string {
        const orgSlug = this.fullObject?.slug;
        return window.location.origin + "/intake/" + orgSlug;
    }

    intakeService: IntakeService;
    editSlug: boolean = false;

    intakeSettings?: IntakeSettings;
    intakeSettingsGroup: FormGroup;
    providerVersionToggle: boolean = false;
    patientVersionToggle: boolean = false;
    familyVersionToggle: boolean = false;

    @ViewChild("providerSelect") providerSelect?: MatSelect;
    @ViewChild("patientSelect") patientSelect?: MatSelect;
    @ViewChild("familySelect") familySelect?: MatSelect;
    accountService: AccountService;
    systemProvidedObjectSetting!: FormGroup;

    buildSystemObjectSettings(settings: SystemObjectSettings): void {
        const group: any = {};
        for (const key in settings) {
            if (settings.hasOwnProperty(key)) {
                group[key] = [settings[key as keyof SystemObjectSettings]];
            }
        }

        this.systemProvidedObjectSetting = this.formBuilder.group(group);
        if (settings.hide_all) {
            for (const key in this.systemProvidedObjectSetting.controls) {
                if (key !== "hide_all") {
                    this.systemProvidedObjectSetting
                        .get(key)
                        ?.disable({ emitEvent: false });
                }
            }
        }
        this.systemProvidedObjectSetting.setValue(settings, { emitEvent: false });
    }

    constructor(protected service: OrganizationService) {
        super(service);
        this.dataFormService = inject(DataFormService);
        this.apiKeyService = inject(APIKeyService);
        this.intakeService = inject(IntakeService);
        this.accountService = inject(AccountService);

        this.intakeSettingsGroup = this.formBuilder.group({
            allow_patient: [false],
            allow_family: [false],
            hcp: [undefined],
            patient: [undefined],
            family: [undefined],
            hosted_intake: [false],
            settings: [null],
        });
        this.intakeSettingsGroup.valueChanges.subscribe((v: any) =>
            this.intakeSettingsChanged(v),
        );
    }

    toggleSystemObjectSettings(hide: boolean): void {
        Object.keys(this.systemProvidedObjectSetting.controls).forEach((key) => {
            if (key !== "hide_all") {
                const control = this.systemProvidedObjectSetting.get(key);

                control?.setValue(hide, { emitEvent: false });
            }
        });
    }

    handleObjectSystemsSettingsChange(newValue: any): void {
        Object.keys(this.systemProvidedObjectSetting.controls).forEach((key) => {
            if (!(key in newValue)) {
                newValue[key] = this.systemProvidedObjectSetting.get(key)?.value;
            }
        });
        const value = { ...this.formGroup.value };
        value.settings.settings.system_object_settings = newValue;

        this.onAutosave(value);
    }

    get isOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.hasRole("object.admin", this.object);
    }

    exporting = false;
    exportSubscription?: Subscription;
    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.exportSubscription?.unsubscribe();
        this.exportSubscription = undefined;
    }
    exportConfig(event: MouseEvent): void {
        if (this.exporting) return;

        this.exporting = true;
        this.snackbar.open(
            "Your download may take a few minutes. You can find it in your downloads once completed.",
            undefined,
            { duration: 5000 },
        );

        this.service.exportConfig(this.fullObject!).subscribe();
    }

    public onColorPickerChange(type: string, data: any): void {
        if (type === "template_bgcolor") {
            const contrastVal = contrast(data.color);
            if (contrastVal === "light") {
                this.templateMedaLogoColor = "brightness(0%)";
                this.brandingTextColor = this.defaultHeaderBgColor;
            } else {
                this.templateMedaLogoColor = "brightness(100%)";
                this.brandingTextColor = this.defaultHeaderWhite;
            }

            this.settingsFormGroup
                .get("template_textcolor")
                ?.setValue(this.brandingTextColor);
        }

        setTimeout(() => {
            const control = this.settingsFormGroup.get(type);
            if (control) {
                control.setValue(data.color);
                control.markAsDirty();
            }
        }, 800);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.allowPatientIntake.valueChanges.subscribe((checked: boolean) => {
            if (checked) this.patientIntakeForm.enable({ emitEvent: false });
            else this.patientIntakeForm.disable({ emitEvent: false });
        });
        this.allowFamilyIntake.valueChanges.subscribe((checked: boolean) => {
            if (checked) this.familyIntakeForm.enable({ emitEvent: false });
            else this.familyIntakeForm.disable({ emitEvent: false });
        });
        this.exportSubscription = this.accountService.objectEvent
            .pipe(
                filter(
                    (msg) =>
                        msg.object?.id === this.currentAccount?.id &&
                        msg.action === WebsocketObjectAction.EXPORT,
                ),
            )
            .subscribe(() => (this.exporting = false));

        this.settingsFormGroup.get("contact_phone")?.valueChanges.subscribe((value) => {
            // Remove non-integer values
            const sanitizedValue = value?.replace(/\D/g, "");
            this.settingsFormGroup
                .get("contact_phone")
                ?.setValue(sanitizedValue, { emitEvent: false });
        });

        this.updateBranding();
        this.previousSettingsObjectSettings =
            this.fullObject?.settings?.settings?.system_object_settings || {};
    }
    get isAdministrator(): boolean {
        return this.isOrganizationAdministrator || this.isSystemAdministrator;
    }
    get isPharmaAdministrator(): boolean {
        return this.isAdministrator && !this.isHcpStaff;
    }
    dataForms: DataFormSettingsReference[] = [];
    dataFormService: DataFormService;
    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            name: [null, Validators.required],
            slug: [null, Validators.required],
            settings: this.formBuilder.group({
                shipment_form: [null],
                adverse_event: [
                    null,
                    Validators.pattern(
                        "^(https?://)([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([/\\w \\.-]*)*/?$",
                    ),
                ],
                webhook_url: [null],
                webhook_enabled: [null],
                settings: [null],
                contact_address: [null],
                contact_phone: [null],
                contact_email: [null, OptionalEmailValidator],
                template_bgcolor: [null],
                template_textcolor: [null],
            }),
        });
    }
    intakeSettingsLoaded = false;
    get hideSystemCaseStatuses() {
        return this.fullObject?.setting(
            "system_object_settings",
            "hide_system_case_status",
        );
    }
    protected setObject(v?: Organization) {
        super.setObject(v);

        this.disablePublicIntake.setValue(
            !!this.fullObject?.setting("intake", "disableHosted"),
        );
        if (this.fullObject?.id) {
            this.dataFormService
                .org_forms(this.fullObject)
                .subscribe((dfr: DataFormSettingsReference[]) => {
                    this.dataForms = dfr;
                    if (this.fullObject?.id) {
                        this.intakeService
                            .getSettings(this.fullObject)
                            .subscribe((settings: IntakeSettings) => {
                                this.updateIntakeSettings(settings);
                            });
                    }
                });

            if (this?.fullObject?.settings?.settings?.system_object_settings) {
                this.buildSystemObjectSettings(
                    this.fullObject.settings.settings.system_object_settings,
                );
                this.systemProvidedObjectSetting?.valueChanges
                    .pipe(debounceTime(500))
                    .subscribe((changes) => {
                        if (changes !== this.previousSettingsObjectSettings) {
                            if (
                                changes.hide_all !==
                                this.previousSettingsObjectSettings.hide_all
                            ) {
                                this.toggleSystemObjectSettings(changes.hide_all);
                            }

                            this.previousSettingsObjectSettings = changes;
                        }

                        this.handleObjectSystemsSettingsChange(
                            this.systemProvidedObjectSetting.value,
                        );
                    });
            }
        } else {
            this.dataForms = [];
        }

        this.updateBranding();
        this.updateAPIKeys();
        this.initializeUploader();
    }

    get disableAllSystemObjects(): boolean {
        return this?.systemProvidedObjectSetting?.get("hide_all")?.value ?? false;
    }

    get apiKeyLimit(): number {
        const limit = this.fullObject?.setting("apiKey", "limit");
        return limit ? Number(limit) : 5;
    }
    deleteAPIKey(event: MouseEvent, apiKey: APIKey): void {
        this.terminateEvent(event);
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: "Are you sure you want to delete this API key?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.apiKeyService.destroy(apiKey) : NEVER,
                ),
            )
            .subscribe(() => {
                this.updateAPIKeys();
            });
    }
    createAPIKey(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.apiKeys.length >= this.apiKeyLimit) return; // limit reached
        const key = new APIKey({ owner: this.fullObject?.asReference });
        this.apiKeyService.create(key).subscribe(() => this.updateAPIKeys());
    }
    copyAPIKey(event: MouseEvent, apiKey: APIKey): void {
        this.terminateEvent(event);
        navigator.clipboard.writeText(apiKey.api_key).then(
            () => {
                this.snackbar.open("API key copied to clipboard.", undefined, {
                    duration: 2000,
                });
            },
            () => {
                this.snackbar.open("Error copying API key to clipboard.", undefined, {
                    duration: 5000,
                });
            },
        );
    }
    editAPIKey(event: MouseEvent, apiKey: APIKey): void {
        this.terminateEvent(event);
        this.editingAPIKey = apiKey;
        this.apiKeyName.setValue(apiKey.name);
    }
    updateAPIKeyName(event: MouseEvent): void {
        this.terminateEvent(event);
        if (this.editingAPIKey) {
            const newName = this.apiKeyName.value;
            if (newName && this.editingAPIKey.name != newName) {
                this.editingAPIKey.name = this.apiKeyName.value;
                this.apiKeyService.update(this.editingAPIKey).subscribe(() => {
                    this.updateAPIKeys();
                    this.editingAPIKey = undefined;
                });
            }
        }
    }

    protected precommitTransform(v: any): any {
        if (this.exists) {
            this.usePatch = true;
        }

        if (this.mode != ObjectViewMode.Create) {
            if (v.settings.contact_address == "") v.settings.contact_address = null;
            else if (!v.settings.contact_address) delete v.settings.contact_address;
            if (v.settings.contact_phone == "") v.settings.contact_phone = null;
            else if (!v.settings.contact_phone) delete v.settings.contact_phone;
            if (v.settings.contact_email == "") v.settings.contact_email = null;
            else if (!v.settings.contact_email) delete v.settings.contact_email;
        }
        if (!this.currentAccount?.isSystemAdministrator) {
            // only a system admin can update entitlements
            delete v.entitlements;
        }
        if (v?.settings) {
            v.settings.shipment_form =
                this?.dataForms?.find((df) => df?.id === v?.settings?.shipment_form?.id)
                    ?.asReference ?? null;
            v.settings.organization = this.object?.asReference.serialize();
            v.settings.settings.system_object_settings =
                this.systemProvidedObjectSetting.value;
        }
        return v;
    }

    protected resetObject(v: Organization) {
        if (!v.settings) {
            const t: any = { ...v };
            delete t.settings;
            super.resetObject(t);
        } else super.resetObject(v);
        const settings: UntypedFormGroup = this.formGroup.get(
            "settings",
        ) as UntypedFormGroup;
        settings?.get("shipment_form")?.setValue(v?.settings?.shipment_form?.id);
    }

    updateObjectForm(): void {
        this.formGroup.removeControl("settings");
        this.formGroup.removeControl("slug");
    }
    protected onCommitSuccess(v: Organization): boolean {
        const ret = super.onCommitSuccess(v);
        this.snackbar.open("Saved changes Succesfully.", undefined, { duration: 2000 });
        return ret;
    }
    protected onCommitError(err: any): void {
        console.error(err);
        // possibly log this on Rollbar or any other logging service ---- TODO
        this.snackbar.open("Error saving changes.", undefined, { duration: 5000 });
    }

    protected updateAPIKeys(): void {
        if (this.fullObject?.id) {
            this.apiKeyService
                .list({ owner: this.fullObject.id })
                .pipe(map((res: APIListResult<APIKey>) => res as APIKey[]))
                .subscribe((keys: APIKey[]) => (this.apiKeys = keys));
        } else {
            this.apiKeys = [];
        }
    }

    protected updateBranding(): void {
        this.brandingBgColor =
            this.fullObject?.settings?.template_bgcolor ?? this.defaultHeaderBgColor;
        this.brandingTextColor =
            this.fullObject?.settings?.template_textcolor ?? this.defaultHeaderWhite;

        this.templateMedaLogoColor =
            contrast(this.brandingBgColor) === "light" ? "brightness(0%)" : (
                "brightness(100%)"
            );
    }

    get settingsFormGroup() {
        return this.formGroup.get("settings") as UntypedFormGroup;
    }
    get webHookControl() {
        return this.settingsFormGroup.get("webhook_url");
    }
    get displayNameControl() {
        return this.formGroup.get("name") as UntypedFormControl;
    }
    errorMatcher = new InstantErrorStateMatcher();
    get isProductionService() {
        return this.session.environment.isProductionService;
    }
    ngOnInit() {
        if (this.fullObject?.id) {
            this.service.retrieve(this.fullObject?.id, {}, true).subscribe((org) => {
                this.setObject(org);
            });
        }

        const webhookUrlControl = this.webHookControl;

        if (webhookUrlControl) {
            //have to add it here , to ensure environment is initialized
            const enforceHttpsOnly = this.isProductionService;

            webhookUrlControl.addValidators(conditionalUrlValidator(enforceHttpsOnly));
        }
    }
    protected dataFormForVersion(
        version?: ObjectReference,
    ): DataFormSettingsReference | undefined {
        // if we're the most recent form, we'll be in the root array
        let parent = this.dataForms.find(
            (f: DataFormSettingsReference) => f.id == version?.id,
        );
        if (!parent) {
            // if we're not in the root, search through the version lists for each root item
            parent = this.dataForms.find(
                (f: DataFormSettingsReference) =>
                    !!f.versions?.find(
                        (v: DataFormSettingsReference) => v.id == version?.id,
                    ),
            );
        }
        return parent;
    }

    toggleProviderVersion(): void {
        this.providerVersionToggle = !this.providerVersionToggle;
        if (this.providerVersionToggle) this.providerSelect?.open();
    }
    togglePatientVersion(): void {
        this.patientVersionToggle = !this.patientVersionToggle;
        if (this.patientVersionToggle) this.patientSelect?.open();
    }
    toggleFamilyVersion(): void {
        this.familyVersionToggle = !this.familyVersionToggle;
        if (this.familyVersionToggle) this.familySelect?.open();
    }
    providerOpenedChanged(opened: boolean): void {
        if (!opened) this.providerVersionToggle = false;
    }
    patientOpenedChanged(opened: boolean): void {
        if (!opened) this.patientVersionToggle = false;
    }
    familyOpenedChanged(opened: boolean): void {
        if (!opened) this.familyVersionToggle = false;
    }

    get providerIntakeForm(): FormControl {
        return this.intakeSettingsGroup.get("hcp") as FormControl;
    }
    get patientIntakeForm(): FormControl {
        return this.intakeSettingsGroup.get("patient") as FormControl;
    }
    get familyIntakeForm(): FormControl {
        return this.intakeSettingsGroup.get("family") as FormControl;
    }
    get allowPatientIntake(): FormControl {
        return this.intakeSettingsGroup.get("allow_patient") as FormControl;
    }
    get allowFamilyIntake(): FormControl {
        return this.intakeSettingsGroup.get("allow_family") as FormControl;
    }

    get providerForms(): DataFormSettingsReference[] {
        const providerForm = this.providerIntakeForm.value;
        const parentForm = this.dataFormForVersion(providerForm);
        if (this.providerVersionToggle) {
            let forms: DataFormSettingsReference[] = [];
            if (parentForm) forms = [parentForm, ...(parentForm.versions ?? [])];
            else if (providerForm) forms = [providerForm];
            forms.sort(
                (a: DataFormSettingsReference, b: DataFormSettingsReference) =>
                    (a.version ?? 0) - (b.version ?? 0),
            );
            forms.reverse();
            return forms;
        } else {
            let forms = [...this.dataForms];
            if (providerForm && providerForm !== parentForm) {
                const index = forms.findIndex(
                    (f: DataFormSettingsReference) => f == parentForm,
                );
                if (index != -1) forms[index] = providerForm;
                else forms = [providerForm, ...forms];
            }
            return forms;
        }
    }
    get patientForms(): DataFormSettingsReference[] {
        const form = this.patientIntakeForm.value;
        const parentForm = this.dataFormForVersion(form);
        if (this.patientVersionToggle) {
            let forms: DataFormSettingsReference[] = [];
            if (parentForm) forms = [parentForm, ...(parentForm.versions ?? [])];
            else if (form) forms = [form];
            forms.sort(
                (a: DataFormSettingsReference, b: DataFormSettingsReference) =>
                    (a.version ?? 0) - (b.version ?? 0),
            );
            forms.reverse();
            return forms;
        } else {
            let forms = [...this.dataForms];
            if (form && form !== parentForm) {
                const index = forms.findIndex(
                    (f: DataFormSettingsReference) => f == parentForm,
                );
                if (index != -1) forms[index] = form;
                else forms = [form, ...forms];
            }
            return forms;
        }
    }
    get familyForms(): DataFormSettingsReference[] {
        const form = this.familyIntakeForm.value;
        const parentForm = this.dataFormForVersion(form);
        if (this.familyVersionToggle) {
            let forms: DataFormSettingsReference[] = [];
            if (parentForm) forms = [parentForm, ...(parentForm.versions ?? [])];
            else if (form) forms = [form];
            forms.sort(
                (a: DataFormSettingsReference, b: DataFormSettingsReference) =>
                    (a.version ?? 0) - (b.version ?? 0),
            );
            forms.reverse();
            return forms;
        } else {
            let forms = [...this.dataForms];
            if (form && form !== parentForm) {
                const index = forms.findIndex(
                    (f: DataFormSettingsReference) => f == parentForm,
                );
                if (index != -1) forms[index] = form;
                else forms = [form, ...forms];
            }
            return forms;
        }
    }

    protected intakeSettingsChanged(v: any): /* NOSONAR */ void {
        if (this.fullObject && this.intakeSettings) {
            v["owner"] = this.fullObject?.asReference;
            const newSettings = new IntakeSettings(v);
            if (newSettings?.owner?.id === this.intakeSettings?.owner?.id)
                newSettings.id = this.intakeSettings.id;

            // check for changes
            let changed = false;
            if (!changed && newSettings.owner.id != this.intakeSettings?.owner?.id)
                changed = true;
            if (
                !changed &&
                newSettings.allow_family != this.intakeSettings?.allow_family
            )
                changed = true;
            if (
                !changed &&
                newSettings.allow_patient != this.intakeSettings?.allow_patient
            )
                changed = true;
            if (!changed && newSettings.hcp?.id != this.intakeSettings?.hcp?.id)
                changed = true;
            if (!changed && newSettings.patient?.id != this.intakeSettings?.patient?.id)
                changed = true;
            if (!changed && newSettings.family?.id != this.intakeSettings?.family?.id)
                changed = true;

            if (
                !changed &&
                newSettings.hosted_intake != this.intakeSettings?.hosted_intake
            )
                changed = true;
            // check for validity
            let valid = true;
            if (newSettings.allow_family && !newSettings.family) valid = false;
            if (newSettings.allow_patient && !newSettings.patient) valid = false;

            if (changed && valid) {
                this.intakeService
                    .updateSettings(newSettings)
                    .subscribe((settings: IntakeSettings) => {
                        this.updateIntakeSettings(settings);
                        this.snackbar.open("Saved changes Succesfully.", undefined, {
                            duration: 2000,
                        });
                    });
            }
        }
    }
    protected updateIntakeSettings(settings: IntakeSettings): void {
        this.intakeSettingsGroup
            .get("allow_family")
            ?.setValue(settings.allow_family, { emitEvent: false });
        this.intakeSettingsGroup
            .get("allow_patient")
            ?.setValue(settings.allow_patient, { emitEvent: false });
        this.intakeSettingsGroup
            ?.get("hosted_intake")
            ?.setValue(settings.hosted_intake, { emitEvent: false });

        if (!settings.allow_family) this.familyIntakeForm.disable({ emitEvent: false });
        else this.familyIntakeForm.enable({ emitEvent: false });
        if (!settings.allow_patient)
            this.patientIntakeForm.disable({ emitEvent: false });
        else this.patientIntakeForm.enable({ emitEvent: false });

        const hcpParent = this.dataFormForVersion(settings.hcp);
        const patientParent = this.dataFormForVersion(settings.patient);
        const familyParent = this.dataFormForVersion(settings.family);

        const hcpForm =
            hcpParent?.id == settings.hcp?.id ?
                hcpParent
            :   hcpParent?.versions?.find(
                    (f: DataFormSettingsReference) => f.id == settings.hcp?.id,
                );
        const patientForm =
            patientParent?.id == settings.patient?.id ?
                patientParent
            :   patientParent?.versions?.find(
                    (f: DataFormSettingsReference) => f.id == settings.patient?.id,
                );
        const familyForm =
            familyParent?.id == settings.family?.id ?
                familyParent
            :   familyParent?.versions?.find(
                    (f: DataFormSettingsReference) => f.id == settings.family?.id,
                );

        this.intakeSettingsGroup.get("hcp")?.setValue(hcpForm, { emitEvent: false });
        this.intakeSettingsGroup
            .get("patient")
            ?.setValue(patientForm, { emitEvent: false });
        this.intakeSettingsGroup
            .get("family")
            ?.setValue(familyForm, { emitEvent: false });

        this.intakeSettings = settings;
        this.intakeSettingsLoaded = true;
    }

    get templateLogoSource(): string | undefined {
        if (this.fullObject?.settings?.template_logo)
            return (
                this.service.session.environment.services +
                "/media/" +
                this.fullObject.settings.template_logo
            );
        return "";
    }

    removeLogo(event: MouseEvent): void {
        this.terminateEvent(event);
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: "Are you sure you want to delete the branding logo?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) =>
                    confirm ? this.service.removeLogo(this.fullObject!) : NEVER,
                ),
            )
            .subscribe((logo?: string) => {
                if (logo !== undefined && this.fullObject?.settings) {
                    this.fullObject.settings.template_logo = logo;
                } else if (this?.fullObject?.settings) {
                    //logo was deleted
                    this.fullObject.settings.template_logo = undefined;
                }
            });
    }

    protected initializeUploader(): void {
        this.service
            .logoUploader(this.object)
            .subscribe((uploader?: FileUploader) => (this.uploader = uploader));
    }
}
