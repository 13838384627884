<object-form
    [ngClass]="{ 'padded absolute': !dialogReference }"
    [controller]="controller"
    [formGroup]="formGroup"
    [showErrors]="mode == ObjectViewMode.Create"
>
    <div class="no-padding" [class.account-view]="!dialogReference" cdkScrollable>
        <div
            class="flex stack"
            [style.marginTop]="'1rem'"
            [class.content-card]="mode != ObjectViewMode.Create && !dialogReference"
        >
            <mat-tab-group [(selectedIndex)]="selectedTabIndex">
                <ng-container>
                    <mat-tab label="Profile"></mat-tab>
                </ng-container>

                <!-- Permissions Tab -->
                <mat-tab label="Permissions" *ngIf="mode != ObjectViewMode.Create">
                    <role-admin
                        [account]="fullObject"
                        class="wrapper flex stack account-roles"
                        [class.content-card]="!dialogReference"
                        *ngIf="currentAccount?.isSystemAdministrator && fullObject?.id"
                    ></role-admin>

                    <div class="title-2" [style.marginLeft]="'1rem'">
                        Staff Permissions
                    </div>
                    <staff-permissions-list
                        [accountId]="object?.id"
                    ></staff-permissions-list>
                </mat-tab>
            </mat-tab-group>

            <!-- Profile tab contents -->
            <div *ngIf="selectedTabIndex === 0">
                <div
                    class="no-padding"
                    [class.account-view]="!dialogReference"
                    cdkScrollable
                >
                    <mat-form-field class="full" *ngIf="mode == ObjectViewMode.Create">
                        <mat-label>Email Address</mat-label>
                        <mat-icon matSuffix>email</mat-icon>
                        <input
                            matInput
                            placeholder="user@domain.com"
                            formControlName="email"
                        />
                        <mat-error *ngIf="emailControl?.hasError('memberOfOrgTeam')">
                            {{ accountIsAlreadyMemberMessage }}
                        </mat-error>
                        <mat-error
                            *ngIf="emailControl?.hasError('invalidEmailAddress')"
                        >
                            Please enter a valid email address.
                        </mat-error>
                    </mat-form-field>
                    <div class="flex full row">
                        <mat-form-field class="column flexible">
                            <mat-label>First Name</mat-label>
                            <input
                                matInput
                                placeholder="First Name"
                                formControlName="first_name"
                            />
                            <mat-error>This field is required.</mat-error>
                        </mat-form-field>
                        <mat-form-field class="column flexible">
                            <mat-label>Last Name</mat-label>
                            <input
                                matInput
                                placeholder="Last Name"
                                formControlName="last_name"
                            />
                            <mat-error>This field is required.</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="full">
                        <mat-label>Phone Number (optional)</mat-label>
                        <mat-icon matSuffix>phone</mat-icon>
                        <input
                            matInput
                            placeholder="(201)555-1234"
                            formControlName="phone"
                        />
                    </mat-form-field>
                    <mat-form-field *ngIf="capabilities.length" class="full">
                        <mat-label>Role</mat-label>
                        <mat-select formControlName="capability" multiple>
                            <mat-option
                                *ngFor="let capability of capabilities"
                                [value]="capability"
                            >
                                {{ capability.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        class="full"
                        *ngIf="currentAccount?.isSystemAdministrator"
                    >
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option value="active">Active</mat-option>
                            <mat-option value="inactive">Inactive</mat-option>
                            <mat-option [value]="null">Not Set</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div
                        class="wrapper flex stack flexible"
                        *ngIf="mode == ObjectViewMode.Create"
                    >
                        <mat-form-field class="full">
                            <mat-label>Permission</mat-label>
                            <mat-select
                                formControlName="permission"
                                placeholder="Select a Permission"
                            >
                                <mat-option
                                    *ngFor="let role of availableRoles"
                                    [disabled]="
                                        mode == ObjectViewMode.Create &&
                                        role.value === 'object.none'
                                    "
                                    [value]="role"
                                >
                                    {{ role.display }}
                                </mat-option>
                            </mat-select>
                            <mat-hint>
                                {{ addStaffPermissionHint }}
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div
                        *ngIf="
                            mode == ObjectViewMode.Create ||
                            (mode == ObjectViewMode.Edit &&
                                teamMember &&
                                isOrganizationAdministrator)
                        "
                    >
                        <mat-checkbox
                            name="publicControl"
                            [formControl]="publicControl"
                            [matTooltip]="publicCheckboxToolTip"
                        >
                            <mat-label>Public</mat-label>
                        </mat-checkbox>
                    </div>

                    <div
                        class="flex full account-actions"
                        *ngIf="mode != ObjectViewMode.Create"
                    >
                        <button
                            mat-raised-button
                            color="accent"
                            *ngIf="canResetPassword"
                            (click)="resetPassword($event)"
                        >
                            Reset Password
                        </button>
                        <button
                            mat-raised-button
                            color="accent"
                            (click)="updateEmail($event)"
                            *ngIf="canEditEmail"
                        >
                            Update Email
                        </button>
                        <button
                            mat-raised-button
                            color="accent"
                            *ngIf="canResendActivation"
                            (click)="resendActivationEmail($event)"
                        >
                            Resend Activation Email
                        </button>
                    </div>
                    <div
                        *ngIf="!dialogReference"
                        class="flex stack"
                        [class.content-card]="
                            mode != ObjectViewMode.Create && !dialogReference
                        "
                        formGroupName="settings"
                    >
                        <div class="title">Notification Preferences</div>

                        <div class="content-card half flex row">
                            <div class="flex row align-center">
                                <div style="margin-top: 6px">
                                    <mat-icon fontSet="material-icons-outlined">
                                        mail
                                    </mat-icon>
                                </div>

                                <div class="column">
                                    <p class="title">Email</p>
                                    <p class="subtitle">
                                        Email notifications
                                        @if (emailNotificationsEnabled) {
                                            will
                                        } @else {
                                            will not
                                        }
                                        be sent to your inbox.
                                    </p>
                                </div>
                            </div>

                            <div style="margin-top: 10px">
                                <mat-slide-toggle
                                    [matTooltip]="
                                        emailNotificationsEnabled ? 'Enabled' : (
                                            'Disabled'
                                        )
                                    "
                                    formControlName="email_notifications"
                                ></mat-slide-toggle>
                            </div>
                        </div>
                    </div>

                    <role-admin
                        [account]="fullObject"
                        class="wrapper flex stack account-roles"
                        [class.content-card]="!dialogReference"
                        *ngIf="
                            mode != ObjectViewMode.Create &&
                            currentAccount?.isSystemAdministrator &&
                            fullObject?.id &&
                            !dialogReference
                        "
                    ></role-admin>
                </div>
            </div>
        </div>
    </div>
</object-form>
