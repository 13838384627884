import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { Organization } from "./../../../../services/models/organization";
import { DataFormPropertiesComponent } from "./data-form-properties.component";
import { DataFormGroupEditorComponent } from "./data-form-group.component";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragEnd,
    CdkDragEnter,
    CdkDragExit,
    CdkDragMove,
    CdkDragRelease,
    CdkDragStart,
    CdkDropList,
} from "@angular/cdk/drag-drop";
import { DragDropService } from "src/common/utilities/drag-drop";
import { DataField, DataForm, DataFormField } from "src/services/models/data";
import { ObjectViewMode } from "./../../object.component";
import { Component, QueryList, ViewChild, ViewChildren, inject } from "@angular/core";
import { GenerateUniqueIdentifier } from "src/common/utilities/utilities";
import {
    DataFieldService,
    DataFormFieldConditionService,
    DataFormService,
} from "src/services/data.services";
import { debounceTime, filter, finalize, forkJoin, map, of, tap } from "rxjs";
import { DataFieldEditorComponent } from "../data-field/data-field.component";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
    objectsOnly,
} from "src/services/models/api-object";
import { defined } from "src/common/utilities/flatten";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "data-form-editor",
    templateUrl: "./data-form.component.html",
    styleUrls: ["./data-form.component.scss"],
})
export class DataFormEditorComponent extends DataFormComponent {
    static excludeDataTypes = ["instructions", "group"]; // Fields with this data type can be added multipe times

    @ViewChildren(CdkDropList) dropLists?: QueryList<CdkDropList>;
    @ViewChild(DataFormGroupEditorComponent)
    formGroupEditor?: DataFormGroupEditorComponent;

    datafieldService: DataFieldService;
    allFields: DataField[] = []; // these are the form fields avaialable form building
    availableFields: DataFormField[] = [];
    searchFilter?: string;
    loadingFields: boolean = true;
    searchTermControl: FormControl = new FormControl();

    autoScrollStep: number = 16; // how fast to scroll while dragging
    useDragHandles: boolean = false; // whether to use drag handles when building
    dragDropService: DragDropService;
    allowDropPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        return this.canDrop(drag, drop);
    };
    toolboxDropListIds: string[] = [];
    isDraggingToolboxField: boolean = false;

    siblingNames?: string[];
    formStructureHasChanged: boolean = false;

    // if we're editing the object and it's a template form, this is the form builder
    get isBuilder(): boolean {
        return (
            (this.mode == ObjectViewMode.Edit || this.mode == ObjectViewMode.Create) &&
            this.isTemplate
        );
    }
    get isTemplate(): boolean {
        return !this.fullObject?.template;
    }

    get _searchfilteredFields() {
        if (!this.searchFilter) {
            return this.availableFields;
        }

        return this.availableFields.filter((field: DataFormField) => {
            if (DataFormEditorComponent.isControlFormField(field)) return false;
            if (
                field.field.displayName &&
                field.field.displayName.toLowerCase().indexOf(this.searchFilter!) != -1
            )
                return true;
            if (
                field.field.name &&
                field.field.name.toLowerCase().indexOf(this.searchFilter!) != -1
            )
                return true;
            return false;
        });
    }

    get filteredFields(): DataFormField[] {
        const inUse = this.fullObject?.form_fields.map(
            (field) => (field as DataFormField).field.id,
        );

        return this._searchfilteredFields.filter((field: DataFormField) => {
            return (
                (!DataFormEditorComponent.isControlFormField(field) ||
                    !field.field.name?.startsWith("generic.")) &&
                inUse?.indexOf(field.field.id) === -1
            );
        });
    }

    get controlFields(): DataFormField[] {
        return this.availableFields.filter((field: DataFormField) => {
            return (
                DataFormEditorComponent.isControlFormField(field) &&
                (field.field.is_control_field ||
                    field.field.name?.startsWith("generic."))
            );
        });
    }

    get draggingOver(): boolean {
        const listId = this.dragDropService.currentHoverDropListId ?? "";
        return this.toolboxDropListIds.indexOf(listId) != -1;
    }

    constructor(protected service: DataFormService) {
        super(service);
        this.datafieldService = inject(DataFieldService);
        this.dragDropService = inject(DragDropService);
        inject(DataFormFieldConditionService);
    }

    duplicating = false;

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.dropLists) {
            this.dropLists.forEach((list: CdkDropList) => {
                this.dragDropService.register(list);
                this.toolboxDropListIds.push(list.id);
            });
        }

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.availableFields),
                filter((term: string) => !term || term.length >= 3),
            )
            .subscribe(
                (term: string) =>
                    (this.searchFilter = term ? term.toLowerCase() : undefined),
            );
    }

    onFocusOut(event: Event): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
        }
    }
    resetSearchFilter(event: MouseEvent): void {
        this.searchFilter = undefined;
        this.searchTermControl.setValue(undefined);
    }

    canDrop(drag: CdkDrag, drop: CdkDropList): boolean {
        let canDrop = false;
        if (this.isBuilder) {
            if (this.dragDropService.currentHoverDropListId == null) canDrop = true;
            else canDrop = drop.id === this.dragDropService.currentHoverDropListId;
        }
        return canDrop;
    }
    onDrop(event: CdkDragDrop<DataFormField>): void {
        // we don't allow reordering in the list, so the only drops we get will be removals
        if (event.container != event.previousContainer) {
            this.formGroupEditor?.removeFormField(event.item.data);
            this.onFormFieldRemoved(event.item.data);
        }
        event.item.data.group = undefined;
    }
    onDragStart(event: CdkDragStart): void {
        const formField = event.source.data;
        if (DataFormEditorComponent.isControlFormField(formField)) {
            const newFormField = new DataFormField({
                field: formField.field,
            });
            event.source.data = new DataFormField(newFormField);
        }
        event.source.data.isToolboxField = true;
        this.isDraggingToolboxField = true;
    }
    onDragEnter(event: CdkDragEnter): void {
        this.dragDropService.dragEntered(event);
    }
    onDragMoved(event: CdkDragMove): void {
        this.dragDropService.dragMoved(event);
    }
    onDragExit(event: CdkDragExit): void {
        this.dragDropService.dragExited(event);
    }
    onDragEnd(event: CdkDragEnd): void {
        this.isDraggingToolboxField = false;
    }
    onDragReleased(event: CdkDragRelease): void {
        this.dragDropService.dragReleased(event);
    }

    onConfigureForm(event: MouseEvent, form?: DataForm): void {
        this.dialog
            .open(DataFormPropertiesComponent, {
                data: {
                    form: form ?? this.fullObject,
                    namePrefix: (this.organization as Organization)?.slug,
                    siblings: this.siblingNames,
                    viewOnly: this.historyMode,
                    title: form ? `Version ${form?.version} Properties` : undefined,
                },
                width: "50vw",
                minWidth: "400px",
                maxHeight: "80vh",
                hasBackdrop: false,
                position: {
                    left: "1rem",
                    top: "1rem",
                },
            })
            .afterClosed()
            .subscribe(
                (changed: boolean) =>
                    (this.formStructureHasChanged =
                        changed || this.formStructureHasChanged),
            );
    }
    addDataField(event: MouseEvent): void {
        const newField = new DataField({
            owner: this.fullObject?.owner,
        });
        const ref = this.dialog.open(DataFieldEditorComponent, {
            minWidth: 480,
            maxWidth: "90vw",
            maxHeight: "90vh",
            disableClose: true,
            hasBackdrop: true,
        });
        const instance = ref.componentInstance;
        instance.dialogReference = ref;
        instance.mode = ObjectViewMode.Create;
        instance.autosave = false;
        instance.autosaveOnCreate = false;
        instance.object = newField;

        ref.afterClosed().subscribe((field?: DataField) => {
            if (field) {
                this.allFields.push(field);
                this.updateAvailableFields();
            }
        });
    }

    protected precommitTransform(v: any) {
        // JT -  explicitly ignoring the base class implementation of precommit - we don't need/want it
        // create reference ids for fields

        objectsOnly(this.fullObject?.form_fields, DataFormField).forEach(
            (field: DataFormField, index) => {
                if (this.duplicating || !field.id) {
                    const refId = "new-field" + index;
                    field.reference = refId;
                    if (field.id) {
                        field.id = undefined;
                    }
                    if (field.conditions && !field.id) {
                        field.conditions = field.conditions.map((c) => {
                            c.form_field = undefined;
                            c.form_field_ref = refId;
                            c.dependent_ref = (
                                c.dependent_field as DataFormField
                            ).field.id;
                            c.dependent_field = undefined;
                            return c;
                        });
                    }
                } else {
                    field.reference = field.id;
                }
            },
        );

        objectsOnly(this.fullObject?.form_fields, DataFormField).forEach(
            (field: DataFormField) => {
                if (field.group && field.group instanceof DataFormField) {
                    field.group_reference = field.group.reference;
                    field.group = undefined; // need to clear the group reference here to prevent a recursion issue in the backend serializer
                } else if (field.group && field.group instanceof ObjectReference) {
                    field.group_reference = field.group.id;
                    field.group = undefined;
                }

                field.conditions = field.conditions.map((c) => {
                    const dependentOn = objectsOnly(
                        this.fullObject?.form_fields,
                        DataFormField,
                    ).find((f) => f.field.id === c.dependent_ref);
                    c.dependent_ref = dependentOn?.reference;
                    return c;
                });
            },
        );
        if (
            ((this.fullObject && this.duplicating) ||
                (this.fullObject && !this.fullObject.name)) &&
            !this.historyMode
        ) {
            const prefix = (this.organization as Organization)?.slug;
            this.fullObject.name = GenerateUniqueIdentifier(
                prefix + "." + (this.fullObject.display_name || "unnamed"),
                this.siblingNames ?? [],
                "-",
            );
        }
        return this.fullObject;
    }
    loading = false;

    onSave(): void {
        this.loading = true;
        super.onSave();
    }

    protected onCommitSuccess(v: DataForm): boolean {
        this.loading = false;
        return super.onCommitSuccess(v);
    }

    protected onCommitError(err: any): void {
        this.loading = false;
        super.onCommitError(err);
    }
    get disabledReason(): string {
        const reasons = [];
        if (!this.formStructureHasChanged)
            reasons.push("The form has not been modified.");
        if (!this.formStructureIsValid) {
            if (!this.fullObject?.display_name)
                reasons.push("The form display name is invalid.");
            if (!this.fullObject?.form_fields.length)
                reasons.push("The form does not have any fields.");
        }
        const reason = reasons.join("\n");
        return reason;
    }

    get formStructureIsValid(): boolean {
        const hasDisplayName = !!this.fullObject?.display_name;
        const hasformFields = !!this.fullObject?.form_fields.length;
        return hasDisplayName && hasformFields;
    }

    protected getHasChanged(): boolean {
        const formValueHasChanged = super.getHasChanged();
        return this.isBuilder ? this.formStructureHasChanged : formValueHasChanged;
    }
    protected getIsValid(): boolean {
        const formValueIsValid = super.getIsValid();
        return this.isBuilder ? this.formStructureIsValid : formValueIsValid;
    }

    protected setObject(v?: DataForm): void {
        super.setObject(v);

        if (v instanceof DataForm) {
            this.updateAvailableFields();
            this.updateFields();

            this.updateSiblingNames(v);
        }

        this.formStructureHasChanged = false;
    }

    static isControlField(field: DataField): boolean {
        return (
            DataFormEditorComponent.excludeDataTypes.indexOf(
                field?.data_type?.name?.toLowerCase() ?? "",
            ) != -1 && !field.owner
        );
    }
    static isControlFormField(formField: ObjectOrReference<DataFormField>): boolean {
        return (
            formField instanceof DataFormField &&
            DataFormEditorComponent.isControlField(formField.field)
        );
    }
    protected controlForName(group: FormGroup, fieldName: string): AbstractControl {
        let control = group.controls[fieldName];
        if (!control) {
            for (let key of Object.keys(group.controls)) {
                const child = group.controls[key];
                if (child instanceof FormGroup)
                    control = this.controlForName(child, fieldName);
                if (control) break;
            }
        }
        return control;
    }
    protected controlNameForFormField(formField: DataFormField): string {
        let controlName = formField.field.name ?? "_unnamed";
        if (DataFormEditorComponent.isControlFormField(formField)) {
            // this is a multipe instance field, we need to give it a unique form control name
            const usedNames = this.getUsedFieldNames(this.formGroup);
            controlName = GenerateUniqueIdentifier(
                formField.field.name ?? "_unnamed",
                usedNames,
            );
        }
        return controlName;
    }
    protected controlForFormField(
        formField: DataFormField,
        controlName?: string,
    ): AbstractControl | undefined {
        if (!formField.previewControl && (controlName || formField.field.name))
            formField.previewControl = this.controlForName(
                this.formGroup,
                (controlName ?? formField.field.name)!,
            );
        if (!formField.previewControl)
            formField.previewControl = this.buildFormFieldControl(formField);
        return formField.previewControl;
    }
    protected formFieldForField(field: DataField): DataFormField {
        const formField = new DataFormField({
            field: field,
            required: false,
            multiple: false,
            order: 0,
        });

        // Add any default attributes that might be necessary based on the field type here
        if (
            field?.data_type?.isCompound &&
            (field.data_type.compound?.length || 0) < 3
        ) {
            formField.attributes = { layout: { compound: "inline" } }; // default compound fields to inline if they have 2 or fewer properties
        }

        let controlName = this.controlNameForFormField(formField);
        formField.previewControl = this.controlForFormField(formField, controlName);
        return formField;
    }

    protected updateSiblingNames(v?: DataForm): void {
        this.service.siblingNames(v, v?.owner).subscribe((names: string[]) => {
            this.siblingNames = names;
        });
    }

    protected updateFields(): void {
        if (this.isBuilder) {
            const repos = ["0"];
            if (this.fullObject?.owner?.id) repos.push(this.fullObject.owner.id);
            this.loadingFields = true;
            this.datafieldService
                .list({ full_serializer: "True", repo: repos.join(",") }) // get the full DataField object
                .pipe(finalize(() => (this.loadingFields = false)))
                .subscribe((fields: APIListResult<DataField>) => {
                    this.allFields = fields as DataField[];
                    this.updateAvailableFields();
                });
        } else {
            this.allFields = [];
            this.loadingFields = false;
        }
    }
    protected updateAvailableFields(): void {
        const usedFields = this.getUsedFieldNames(this.formGroup);
        const fields = this.allFields
            .filter((field: DataField) => {
                const used = usedFields.indexOf(field.name ?? "") != -1;
                return !used || DataFormEditorComponent.isControlField(field);
            })
            .map((field: DataField) => this.formFieldForField(field));
        this.availableFields = this.sortAvailableFields(fields);

        const resolveRefs = this.availableFields.map((field: DataFormField) => {
            if (field.field instanceof ObjectReference) {
                return ObjectFactory.objectObservable(field.field).pipe(
                    map((f) => {
                        if (f && field.field) {
                            field.field = f;
                        }
                        return field;
                    }),
                );
            }
            return of(field);
        });

        forkJoin(resolveRefs).subscribe();
    }
    protected sortAvailableFields(fields: DataFormField[]): DataFormField[] {
        const f = [...fields];
        f.sort((a: DataFormField, b: DataFormField) => {
            const _a = a.displayName?.toLowerCase() || "";
            const _b = b.displayName?.toLowerCase() || "";
            if (_a < _b) return -1;
            if (_a > _b) return 1;
            return 0;
        });
        return f;
    }
    protected onFormFieldAdded(formField: DataFormField, formGroup?: FormGroup): void {
        const controlName = this.controlNameForFormField(formField);
        if (!formField.previewControl)
            formField.previewControl = this.controlForFormField(formField, controlName);
        // add the previewcontrol into the form group
        if (controlName && formField.previewControl)
            formGroup?.addControl(controlName, formField.previewControl);

        if (!DataFormEditorComponent.isControlFormField(formField))
            this.availableFields = this.availableFields.filter(
                (field: DataFormField) => field != formField,
            );

        this.fullObject?.form_fields.push(formField);
        this.formStructureHasChanged = true;
    }

    protected onFormFieldRemoved(formField: DataFormField): void {
        // remove the previewcontrol from the formGroup
        if (
            formField.previewControl?.parent instanceof FormGroup &&
            formField.field.name
        )
            formField.previewControl.parent.removeControl(formField.field.name);

        if (this.isGroupField(formField)) {
            formField.children?.forEach((child: ObjectOrReference<DataFormField>) =>
                this.formGroupEditor?.removeFormField(child),
            );
        } else {
            if (!DataFormEditorComponent.isControlFormField(formField))
                this.availableFields = this.sortAvailableFields([
                    ...this.availableFields,
                    formField,
                ]);
        }
        if (this.fullObject)
            this.fullObject.form_fields = this.fullObject.form_fields.filter(
                (dff: ObjectOrReference<DataFormField>) => dff != formField,
            );

        this.formStructureHasChanged = true;
        this.updateAvailableFields();
    }
    protected onFormFieldChanged(
        formField: DataFormField,
        formGroup?: FormGroup,
    ): void {
        this.formStructureHasChanged = true;
    }

    get displayName() {
        if (this.historyMode) {
            return "Version History: " + this.fullObject?.displayName;
        } else {
            return this.fullObject?.displayName ?? this.fullObject?.name;
        }
    }

    versions: DataForm[] = [];
    historyMode = false;
    activeVersion = 0;
    original?: DataForm;

    setSelectedFormVersion(event: MouseEvent, form: DataForm, index: number) {
        this.terminateEvent(event);

        this.setObject(form);
        this.activeVersion = index;
    }

    isSelected(index: number) {
        return this.activeVersion === index;
    }
    getAllVersions() {
        this.historyMode = !this.historyMode;

        if (!this.historyMode) {
            this.versions = [];
            this.setObject(this.original);
        } else {
            this.original = this.fullObject;
            this.service.versions(this.fullObject!).subscribe((v) => {
                this.activeVersion = v.length - 1;
                this.versions = v;
            });
        }
    }
    isReference(item: ObjectOrReference<DataForm>): boolean {
        return item instanceof ObjectReference;
    }
}
