<div
    class="case-overview flex mat-elevation-z3"
    [class.physician]="isPhysicianStaff"
    [class.pharma]="isPharmaStaff"
    (keydown)="handleKeyDown($event)"
>
    <div class="header-panel flex stack case-reference">
        <div *ngIf="inquiry?.program">
            <strong>{{ inquiry?.program?.displayName }}</strong>
        </div>
        <div class="secondary small">
            Case {{ fullObject?.shared?.reference_identifier }}
        </div>
    </div>
    <div
        class="header-panel flex stack case-name"
        [class.editing]="editingProperties"
        (click)="!editingProperties && toggleEdit($event)"
    >
        <div *ngIf="!editingProperties">
            <strong>{{ fullObject?.name }}</strong>
        </div>
        <div *ngIf="!!editingProperties" class="case-name-edit">
            <mat-form-field class="full">
                <mat-label>Case Name</mat-label>
                <input
                    matInput
                    [formControl]="nameControl"
                    (keyup.enter)="toggleEdit($event)"
                    placeholder="Enter a name for this case"
                />
                <button
                    matSuffix
                    mat-icon-button
                    [disabled]="!nameControl.value"
                    (click)="toggleEdit($event)"
                >
                    <mat-icon>done</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="header-panel flex stack logo-panel" *ngIf="isPhysicianStaff">
        <img
            alt="logo"
            *ngIf="isPhysicianStaff && !!organizationLogo"
            [src]="organizationLogo"
        />
        <div *ngIf="isPhysicianStaff && !organizationLogo">
            {{ inquiry?.organization?.displayName }}
        </div>
    </div>

    <div class="header-panel flex stack" *ngIf="isPharmaStaff">
        <div *ngIf="patientInstitution">{{ patientInstitution }}</div>
        <div *ngIf="physicianName" class="secondary small">{{ physicianName }}</div>
    </div>
    <div class="header-panel flex stack">
        <span>Case Status</span>
        <div
            class="chip-container"
            *ngIf="!isPhysicianStaff"
            style="padding-bottom: 0.5rem; cursor: pointer"
        >
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="
                    !caseStatus?.attributes?.is_default_inquiry_status &&
                    !caseStatus?.attributes?.closes_case
                "
                color="accent"
                selected
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [ngClass]="{ disabled: viewOnly }"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.closes_case"
                color="warn"
                selected
                mat-icon-button
                [matMenuTriggerFor]="menu"
                [ngClass]="{ disabled: viewOnly }"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.is_default_inquiry_status"
                selected
                mat-icon-button
                [matMenuTriggerFor]="menu"
                color="primary"
                [ngClass]="{ disabled: viewOnly }"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-menu #menu="matMenu">
                <button
                    mat-menu-item
                    *ngFor="let status of caseStatuses"
                    (click)="updateStatus($event, status)"
                    [disabled]="!canUpdateStatus(status)"
                >
                    <span>{{ status.display_name }}</span>
                </button>
            </mat-menu>
        </div>
        <div class="chip-container" *ngIf="isPhysicianStaff">
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="
                    !caseStatus?.attributes?.is_default_inquiry_status &&
                    !caseStatus?.attributes?.closes_case
                "
                color="accent"
                selected
                mat-icon-button
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.closes_case"
                color="warn"
                selected
                mat-icon-button
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
            <mat-chip
                mat-margin-left="16px"
                class="case-status-chip"
                *ngIf="caseStatus?.attributes?.is_default_inquiry_status"
                selected
                mat-icon-button
                color="primary"
            >
                {{ caseStatus?.display_name }}
            </mat-chip>
        </div>
    </div>
</div>

<!--<bdc-walk-popup
    #doctorTask9
    name="doctorTask9"
    [alignCenter]="true"
    [mustCompleted]="{ doctorOnboardingWelcome: true }"
    [showCloseButton]="false"
    class="doctorTask9Popup"
    [closeOnClick]="true"
>
    To rename the case, click here.
</bdc-walk-popup>

<bdc-walk-popup
    #doctorTask10
    name="doctorTask10"
    [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask9: true }"
    [showCloseButton]="false"
    class="doctorTask10Popup"
>
    To save changes, click the checkmark.
</bdc-walk-popup>
-->
