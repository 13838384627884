import { Component, inject, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { finalize, tap } from "rxjs";
import { DisplayNameMap } from "src/common/utilities/utilities";
import { SessionComponent } from "src/services/components/session.component";
import { Workflow, WorkflowInstance } from "src/services/models/workflow";
import { WorkflowInstanceService } from "src/services/program.services";

type WorkflowChoices = { [key: string]: Workflow };

@Component({
    selector: "append-workflow",
    templateUrl: "./append-workflow.dialog.html",
})
export class AppendWorkflowDialog extends SessionComponent {
    formGroup: FormGroup;
    loading: boolean = false;

    protected workflowInstanceService: WorkflowInstanceService;

    get isWorkflowChoice(): boolean {
        return !!this.data.choices;
    }

    workflowChoices: DisplayNameMap[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: any,
        protected formBuilder: FormBuilder,
        protected dialogRef: MatDialogRef<AppendWorkflowDialog>,
    ) {
        super();
        this.workflowInstanceService = inject(WorkflowInstanceService);

        if (data.choices) {
            const choices: DisplayNameMap[] = data.choices
                .toSorted((a: any, b: any) => a.order - b.order)
                .map((wf: any) => ({ value: wf.value, displayName: wf.displayName }));

            this.workflowChoices = choices;
        } else {
            const choices: DisplayNameMap[] = [];
            this.data.workflows.forEach((wf: Workflow) =>
                choices.push({ value: wf, displayName: wf.displayName! }),
            );
            choices.sort((a, b) =>
                (a.displayName ?? "").localeCompare(b.displayName ?? ""),
            );
            this.workflowChoices = choices;
        }

        this.formGroup = this.formBuilder.group({
            workflow: [null, Validators.required],
            name: [null],
        });

        if (this.workflowChoices.length > 0)
            this.formGroup.get("workflow")?.setValue(this.workflowChoices[0].value);
    }

    appendWorkflow(event: MouseEvent): void {
        const instance = new WorkflowInstance({
            workflow: this.formGroup.get("workflow")?.value?.asReference,
            name: this.formGroup.get("name")?.value,
            owner: this.data.case.asReference,
            order: -1, // set to -1 so that we append it to the end of the list
        });
        this.loading = true;
        this.workflowInstanceService
            .create(instance)
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (result) => {
                    this.dialogRef.close(result);
                },
                error: (error) => {
                    this.snackbar.open(
                        "Unable to create workflow instance",
                        "Dismiss",
                        { duration: 4000 },
                    );
                },
            });
    }
}
