import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    inject,
} from "@angular/core";
import { Shipment } from "src/services/models/shipment";
import { ShipmentService } from "src/services/shipping.services";
import { Case } from "src/services/models/case";
import { AssignmentService, ProgramService } from "../../../services/program.services";
import { ObjectViewMode } from "../../../common/components/object.component";
import {
    CASE_TAB_NAMES,
    TabChangeEvent,
    TabChangeService,
} from "src/services/component.services";
import { Observable, of } from "rxjs";
import { Organization } from "src/services/models/organization";
import { DataForm } from "src/services/models/data";
import { Program } from "src/services/models/program";
import { ShipmentComponent } from "./shipment.component";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { map, mergeMap } from "rxjs/operators";
import { DataFormService } from "src/services/data.services";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { RequestFilter } from "src/common/utilities/request";
import {
    ObjectFactory,
    ObjectOrReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { Assignment } from "src/services/models/assignment";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { SearchableListComponent } from "src/common/components/searchable-list.component";

@Component({
    selector: "shipment-list",
    templateUrl: "./shipment-list.component.html",
    styleUrls: ["./shipment.component.scss"],
})
export class ShipmentListComponent extends SearchableListComponent<Shipment> {
    objectView = ShipmentComponent;
    programService: ProgramService;
    @Input() viewOnly = false;
    protected case_?: Case;
    get case(): Case | undefined {
        return this.case_;
    }
    @Input() set case(v: Case | undefined) {
        this.case_ = v;
        this.updateProgram();
    }
    protected program?: Program;
    @Input() canAdd: boolean = false;

    protected org_?: Organization;
    get organization() {
        return this?.org_;
    }

    @Input() set organization(o: Organization | undefined) {
        this.org_ = o;
    }

    @Input() dataForm?: ObjectOrReference<DataForm>;

    _formToOpen?: TabChangeEvent;
    @Input()
    get formToOpen(): TabChangeEvent | undefined {
        return this._formToOpen;
    }

    set formToOpen(value: TabChangeEvent | undefined) {
        this._formToOpen = value;
    }
    @Output() formToOpenUsed = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["dataForm"] && this.formToOpen?.data?.create) {
            const assignment = this.formToOpen.data["assignment"] as Assignment;

            this.createNewForm(this.formToOpen.data.references, assignment);
        }
    }
    columns: string[] = ["reference_identifier", "form", "type", "date", "actions"];
    legacyColumns: string[] = ["shipment", "received", "actions"];

    dataFormService: DataFormService;
    assignmentService: AssignmentService;
    tabService: TabChangeService;
    constructor(
        protected service: ShipmentService,
        protected changeDetection: ChangeDetectorRef,
        dataFormService: DataFormService,
    ) {
        super(service, changeDetection, 10, "shipment-list");
        this.programService = inject(ProgramService);
        this.assignmentService = inject(AssignmentService);
        this.dataFormService = dataFormService;
        this.tabService = inject(TabChangeService);
    }

    get shipments(): ObjectOrReference<Shipment>[] {
        return this.list.items;
    }
    get legacyShipments(): ObjectOrReference<Shipment>[] {
        return this.shipments?.filter(
            (s: ObjectOrReference<Shipment>) => s instanceof Shipment && !s.data_form,
        );
    }
    get newShipments(): ObjectOrReference<Shipment>[] {
        return this.shipments?.filter(
            (s: ObjectOrReference<Shipment>) => s instanceof Shipment && !!s.data_form,
        );
    }
    get canAddShipment(): boolean {
        return this.canAdd && !!this.case;
    }

    protected objectDialogConfiguration(
        object: Shipment,
        mode: ObjectViewMode,
    ): MatDialogConfig {
        return {
            ...(super.objectDialogConfiguration(object, mode) || {}),
            width: "80%",
            height: "85%",
            autoFocus: false,
        };
    }

    /**
     * (Override)
     *
     * @param {*} [data]
     * @returns {Shipment}
     * @memberof ShipmentListComponent
     */
    newObject(data?: any): Shipment | undefined {
        return super.newObject({
            ...(data || {}),
            case: this.case?.asReference,
            ind: this.program?.ind,
            product_name: this.program?.drug_name,
        });
    }

    protected updateProgram(): void {
        this.program = undefined;
        if (this.case?.shared?.program?.id)
            this.programService
                .retrieve(this.case.shared.program.id)
                .subscribe((program: Program | undefined) => (this.program = program));
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["inquiry"] = `${this.case?.shared?.id ?? "0"},${this.case?.id ?? "0"}`;
        return filters;
    }

    findFormValue(
        key: string,
        form?: DataForm,
        attributes?: any,
        references?: any,
        formKey?: string,
        attributeKey?: string,
    ): any {
        let valueKey = formKey ?? key;
        let referenceKey = references ? references[key] : undefined;
        if (referenceKey) {
            const parts = referenceKey?.item_data.split(":");
            if (parts && parts[0] == "*value") valueKey = parts[1];
            else if (parts) return parts[0];
        } else if (attributes?.shipment) {
            const attrKey = (attributeKey ?? key).replace("shipment.", "");
            const parts = attributes.shipment[attrKey]?.split(":");
            if (parts && parts[0] == "*value") valueKey = parts[1];
            else if (parts) return parts[0];
        }

        const value = form?.getValue(valueKey);
        return value;
    }
    formatQuantity(form?: DataForm, references?: any): string {
        const quantity = this.findFormValue(
            "shipment.quantity",
            form,
            form?.attributes,
            references,
            "shipment.drug.quantity",
        );
        const unit = this.findFormValue(
            "shipment.unit",
            form,
            form?.attributes,
            references,
            "shipment.drug.quantity.unit",
        );

        let quantity_requested = quantity;
        if (typeof quantity == "object" && (!quantity["value"] || !quantity["unit"])) {
            return "";
        }

        if (unit) quantity_requested = quantity + " " + unit;
        if (
            quantity &&
            typeof quantity == "object" &&
            !Array.isArray(quantity) &&
            !!quantity["value"]
        ) {
            quantity_requested = quantity["value"];
            if (quantity["unit"]) quantity_requested += " " + quantity["unit"];
        }
        return quantity_requested;
    }

    createNewForm(references?: any, assignment?: Assignment): void {
        this.editForm(undefined, references, assignment);
    }
    createdForm?: DataForm;
    formObservable(
        form: ObjectOrReference<DataForm>,
        shipment?: Shipment,
    ): Observable<OptionalObjectOrReference<DataForm>> {
        if (!shipment) {
            const factory = ObjectFactory.getObjectFactory<DataForm>(
                DataForm.object_type,
            );
            let obs: Observable<DataForm | undefined> = of(undefined);
            if (factory && form?.id) obs = factory.retrieve(form.id);
            obs = obs.pipe(
                map((template: DataForm | undefined) => {
                    if (template) {
                        const form = ObjectFactory.makeObject<DataForm>(
                            {
                                name: template.name,
                                is_template: false,
                                display_name: template.displayName,
                                description: template.description,
                                region: template.region,
                                template: template.asReference,
                                form_fields: template.form_fields,
                                owner: this.case?.shared.asReference,
                                attributes: template.attributes,
                            },
                            DataForm.object_type,
                        ) as DataForm;

                        return form;
                    }
                    return undefined;
                }),
            );
            return obs;
        }
        return of(form);
    }
    editForm(shipment?: Shipment, references?: any, assignment?: Assignment) {
        const mode = shipment ? ObjectViewMode.Edit : ObjectViewMode.Create;
        const defaultType = this.list.items.length > 0 ? "Resupply" : "Initial";
        const form =
            shipment?.data_form ??
            references?.["shipment.form"]?.reference ??
            references?.["form"]?.reference ??
            this.dataForm;

        this.formObservable(form, shipment).subscribe(
            (form: OptionalObjectOrReference<DataForm>) => {
                const dialogReference = this.showDataFormDialog(mode, form);

                dialogReference?.afterClosed().subscribe((form?: DataForm) => {
                    this.formToOpenUsed.emit();
                    this.formToOpen = undefined;
                    // JT - Removed normalization of shipment values except on create shipment case; moved data normalization to the backend
                    if (!shipment && form) {
                        const type =
                            this.findFormValue(
                                "shipment.type",
                                form,
                                form?.attributes,
                                references,
                            ) || defaultType;
                        const quantity_requested =
                            this.formatQuantity(form, references) || " ";

                        const shipment = ObjectFactory.makeObject<Shipment>(
                            {
                                inquiry: this.case?.shared,
                                data_form: form,
                                quantity_requested: quantity_requested,
                                request_type: type,
                            },
                            Shipment.object_type,
                        );

                        if (shipment) {
                            if (
                                dialogReference.componentInstance.formGroup.valid &&
                                assignment?.pending
                            )
                                this.completeTask(assignment);
                            this.service.create(shipment).subscribe();
                        }
                    }
                });
            },
        );
    }

    completeTask(assignment: Assignment) {
        if (assignment.completed) return;
        assignment.completed = new Date();
        assignment.completed_by = this.currentAccount?.asReference;
        this.assignmentService.update(assignment).subscribe(() => {
            this.tabService.changeTab(CASE_TAB_NAMES.CHECK_LIST, {});
        });
    }
    viewForm(data_form: DataForm): void {
        this.showDataFormDialog(ObjectViewMode.View, data_form);
    }

    deleteShipment(shipment: Shipment) {
        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message: "Are you sure you want to delete this shipment?",
                },
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        return this.service.destroy(shipment);
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe((s) => {
                this.list.refresh();
            });
    }

    isResupply(shipment: Shipment): boolean {
        return shipment.request_type?.toLowerCase() == "resupply";
    }

    showDataFormDialog(
        mode: ObjectViewMode,
        form?: ObjectOrReference<DataForm>,
    ): MatDialogRef<DataFormComponent> | undefined {
        if (!form) return;
        const dialogRef = this.dialog.open(DataFormComponent, {
            maxWidth: "90vw",
            maxHeight: "90vh",
            minWidth: "75vw",
        });
        const componentInstance = dialogRef.componentInstance;
        componentInstance.dialogReference = dialogRef;
        componentInstance.mode = mode;
        componentInstance.autosave = false;
        componentInstance.autosaveOnCreate = false;
        componentInstance.repository = this.case?.shared;
        componentInstance.object = form;
        return dialogRef;
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
