import { SearchableListComponent } from "./../../../common/components/searchable-list.component";
import { ChangeDetectorRef, Component, inject, Input } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { ObjectViewMode } from "src/common/components/object.component";
import { Organization } from "src/services/models/organization";
import { Program, ProgramCountry } from "../../../services/models/program";
import { TeamMemberService, TeamService } from "../../../services/program.services";
import { RequestFilter } from "src/common/utilities/request";
import { MatDialogConfig } from "@angular/material/dialog";
import { Team, TeamMember } from "src/services/models/team";
import { ProgramCountryStaffComponent } from "./program-country-staff.component";
import { map } from "rxjs";
import { Role, RoleDefinition } from "src/services/models/role";

@Component({
    selector: "program-country-staff-admin",
    templateUrl: "./program-country-staff-admin.component.html",
    styleUrls: ["./program.component.scss"],
})
export class ProgramCountryStaffAdminComponent extends SearchableListComponent<TeamMember> {
    objectView = ProgramCountryStaffComponent;

    private teamService = inject(TeamService);
    programCountryRelatedTeamsIds: string[] = [];
    inheritedAccessTeamTypes: string[] = ["program-staff", "staff"];

    protected _programCountry?: ProgramCountry;
    get programCountry(): ProgramCountry | undefined {
        return this._programCountry;
    }
    @Input() set programCountry(v: ProgramCountry | undefined) {
        this._programCountry = v;
        this.pushInheritedTeamsIds(); // TODO: Rename this function
        this.pushProgramCountryTeamId(); // TODO: Rename
    }

    programCountryStaffTeam: Team | undefined;
    orgEmails: string[] = [];

    protected _organization?: Organization;
    @Input() set organization(v: Organization | undefined) {
        this._organization = v;
    }
    get organization(): Organization | undefined {
        return this._organization;
    }

    get isOrganizationAdministrator(): boolean {
        return (
            !!this.currentAccount?.hasRole("object.admin", this.organization) ||
            !!this.currentAccount?.isSystemAdministrator ||
            false
        );
    }

    get displayedColumns(): string[] {
        return [
            "account__email",
            "capabilities__display_name",
            "permission__role",
            "actions",
        ];
    }

    get availableRoles(): RoleDefinition[] {
        return Role.roles;
    }

    constructor(
        protected service: TeamMemberService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10);
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["team"] = this.programCountryRelatedTeamsIds.join(",");
        return filters;
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else {
            this.list.ordering = [];
        }
    }

    newObject(data?: any): TeamMember | undefined {
        if (!this.programCountryStaffTeam) {
            console.error(
                "Cannot Add Staff.  No country-staff team found for this ProgramCountry.",
            );
            return undefined;
        }
        // Set the team reference when adding new staff to the program country
        data = {
            ...data,
            team: this.programCountryStaffTeam.asReference,
            role: "object.view",
            private: false,
        };
        return super.newObject(data);
    }

    protected objectDialogConfiguration(
        object: TeamMember,
        mode: ObjectViewMode,
    ): MatDialogConfig<any> {
        const config = super.objectDialogConfiguration(object, mode);
        return {
            ...config,
            width: "75%",
            minHeight: "65%",
        };
    }

    pushInheritedTeamsIds(): void {
        // Get related org-staff and program-staff teams who will inherit access to this ProgramCountry
        if (!this.programCountry) {
            console.error("ProgramCountry is not defined");
            return;
        }

        const owners: string[] = [];

        // Add this Program as a valid team owner so program admins can be displayed
        this.programCountry?.program?.id && owners.push(this.programCountry.program.id);

        // Add the Program organization as a valid team owner so organization admins can be displayed
        if (this.programCountry?.program instanceof Program) {
            this.programCountry.program.organization?.id &&
                owners.push(this.programCountry.program.organization.id);
        }

        // Retreive the list of teams that are owned by the provided list of owners
        this.teamService
            .list({
                owned: owners.join(","),
                team_types: this.inheritedAccessTeamTypes.join(","),
            })
            .pipe(
                map(
                    (programCountryRelatedTeams) =>
                        programCountryRelatedTeams as Team[],
                ),
                map((programCountryRelatedTeams) =>
                    programCountryRelatedTeams.map((el) => el.id!),
                ),
            )
            .subscribe((programCountryRelatedTeamIds) => {
                this.programCountryRelatedTeamsIds.push(
                    ...programCountryRelatedTeamIds,
                );
                this.list.refresh();
            });
    }

    pushProgramCountryTeamId(): void {
        // Get coutnry-staff who are on the team for this programCountry
        if (!this.programCountry) {
            console.error("ProgramCountry is not defined");
            return;
        }

        const teamTypesFilter = ["country-staff"];
        const owners: string[] = [];

        this.programCountry?.id && owners.push(this.programCountry.id);

        // Retreive the list of teams that are owned by the provided list of owners
        this.teamService
            .list({ owned: owners.join(","), team_types: teamTypesFilter.join(",") })
            .pipe(
                map(
                    (programCountryRelatedTeams) =>
                        programCountryRelatedTeams as Team[],
                ),
                map((programCountryRelatedTeams) => {
                    this.programCountryStaffTeam = programCountryRelatedTeams[0];
                    return programCountryRelatedTeams;
                }),
                map((programCountryRelatedTeams) =>
                    programCountryRelatedTeams.map((el) => el.id!),
                ),
            )
            .subscribe((programCountryRelatedTeamIds) => {
                this.programCountryRelatedTeamsIds.push(
                    ...programCountryRelatedTeamIds,
                );
                this.list.refresh();
            });
    }

    getRoleDisplay(teamMember: TeamMember): string {
        const foundPermRole = this.availableRoles.find(
            (el) => el.value == teamMember.permission?.role,
        );
        const foundRoleRole = this.availableRoles.find(
            (el) => el.value == teamMember.role,
        );

        return foundPermRole?.display ?? foundRoleRole?.display ?? "";
    }

    getRoleInheritedHint(teamMember: TeamMember): string {
        if (teamMember.team instanceof Team && this.isInheritedAccessUser(teamMember)) {
            switch (teamMember.team?.team_type) {
                case "staff":
                    return "(Inherited from Organization)";
                case "program-staff":
                    return "(Inherited from Program)";
                default:
                    return "Inherited";
            }
        }
        return "";
    }

    isInheritedAccessUser(teamMember: TeamMember): boolean {
        if (teamMember.team instanceof Team) {
            return this.inheritedAccessTeamTypes.includes(
                teamMember.team?.team_type ?? "",
            );
        }
        return false;
    }
    ngAfterViewInit(): void {
        this.getEmails();
    }
    getEmails() {
        const list = [];

        const org_id = (this.programCountry?.program as Program).organization?.id;
        const program_id = (this.programCountry?.program as Program).id;

        if (org_id) list.push(org_id);
        if (program_id) list.push(program_id);

        this.service.getMemberEmails(list).subscribe((data) => {
            this.orgEmails = data;
        });
    }

    editObject(
        event: any,
        object: any,
        asDialog: boolean = false,
        viewOnly: boolean = false,
    ) {
        const instance = super.editObject(event, object, asDialog, viewOnly);
        (instance as ProgramCountryStaffComponent).orgEmails = this.orgEmails;
        return instance;
    }

    staffIsCurrentUser(teamMember: TeamMember): boolean {
        return teamMember.account?.id == this.currentAccount?.id;
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}
}
