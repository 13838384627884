import { Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { ObjectComponent } from "src/common/components/object.component";
import { CapabilityService } from "src/services/iam.services";
import { Capability } from "src/services/models/capability";

@Component({
    selector: "capability",
    templateUrl: "./capability.component.html",
    styleUrls: ["../organization.component.scss"],
})
export class CapabilityComponent extends ObjectComponent<Capability> {
    objectName = "Create New Role";
    constructor(service: CapabilityService) {
        super(service);
    }

    protected override createObjectForm() {
        return this.formBuilder.group({
            display_name: [Validators.required],
        });
    }

    protected precommitTransform(v: any) {
        v = super.precommitTransform(v);
        if (!this?.fullObject?.id && this?.fullObject?.owner) {
            v["name"] = `${this.fullObject.owner?.name}.${v["display_name"]}`;
        }

        return v;
    }

    protected onCommitError(err: any) {
        super.onCommitError(err);
        this.session.handleError(err);
    }

    protected setObject(v?: Capability | undefined): void {
        super.setObject(v);
        if (v?.id) {
            this.objectName = "Edit Role";
        }
    }
}
