<div class="wrapper flex stack full" *ngIf="mode == ObjectViewMode.Create">
    <object-form
        [controller]="controller"
        [formGroup]="formGroup"
        [hideHeader]="mode != ObjectViewMode.Create"
    >
        <div class="flex stack">
            <mat-form-field color="primary" class="inquiryDetail">
                <mat-label>Product</mat-label>
                <mat-select
                    [formControl]="productControl"
                    [disabled]="!canEditInquiryDetails"
                >
                    <mat-select-trigger *ngIf="productControl?.value">
                        <mat-icon
                            [matTooltip]="productInvalidToolTip"
                            class="warning country-flag-img"
                            *ngIf="
                                fullObject &&
                                isObject(fullObject) &&
                                !createProductValid
                            "
                        >
                            warning
                        </mat-icon>
                        <span *ngIf="productControl?.value">
                            {{ productControl.value.displayName }}
                        </span>
                    </mat-select-trigger>
                    <mat-option
                        [value]="fullObject?.shared?.product"
                        *ngIf="fullObject?.shared?.product?.displayName"
                    >
                        {{ fullObject?.shared?.product?.displayName }}
                    </mat-option>
                    <mat-option *ngFor="let product of products" [value]="product">
                        {{ product.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field color="primary" class="inquiryDetail">
                <mat-label>Country</mat-label>
                <mat-select
                    [formControl]="countryControl"
                    [disabled]="!canEditInquiryDetails"
                >
                    <mat-select-trigger *ngIf="countryControl?.value">
                        <mat-icon
                            [matTooltip]="countryInvalidToolTip"
                            class="warning country-flag-img"
                            *ngIf="
                                fullObject &&
                                isObject(fullObject) &&
                                !createCountryValid
                            "
                        >
                            warning
                        </mat-icon>
                        <img
                            *ngIf="countryControl?.value"
                            [src]="countryControl.value.flag_url"
                            alt="({{ countryControl.value.code }})"
                            class="country-flag-img"
                            (error)="handleCountryFlagError(countryControl.value)"
                        />
                        {{ countryControl.value.display_name }}
                    </mat-select-trigger>
                    <ng-container *ngIf="countries.length">
                        <mat-option *ngFor="let country of countries" [value]="country">
                            <img
                                [src]="country.flag_url"
                                [alt]="country.code"
                                class="country-flag-img"
                                (error)="handleCountryFlagError(country)"
                            />
                            {{ country.display_name }}
                        </mat-option>
                    </ng-container>
                    <ng-container *ngIf="!countries.length">
                        <mat-option disabled>
                            Error - Countries list not found
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field color="primary" class="inquiryDetail">
                <mat-label>Program</mat-label>
                <mat-select
                    [formControl]="programControl"
                    [disabled]="!canEditInquiryDetails"
                >
                    <mat-select-trigger *ngIf="programControl?.value">
                        <mat-icon
                            [matTooltip]="programCountryInvalidToolTip"
                            class="warning country-flag-img"
                            *ngIf="
                                fullObject &&
                                isObject(fullObject) &&
                                !createProgramCountryValid
                            "
                        >
                            warning
                        </mat-icon>
                        <span *ngIf="programControl?.value">
                            {{ programControl.value.displayName }}
                        </span>
                    </mat-select-trigger>
                    <mat-option
                        *ngFor="let program of productOptions"
                        [value]="program"
                    >
                        {{ program.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="showNextFields">
                <mat-form-field class="full" style="padding-top: 1rem">
                    <mat-label>Internal Case Name</mat-label>
                    <input
                        matInput
                        placeholder="Internal Case Name"
                        formControlName="name"
                        (input)="caseNameInput($event)"
                    />
                    <mat-progress-spinner
                        *ngIf="hasTimeout"
                        matSuffix
                        diameter="20"
                        class="inline"
                        mode="indeterminate"
                    ></mat-progress-spinner>
                    <mat-error *ngIf="!isCaseNameValid">
                        A case with this name already exists. Please choose a different
                        case name.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="flexible full">
                    <mat-label>WorkFlow</mat-label>
                    <mat-select formControlName="workflow">
                        <mat-option
                            *ngFor="let workflow of workflows"
                            [value]="workflow.id"
                        >
                            {{ workflow.displayName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="flexible full">
                    <mat-label>Owner</mat-label>
                    <mat-select formControlName="primary">
                        <mat-option
                            *ngFor="let staff of availablePrimaryAssignees"
                            [value]="staff"
                        >
                            {{ staff.displayName }}
                        </mat-option>
                        <mat-option disabled *ngIf="!availablePrimaryAssignees.length">
                            Error - Assignees list not found
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        The case owner will be automatically assigned administrative
                        tasks within the case workflow
                    </mat-hint>
                </mat-form-field>
                <mat-form-field class="flexible full" style="padding-top: 1rem">
                    <mat-label>Additional Administrator(s) (optional)</mat-label>
                    <mat-select formControlName="secondary" multiple="multiple">
                        <mat-option
                            *ngFor="let staff of availableSecondaryAssignees"
                            [value]="staff"
                            [disabled]="primary?.account?.id == staff.account.id"
                        >
                            {{ staff.displayName }}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        Additional administrators will be added to the case team and
                        receive task notifications, but will not be automatically
                        assigned to any workflow tasks.
                    </mat-hint>
                </mat-form-field>
                <div class="flex" *ngIf="false">
                    <!-- JT 4 Nov 2021 - Temporarily Removed Doctor invites-->
                    <div class="spacer half">&nbsp;</div>
                    <div class="flexible full create-case-info">
                        <mat-icon class="info">info</mat-icon>
                        <p>
                            The primary contact
                            <mat-icon class="inline amber">star</mat-icon>
                            for this inquiry will be invited to create an account in
                            order to track progress and view any shared information.
                        </p>
                    </div>
                    <div class="spacer half">&nbsp;</div>
                </div>
            </div>
        </div>
    </object-form>
</div>
<ng-container *ngIf="mode != ObjectViewMode.Create">
    <div *ngIf="!fullObject">
        <div class="spinner">
            <mat-spinner mode="indeterminate" [diameter]="50"></mat-spinner>
        </div>
        <div class="spinner-message">Loading Case ...</div>
    </div>
    <div class="flex stack" *ngIf="fullObject">
        <case-overview
            class="case-overview"
            [(object)]="object"
            [mode]="mode"
            [autosave]="true"
            [case]="fullObject"
            [viewOnly]="viewOnly"
            [caseStatuses]="caseStatuses"
        ></case-overview>
        <div class="wrapper flexible flex stack case-view">
            <mat-tab-group
                #tabGroup
                color="primary"
                class="case-tabs margin-top"
                [selectedIndex]="selectedIndex"
                (focusChange)="handleUserTabChange($event)"
            >
                <mat-tab label="Checklist">
                    <ng-template mat-tab-label>
                        <div
                            class="flex"
                            style="padding-right: 1rem"
                            [matBadge]="matBadge(assignmentNotifications || [])"
                            matBadgeColor="accent"
                            matBadgePosition="after"
                            matBadgeOverlap="false"
                        >
                            Checklist
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div class="container flex center-align">
                                <div class="title-2">Quick Actions</div>
                                <div class="spacer"></div>
                                <a
                                    [href]="adverseEventUrl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    *ngIf="!isCaseClosed && adverseEventUrl"
                                >
                                    <button
                                        mat-flat-button
                                        class="error"
                                        matTooltip="Report Safety Event"
                                        [disabled]="viewOnly"
                                    >
                                        <mat-icon>health_and_safety</mat-icon>
                                        Report Safety Event
                                    </button>
                                </a>
                                <button
                                    mat-flat-button
                                    class="brand-medium"
                                    *ngIf="!isCaseClosed && !isPhysicianStaff"
                                    (click)="appendWorkflow($event)"
                                    matTooltip="Append Workflow"
                                    [disabled]="viewOnly"
                                >
                                    <mat-icon>splitscreen-add</mat-icon>
                                    Append Workflow
                                </button>
                                <div class="spacer"></div>
                                <button
                                    mat-flat-button
                                    class="brand-medium"
                                    *ngIf="
                                        !isCaseClosed && !isPhysicianStaff && isManager
                                    "
                                    (click)="rejectCase($event)"
                                    matTooltip="Close Case"
                                    [disabled]="viewOnly"
                                >
                                    <mat-icon>close</mat-icon>
                                    Close Case
                                </button>
                            </div>
                        </div>
                        <div class="flex flexible checklist-filter">
                            <div class="spacer"></div>
                            <mat-form-field (click)="terminateEvent($event)">
                                <mat-label>Checklist View</mat-label>
                                <mat-select [formControl]="taskFilterControl">
                                    <mat-option
                                        *ngFor="let filter of taskFilterEnum | keyvalue"
                                        [value]="filter.value"
                                    >
                                        {{ taskFilterLabels[filter.value] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <workflow-stack
                            [case]="object"
                            [viewOnly]="viewOnly"
                            [notifications]="assignmentNotifications"
                            [filterMode]="taskFilterControl.value ?? undefined"
                        ></workflow-stack>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Discussion" *ngIf="!isPhysicianStaff">
                    <ng-template mat-tab-label>
                        <div
                            [matTooltip]="
                                (
                                    currentUserIsMentioned &&
                                    discussionNotifications.length
                                ) ?
                                    'You were mentioned in a discussion.'
                                :   ''
                            "
                            class="flex"
                        >
                            <div style="padding-right: 3rem">Discussion</div>
                            <div
                                [matBadge]="matBadge(discussionNotifications || [])"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="above after"
                            ></div>
                            <div
                                [matBadge]="
                                    (
                                        currentUserIsMentioned &&
                                        discussionNotifications.length
                                    ) ?
                                        '@'
                                    :   undefined
                                "
                                matBadgeColor="accent"
                                matBadgePosition="above before"
                                matBadgeOverlap="false"
                            ></div>
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div *ngIf="!fullObject?.shared?.teams">
                                <div class="spinner">
                                    <mat-spinner
                                        mode="indeterminate"
                                        [diameter]="50"
                                    ></mat-spinner>
                                </div>
                                <div class="spinner-message">
                                    Loading Discussion ...
                                </div>
                            </div>

                            <discussion
                                *ngIf="!!fullObject?.shared?.teams"
                                [staffWithCaseAccess]="pharmaTeamMembers"
                                [parent]="fullObject!"
                                [viewOnly]="viewOnly"
                                [notifications]="discussionNotifications"
                            ></discussion>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Communications">
                    <ng-template mat-tab-label>
                        <div
                            class="flex"
                            [matBadge]="matBadge(messageNotifications || [])"
                            matBadgeColor="accent"
                            matBadgeOverlap="false"
                        >
                            <span class="flexible" style="padding-right: 1.25rem">
                                Communications
                            </span>
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card" *ngIf="fullObject?.id">
                            <communications
                                [viewOnly]="viewOnly"
                                [repository]="fullObject"
                                [notifications]="messageNotifications"
                                [messageToOpen]="messageToOpen"
                                (messageToOpenUsed)="messageToOpen = undefined"
                            ></communications>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Case Summary">
                    <ng-template mat-tab-label>
                        <div class="flex">Case Summary</div>
                        <mat-icon
                            [matTooltip]="invalidRequestToolTip(fullObject)"
                            class="warning"
                            *ngIf="
                                isObject(fullObject) &&
                                isPharmaStaff &&
                                (!fullObject.country_valid ||
                                    !fullObject.product_valid ||
                                    !fullObject.program_country_valid)
                            "
                        >
                            warning
                        </mat-icon>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card flex stack">
                            <div class="title">Case Details</div>
                            <div class="detailContainer">
                                <mat-form-field color="primary" class="inquiryDetail">
                                    <mat-label>Country</mat-label>
                                    <mat-select
                                        [formControl]="countryControl"
                                        [disabled]="!canEditInquiryDetails"
                                    >
                                        <mat-select-trigger
                                            *ngIf="countryControl?.value"
                                        >
                                            <mat-icon
                                                [matTooltip]="countryInvalidToolTip"
                                                class="warning country-flag-img"
                                                *ngIf="
                                                    isObject(fullObject) &&
                                                    isPharmaStaff &&
                                                    !fullObject.country_valid
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                            <img
                                                *ngIf="countryControl.value"
                                                [src]="countryControl.value.flag_url"
                                                [alt]="countryControl.value.code"
                                                class="country-flag-img"
                                                (error)="
                                                    handleCountryFlagError(
                                                        countryControl.value
                                                    )
                                                "
                                            />
                                            {{ countryControl.value.display_name }}
                                        </mat-select-trigger>
                                        <ng-container *ngIf="countries.length">
                                            <mat-option
                                                *ngFor="let country of countries"
                                                [value]="country"
                                            >
                                                <img
                                                    [src]="country.flag_url"
                                                    [alt]="country.code"
                                                    class="country-flag-img"
                                                    (error)="
                                                        handleCountryFlagError(country)
                                                    "
                                                />
                                                {{ country.display_name }}
                                            </mat-option>
                                        </ng-container>
                                        <ng-container *ngIf="!countries.length">
                                            <mat-option disabled>
                                                Error - Countries list not found
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field color="primary" class="inquiryDetail">
                                    <mat-label>Program</mat-label>
                                    <mat-select
                                        [formControl]="programControl"
                                        [disabled]="!canEditInquiryDetails"
                                    >
                                        <mat-select-trigger>
                                            <mat-icon
                                                [matTooltip]="programInvalidToolTip"
                                                class="warning country-flag-img"
                                                *ngIf="
                                                    isObject(fullObject) &&
                                                    isPharmaStaff &&
                                                    !fullObject.program_country_valid
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                            {{ programControl.value.displayName }}
                                        </mat-select-trigger>
                                        <mat-option
                                            *ngFor="let program of productOptions"
                                            [value]="program"
                                        >
                                            {{ program.displayName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field color="primary" class="inquiryDetail">
                                    <mat-label>Product</mat-label>
                                    <mat-select
                                        [formControl]="productControl"
                                        [disabled]="!canEditInquiryDetails"
                                    >
                                        <mat-select-trigger>
                                            <mat-icon
                                                [matTooltip]="productInvalidToolTip"
                                                class="warning country-flag-img"
                                                *ngIf="
                                                    isObject(fullObject) &&
                                                    isPharmaStaff &&
                                                    !fullObject.product_valid
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                            {{ productControl.value.displayName }}
                                        </mat-select-trigger>
                                        <mat-option
                                            [value]="fullObject.product"
                                            *ngIf="fullObject?.product?.displayName"
                                        >
                                            {{ fullObject.product.displayName }}
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let product of products"
                                            [value]="product"
                                        >
                                            {{ product.displayName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="content-card">
                            <case-summary [repository]="fullObject"></case-summary>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Documents">
                    <ng-template mat-tab-label>
                        <div class="flex" style="align-items: center">
                            <div
                                class="flex"
                                style="padding-right: 1rem"
                                [ngStyle]="{
                                    'margin-right':
                                        matBadge(documentNotifications || []) ? '1.5rem'
                                        :   '0rem',
                                }"
                                [matBadge]="matBadge(documentNotifications || [])"
                                matBadgeColor="accent"
                                matBadgeOverlap="false"
                                matBadgePosition="after"
                            >
                                Documents
                            </div>
                            <mat-icon
                                *ngIf="!!tabErrors['Documents']"
                                class="fixed"
                                [ngClass]="tabErrors['Documents'].severity"
                                [matTooltip]="tabErrors['Documents'].message"
                            >
                                warning
                            </mat-icon>
                        </div>
                    </ng-template>

                    <div class="content-card">
                        <document-repository
                            *ngIf="!!fullObject"
                            displayMode="repo"
                            [caseOrg]="fullObject.shared.organization"
                            [viewOnly]="viewOnly"
                            [repository]="fullObject"
                            [owner]="ownerOrg"
                            [redactionEnabled]="redactionEnabled"
                            [sources]="documentSources"
                            [notifications]="documentNotifications"
                            [documentToOpen]="documentToOpen"
                            (documentToOpenUsed)="documentToOpen = undefined"
                            (setCaseError)="setTabError('Documents', $event)"
                        ></document-repository>
                    </div>
                </mat-tab>
                <mat-tab label="Forms">
                    <ng-template mat-tab-label>
                        <div
                            class="flex"
                            [matBadge]="matBadge(formNotifications || [])"
                            matBadgeColor="accent"
                            matBadgeOverlap="false"
                            style="padding-right: 1.5rem"
                        >
                            Forms
                        </div>
                    </ng-template>
                    <ng-template matTabContent>
                        <div class="content-card">
                            <data-form-list
                                *ngIf="!!fullObject"
                                [templateForms]="templateForms"
                                [owner]="this.fullObject"
                                [productOptions]="productOptions"
                                [notifications]="formNotifications"
                                [formToOpen]="formToOpen"
                                (formToOpenUsed)="formToOpen = undefined"
                            ></data-form-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Shipments">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <shipment-list
                                *ngIf="!!fullObject"
                                [viewOnly]="viewOnly"
                                [dataForm]="shipmentForm"
                                [canAdd]="true"
                                [case]="fullObject"
                                [organization]="programOrganization"
                                [formToOpen]="shipmentToOpen"
                            ></shipment-list>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Team">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <div *ngIf="!fullObject?.shared?.teams">
                                <div class="spinner">
                                    <mat-spinner
                                        mode="indeterminate"
                                        [diameter]="50"
                                    ></mat-spinner>
                                </div>
                                <div class="spinner-message">Loading Team ...</div>
                            </div>
                            <team
                                *ngIf="!!fullObject && fullObject.shared.teams"
                                [case]="fullObject"
                                [staffTeam]="staffTeam"
                                [viewOnly]="viewOnly"
                            ></team>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Audit">
                    <ng-template matTabContent>
                        <div class="content-card">
                            <case-audit
                                [case]="object"
                                [selected]="isAuditSelected"
                            ></case-audit>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <ng-container *ngIf="false">
        <div
            class="taskManager"
            *ngIf="isPhysicianStaff && fullObject && walkThroughVisible"
        >
            <button
                (click)="resetWalkthrough()"
                mat-button
                *ngIf="!showWalkthrough"
                [disabled]="!fullObject"
            >
                Replay Walkthrough
            </button>
            <button
                (click)="stopWalkthrough()"
                mat-button
                *ngIf="showWalkthrough"
                [disabled]="!fullObject"
            >
                Stop Walkthrough
            </button>
        </div>

        <bdc-walk-popup
            #doctorOnboardingWelcome
            name="doctorOnboardingWelcome"
            header="Welcome to MedaSystems"
            [showButton]="true"
            buttonText="Let's start"
            xPosition="after"
            [arrow]="false"
            [showCloseButton]="false"
        >
            Welcome to MedaSystems! You or your team recently submitted an Expanded
            Access request. This will step you through a quick walkthrough to explain
            the tools in our system that you will use to process the request.
        </bdc-walk-popup>

        <bdc-walk-popup
            #doctorTask1
            name="doctorTask1"
            [mustCompleted]="{ doctorOnboardingWelcome: true, doctorTask10: true }"
            [onButtonCompleteTask]="{ doctorTask1: true }"
            buttonText="Continue"
            [arrow]="false"
            [showCloseButton]="false"
            [showButton]="true"
            class="doctorTask1Popup"
        >
            This checklist shows all the tasks that need to be completed by both the
            pharmaceutical company and the physician’s team to process the request.
        </bdc-walk-popup>
    </ng-container>
</ng-container>
