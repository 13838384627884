import { TeamMember } from "./../../../services/models/team";
import { Component, Inject, inject } from "@angular/core";
import { SessionComponent } from "../../../services/components/session.component";
import {
    MessageService,
    TemplateService,
} from "../../../services/notification.services";
import { Template } from "../../../services/models/template";
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { TemplateComponent } from "./template.component";
import {
    APIListResult,
    ObjectFactory,
    ObjectOrReference,
    ObjectReference,
} from "../../../services/models/api-object";
import { AccountEmail, Message } from "../../../services/models/message";
import {
    AssignmentService,
    DocumentFileItem,
    DocumentService,
    InquiryService,
    ProgramCountryService,
    TeamMemberService,
} from "../../../services/program.services";
import {
    Document,
    DocumentOwner,
    DocumentRepository,
} from "../../../services/models/document";
import { FileItem } from "ng2-file-upload";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { Role } from "../../../services/models/role";
import { Assignment } from "src/services/models/assignment";
import { Case } from "src/services/models/case";
import { Inquiry } from "src/services/models/inquiry";
import { FileUploaderCustom } from "src/common/utilities/FileUploaderCustom";
import { OptionalEmailValidator } from "src/common/utilities/validators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Patient } from "src/services/models/patient";
import { ConfirmRecipientsDialog } from "./ConfirmBulkRecipients";
import { defined } from "src/common/utilities/flatten";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatChipInputEvent } from "@angular/material/chips";
import { Program, ProgramCountry } from "src/services/models/program";
import { ConfirmDialog, ConfirmDialogData } from "../confirm.dialog";
import { Organization } from "src/services/models/organization";

type TemplateContact = Role | TeamMember | Patient;

export interface SendTemplateDialogData {
    to?: Role | TeamMember;
    subject?: string;
    bulkMode?: boolean;
    owner?: string;
    context?: any;
    reference?: ObjectReference;
    allowInvite?: boolean;
    sources?: DocumentRepository[];
    uploadOwner?: DocumentOwner;
    repository?: DocumentRepository;
    contacts?: TemplateContact[];
    assignment?: Assignment; // Assignment to complete when the message has been sent.
    template?: ObjectReference; // Default template to be used,
    message?: any;
    send_notification?: boolean;
    inquiry?: Inquiry;
}

@Component({
    selector: "send-template",
    templateUrl: "./send-template.dialog.html",
    styleUrls: ["./template.component.scss"],
})
export class SendTemplateDialog extends SessionComponent {
    protected templateService: TemplateService;
    protected messageService: MessageService;
    protected documentService: DocumentService;
    protected formBuilder: UntypedFormBuilder;
    protected matDialog: MatDialog;
    protected inquiryService: InquiryService;
    protected teamMemberService: TeamMemberService;
    availableTemplates: Template[] = [];
    formGroup: UntypedFormGroup;
    subjectMaxLength: number = 250;

    availableDocuments_: ObjectOrReference<Document>[] = [];
    uploader?: FileUploaderCustom;
    fileOver: boolean = false;
    attached: ObjectOrReference<Document>[] = [];
    uploaded: ObjectOrReference<Document>[] = [];
    showAvailableDocuments: boolean = false;
    assignmentService: AssignmentService;
    loading = false;
    get bulkMode() {
        return this?.data?.bulkMode as boolean;
    }
    programCountryService: ProgramCountryService;
    dialog: MatDialog;
    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: SendTemplateDialogData,
        protected dialogRef: MatDialogRef<SendTemplateDialog>,
    ) {
        super();
        this.templateService = inject(TemplateService);
        this.messageService = inject(MessageService);
        this.documentService = inject(DocumentService);
        this.formBuilder = inject(UntypedFormBuilder);
        this.matDialog = inject(MatDialog);
        this.inquiryService = inject(InquiryService);
        this.teamMemberService = inject(TeamMemberService);
        this.programCountryService = inject(ProgramCountryService);
        this.dialog = inject(MatDialog);
        const target =
            this.data.contacts && this.data.contacts[0] instanceof Patient ?
                this.data.contacts
            :   this.data.to;

        this.formGroup = this.formBuilder.group({
            target: [target, Validators.required],
            unAffiliatedTarget: [null, [OptionalEmailValidator]],
            copies: [null],
            template: [null],
            subject: [
                this.data.subject,
                [Validators.required, Validators.maxLength(250)],
            ],
            message: [this.data.message, Validators.required],
            chipListInput: [null, OptionalEmailValidator],
            unAffiliatedCopies: this.formBuilder.array([]),
        });
        this.initializeUploader();
        this.asFormControl(this.formGroup.controls.template).valueChanges.subscribe(
            (value: any) => this.updateMessage(value as Template),
        );

        this.getProgramCountries();

        this.formGroup.controls.target.valueChanges.subscribe(
            (r: Role | TeamMember | "external") => {
                if (r === "external") {
                    this.unAffiliatedEmail.addValidators(Validators.required);
                    this.unAffiliatedEmail.reset();
                } else {
                    if (this.unAffiliatedEmail.hasValidator(Validators.required)) {
                        this.unAffiliatedEmail.removeValidators(Validators.required);
                    }

                    const copies = this.formGroup.controls.copies.value;
                    const newCopies = copies?.filter(
                        (t: Role | TeamMember) => t.id != r.id,
                    );
                    this.formGroup.controls.copies.setValue(newCopies);
                }
            },
        );

        this.assignmentService = inject(AssignmentService);
    }
    programCountries: ProgramCountry[] = [];
    getProgramCountries() {
        const program = this.data.sources?.find((o) => o.type === Program.object_type);
        let templateOwner = this.data.owner;

        if (!program || !this?.data?.inquiry?.country?.id) {
            this.updateAvailableTemplates(this.data?.template?.id, templateOwner);
            return;
        }

        this.programCountryService
            .list({
                country: this?.data?.inquiry?.country?.id,
                program: program.id!,
            })
            .subscribe((res) => {
                this.programCountries = res as ProgramCountry[];

                if (Array.isArray(this.data.owner)) {
                    templateOwner = this.data.owner.join(",");
                }
                this.updateAvailableTemplates(this.data?.template?.id, templateOwner);
            });
    }
    get copies() {
        return this.formGroup.controls.copies;
    }
    get unAffiliatedEmail(): UntypedFormControl {
        return this.formGroup.controls.unAffiliatedTarget as UntypedFormControl;
    }

    get targetIsUnAffiliated() {
        return this.formGroup.value.target === "external";
    }
    get isInvite(): boolean {
        return !!this.data.allowInvite;
    }
    get canAttachDocuments(): boolean {
        return (
            !!this.data.uploadOwner &&
            !!this.data.repository &&
            !!this.session.currentAccount
        );
    }
    get queue(): DocumentFileItem[] | undefined {
        return this.uploader?.queue as DocumentFileItem[];
    }
    get repository(): DocumentRepository | undefined {
        return this.data.repository;
    }
    get availableDocuments(): ObjectOrReference<Document>[] {
        return this.availableDocuments_.filter(
            (d: ObjectOrReference<Document>) =>
                !this.attached.find((x: ObjectOrReference<Document>) => x.id == d.id),
        );
    }
    get contacts(): TemplateContact[] {
        return this.data.contacts ?? [];
    }
    get messageFormControl(): UntypedFormControl {
        return this.formGroup.controls.message as UntypedFormControl;
    }

    get isCaseRepository(): boolean {
        return (
            this.repository?.type == "program.case" ||
            this.repository?.type == "program.inquiry"
        );
    }
    get inquiry() {
        return this?.data?.inquiry;
    }
    get isPhysician(): boolean {
        return (
            this.isCaseRepository &&
            (this.repository as Case | Inquiry).isPhysicianStaff(this.currentAccount)
        );
    }
    get walkthroughEnabled(): boolean {
        return this.isPhysician && !this.completedWalkthrough;
    }

    openBulkConfirm(targets: string[]) {
        return this.matDialog
            .open(ConfirmRecipientsDialog, {
                data: { targets },
                width: "50%",
            })
            .afterClosed()
            .pipe(
                map((dialogResult) => {
                    if (dialogResult) {
                        return dialogResult;
                    } else {
                        return undefined;
                    }
                }),
            );
    }
    getBulkMsgObs() {
        const inquiryIds = new Set<string>();
        const caseIds = new Set<string>();
        const orgIds = new Set<string>();
        this.currentAccount?.orgs.forEach((org) => orgIds.add(org.id!));
        this.formGroup.value?.target
            .filter((c: TemplateContact) => c instanceof Patient)
            .forEach((patient: Patient) => {
                if (patient?.pharma?.id) {
                    orgIds.add(patient.pharma.id);
                }

                if (patient.object.type === "program.inquiry" && patient?.object?.id) {
                    inquiryIds.add(patient.object.id);
                } else if (patient?.object?.id) {
                    caseIds.add(patient.object.id);
                }
                const program = patient.program as Program;
                if (program?.organization?.id) {
                    orgIds.add(program.organization.id);
                }
            });

        const targets = this.teamMemberService
            .getMembersForInquiries(
                Array.from(inquiryIds),
                Array.from(caseIds),
                Array.from(orgIds),
            )
            .pipe(
                concatMap((targets: string[]) => {
                    return this.openBulkConfirm(targets);
                }),
                concatMap((targets: string[] | undefined) => {
                    if (!targets) {
                        this.loading = false;
                        return EMPTY; //immediately stops the observable
                    }
                    return this.handleDocumentObs().pipe(
                        concatMap((attachments) => of({ targets, attachments })),
                    );
                }),
                concatMap(({ targets, attachments }) => {
                    const message = this.buildMessage(attachments, undefined);
                    if (!message) {
                        this.dialogRef.close();
                        return EMPTY;
                    }
                    return of({
                        targets,
                        message,
                        cases: caseIds,
                        inquiries: inquiryIds,
                    });
                }),
            );

        return targets;
    }

    asDocument(doc: ObjectOrReference<Document>): Document | undefined {
        return doc instanceof Document ? doc : undefined;
    }
    handleDocumentObs() {
        return this.uploadDocumentObservable().pipe(
            concatMap((uploaded: ObjectOrReference<Document>[]) => {
                return this.copyDocumentObservable().pipe(
                    map((copied: ObjectOrReference<Document>[]) => [
                        ...uploaded,
                        ...copied,
                    ]),
                );
            }),
        );
    }
    getMsgObs(target?: TeamMember) {
        return this.handleDocumentObs().pipe(
            concatMap((attachments: ObjectOrReference<Document>[]) => {
                const message = this.buildMessage(attachments, target);
                if (!message) return of(undefined);

                return this.messageService.create(message);
            }),
        );
    }
    removeQueueItem(event: MouseEvent, queueItem: FileItem): void {
        this.terminateEvent(event);
        queueItem.remove();
    }
    removeAttachment(event: MouseEvent, document: ObjectOrReference<Document>): void {
        this.terminateEvent(event);
        this.attached = this.attached.filter(
            (d: ObjectOrReference<Document>) => d.id !== document.id,
        );
    }
    attachDocument(event: MouseEvent, document: ObjectOrReference<Document>): void {
        this.terminateEvent(event);
        this.attached = [...this.attached, document];
    }

    buildMessage(
        attachments: ObjectOrReference<Document>[],
        target?: TeamMember,
    ): Message | undefined {
        let formValue = this.formGroup.value;
        let toUnAffiliated = false;
        if (
            formValue.target === "external" &&
            formValue.unAffiliatedTarget &&
            !this.bulkMode
        ) {
            toUnAffiliated = true;
            formValue.target = formValue.unAffiliatedTarget;
        }

        const message = ObjectFactory.makeObject<Message>(
            formValue,
            Message.object_type,
        ) as Message;

        if (!message) return undefined;

        if (message?.template) {
            message.template = message.template?.asReference.serialize();
        }

        message.sender = new AccountEmail({
            account: this.session.currentAccount?.asReference.serialize(),
            email: this.session.currentAccount?.email,
        });
        message.context = this.data.context;
        message.attachments = attachments.map((d: ObjectOrReference<Document>) =>
            d.asReference.serialize(),
        );

        if (!this.bulkMode && !target) {
            let copies = [];
            if (this.formGroup.controls.copies.value)
                copies = this.formGroup.controls.copies.value.map(
                    (r: Role | TeamMember) => new AccountEmail(r),
                );
            if (this.unAffiliatedCopiesList.length) {
                const unAffiliatedCopies = this.unAffiliatedCopiesList.map(
                    (email: string) => new AccountEmail({ email }),
                );
                copies = [...copies, ...unAffiliatedCopies];
            }

            const targets = [
                toUnAffiliated ?
                    new AccountEmail({ email: formValue.target })
                :   new AccountEmail(formValue.target),
                ...copies,
            ];

            message.targets = targets;

            message.reference = this.data.reference?.serialize();
            message.send_invite =
                this.data?.allowInvite ?
                    this.session.currentAccount?.asReference
                :   undefined;
        } else if (this.bulkMode) {
            message.send_invite = undefined;
            message.in_bulk = true;
        }
        return message;
    }

    uploadDocumentObservable(): Observable<ObjectOrReference<Document>[]> {
        if (this.uploader && this.queue?.length) {
            const docObs = this.uploader
                .uploadAllObservable()
                .pipe(
                    map((result: any[]) =>
                        defined(
                            result.map((res: any) =>
                                ObjectFactory.makeObject<Document>(res),
                            ),
                        ),
                    ),
                );

            return docObs;
        } else {
            return of([]);
        }
    }
    copyDocumentObservable(): Observable<ObjectOrReference<Document>[]> {
        if (this.attached.length) {
            const shared = this.attached.filter(
                (d: ObjectOrReference<Document>) =>
                    d instanceof Document &&
                    d.repository?.id == this.data.repository!.id,
            );
            const notShared = this.attached.filter(
                (d: ObjectOrReference<Document>) =>
                    d instanceof Document &&
                    d.repository?.id != this.data.repository!.id,
            );
            return this.documentService
                .copyDocuments(notShared, this.data.repository!)
                .pipe(
                    map((documents: ObjectOrReference<Document>[]) => [
                        ...documents,
                        ...shared,
                    ]),
                );
        } else return of([]);
    }
    confirmCounterPartyMessage(): Observable<boolean> {
        if (this.data?.uploadOwner instanceof Organization) {
            if (!this?.data?.uploadOwner?.warnCounterpartyMessages) {
                return of(true);
            }
        } else {
            return of(true);
        }

        let isCounterParty =
            this.unAffiliatedEmail?.value || this?.chipListInput?.value?.length > 0;
        const teamForUser = this?.inquiry?.caseTeam(this.currentAccount);

        if (this.inquiry?.id && teamForUser) {
            const target = this.formGroup.value.target as TeamMember;
            const copies = [...(this.copies?.value ?? []), target] as TeamMember[];

            const isCounterPartyTeam = copies.some((member) => {
                if (teamForUser.capacity === "pharma") {
                    return !!this.inquiry?.isMemberOfTeam(member.account, "provider");
                }

                return !!this.inquiry?.isMemberOfTeam(member.account, "pharma");
            });

            if (!isCounterParty && !isCounterPartyTeam) {
                return of(true);
            }
        }
        const data = {
            title: "Confirm Communication",
            message: "This message will be sent to people outside your organization's current staff. Do you still want to proceed?",
            ok: "Send Message",
        } satisfies ConfirmDialogData;

        return this.dialog
            .open(ConfirmDialog, {
                disableClose: true,
                data,
            })
            .afterClosed();
    }
    onSend(): void {
        this.loading = true;

        if (this.bulkMode) {
            this.getBulkMsgObs().subscribe({
                next: (targets) => {
                    this.loading = false;
                    this.dialogRef.close(targets);
                },
                error: (error) => {
                    this.loading = false;
                    console.error(error);
                    this.snackbar.open("An error occurred.", undefined, {
                        duration: 2000,
                    });
                },
            });
        } else {
            this.confirmCounterPartyMessage()
                .pipe(
                    concatMap((confirmed) => {
                        if (confirmed) {
                            return this.getMsgObs();
                        }
                        this.loading = false;
                        return EMPTY;
                    }),
                )
                .subscribe((msg: Message | undefined) => {
                    if (this.data.assignment) {
                        this.data.assignment.completed = new Date();
                        this.assignmentService
                            .update(this.data.assignment)
                            .subscribe(() => {
                                this.loading = false;
                                this.dialogRef.close(msg);
                            });
                    } else this.loading = false;
                    this.dialogRef.close(msg);
                });
        }
    }

    roleDisplay(role: Role | TeamMember | string | Patient): string {
        if (role instanceof Role || role instanceof TeamMember) {
            if (role.account?.displayName)
                return role.account.displayName + " (" + role.email + ")";
            return role.email;
        } else if (role instanceof Patient) {
            return role.displayName!;
        }
        return role;
    }

    protected updateAvailableTemplates(id?: string, owner?: string): void {
        if (id) {
            this.templateService
                .retrieve(id)
                .subscribe((template: Template | undefined) => {
                    this.availableTemplates = template ? [template] : [];
                    if (this.data.template)
                        this.formGroup.get("template")?.setValue(template);
                });
        } else {
            let countryIds = this.programCountries.map((pc) => pc.id).join(",");

            // can't return early here when no country specified for legacy reason, because we need to get global and inherited templates

            if (!owner && !countryIds) {
                this.availableTemplates = [];
                return;
            }
            owner = Array.isArray(owner) ? owner.join(",") : owner;
            const ownerTemplates$ =
                owner ? this.templateService.list({ owner: owner ?? "0" }) : of([]);
            const countryTemplates$ =
                countryIds.length ?
                    this.templateService.list({ owner: countryIds })
                :   of([]);

            forkJoin({
                ownerTemplates: ownerTemplates$,
                countryTemplates: countryTemplates$,
            }).subscribe(({ ownerTemplates, countryTemplates }) => {
                this.availableTemplates = [
                    ...(ownerTemplates as Template[]),
                    ...(countryTemplates as Template[]),
                ] as Template[];

                if (this.data.template) {
                    this.formGroup
                        .get("template")
                        ?.setValue(this.availableTemplates[0]);
                }
            });
        }
    }
    protected replaceTemplateVars(
        template: string,
        vars: string[],
        defaults: any,
    ): string | undefined {
        const result = vars.reduce((text: string, field: string) => {
            const dataField = field
                .replace("{{", "")
                .replace("}}", "")
                .trim()
                .replace(/\.+$/, "")
                .replace(/\.+/g, " ")
                .replace(/ +/g, ".")
                .toLowerCase();
            let value = this.data.context ? this.data.context[dataField] : undefined;
            value = value || defaults[dataField] || "";
            return text.replace(new RegExp(field, "g"), value);
        }, template);
        return result !== "" ? result : undefined;
    }

    protected updateMessage(template?: Template): void {
        const templateText = template?.html ?? template?.text ?? "";
        const vars = [
            ...new Set(
                RegExp(TemplateComponent.TEMPLATE_VARAIBLE_REGEX).exec(templateText),
            ),
        ];
        const message = this.replaceTemplateVars(
            templateText,
            vars,
            template?.default_context,
        );

        const templateSubject = template?.subject ?? "";
        const subjectVars = [
            ...new Set(
                RegExp(TemplateComponent.TEMPLATE_VARAIBLE_REGEX).exec(templateSubject),
            ),
        ];
        const subject = this.replaceTemplateVars(
            templateSubject,
            subjectVars,
            template?.default_context,
        );

        setTimeout(() => {
            this.formGroup.controls.message.setValue(message);
            this.formGroup.controls.subject.setValue(subject);
            this.formGroup.controls.message.markAsDirty();
            this.formGroup.controls.subject.markAsDirty();
        });
    }

    protected initializeUploader(): void {
        if (
            this.data.uploadOwner &&
            this.data.repository &&
            this.session.currentAccount
        ) {
            this.documentService
                .fileUploader(
                    this.data.uploadOwner,
                    this.data.repository,
                    this.session.currentAccount,
                )
                .subscribe((uploader?: FileUploaderCustom) => {
                    this.uploader = uploader;
                    if (this.data.send_notification !== undefined && this.uploader) {
                        const options = {
                            ...this.uploader.options,
                            additionalParameter: {
                                send_notification: !!this.data.send_notification,
                            },
                        };
                        this.uploader.setOptions(options);
                    }
                });

            const obs = (this.data.sources ?? []).map((repo: DocumentRepository) =>
                this.documentService.list({ repo: repo.id! }),
            );
            forkJoin(obs)
                .pipe(
                    map((res: APIListResult<Document>[]) =>
                        ([] as ObjectOrReference<Document>[]).concat(
                            ...(res as ObjectOrReference<Document>[][]),
                        ),
                    ),
                )
                .subscribe((documents: ObjectOrReference<Document>[]) => {
                    this.availableDocuments_ = documents;
                    this.attached = documents.filter((d: ObjectOrReference<Document>) =>
                        this.attached.find(
                            (x: ObjectOrReference<Document>) => x.id === d.id,
                        ),
                    );
                });
        } else {
            this.uploader = undefined;
            this.availableDocuments_ = [];
            this.attached = [];
        }
    }

    get chipListInput() {
        return this.formGroup.controls.chipListInput;
    }

    get unAffiliatedCopies() {
        return this.formGroup.controls.unAffiliatedCopies as UntypedFormArray;
    }
    get unAffiliatedCopiesList() {
        return this.unAffiliatedCopies.value;
    }
    get formValid() {
        return this.formGroup.valid;
    }
    get separatorKeysCodes() {
        return [ENTER, COMMA];
    }

    addUnAffiliatedEmail(event: MatChipInputEvent | MouseEvent | FocusEvent) {
        if (!this.chipListInput.valid || !this.chipListInput?.value?.length) return;
        if (event instanceof MouseEvent || event instanceof FocusEvent) {
            this.terminateEvent(event);
        }
        this.unAffiliatedCopiesList.push(this.chipListInput.value);
        this.chipListInput.reset();
    }
    removeEmail(index: number) {
        this.unAffiliatedCopiesList.splice(index, 1);
    }
    onFocusOut(event: FocusEvent) {
        if (this.chipListInput.valid) {
            this.addUnAffiliatedEmail(event);
        } else {
            this.chipListInput.reset();
        }
    }
}
