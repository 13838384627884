<div class="container" [formGroup]="formGroup">
    <div class="content-card">
        <div class="title">Properties</div>
        <div class="settings-section-content flex flexible row">
            <div class="flex flexible">
                <mat-form-field class="flexible header-field">
                    <mat-label>Change Organization Name</mat-label>
                    <input matInput formControlName="name" />
                    <mat-error>Organization name cannot be empty.</mat-error>
                </mat-form-field>
                <mat-form-field
                    class="flexible header-field organization-edit-field"
                    *ngIf="!isHcpStaff"
                >
                    <mat-label>Change Organization Slug</mat-label>
                    <input matInput formControlName="slug" />
                    <mat-hint>This is used in the URL for the intake form.</mat-hint>
                    <mat-error>Slug cannot be empty.</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div
            class="settings-section-content flex flexible row"
            formGroupName="settings"
            *ngIf="!isHcpStaff"
        >
            <mat-form-field class="flexible margin-right">
                <mat-label>Shipment Form</mat-label>
                <mat-select formControlName="shipment_form">
                    <mat-option *ngFor="let form of dataForms" [value]="form.id">
                        {{ form?.displayName ?? form.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flexible">
                <mat-label>Adverse Event URL</mat-label>

                <input matInput formControlName="adverse_event" />
                <mat-hint>
                    Make sure the URL starts with starts with "http://" or "https://".
                </mat-hint>
                <mat-error>
                    Please enter a valid URL, starting with "http://" or "https://".
                </mat-error>
            </mat-form-field>
        </div>

        <div
            class="settings-section-content flex flexible row"
            *ngIf="currentAccount?.isSystemAdministrator && this.fullObject"
        >
            <div class="spacer"></div>
            <div class="">
                <button
                    mat-flat-button
                    color="accent"
                    type="button"
                    (click)="exportConfig($event)"
                    [disabled]="exporting"
                >
                    Export Organization Configuration
                </button>
            </div>
            <div class="spacer"></div>
        </div>
    </div>
    <div class="content-card" *ngIf="!isHcpStaff">
        <div
            class="settings-section-content flex flexible row"
            [formGroup]="settingsFormGroup"
        >
            <div class="column flexible flex stack">
                <div class="title">Integrations</div>
                <div class="flex webhook-container">
                    <div class="flex flexible stack">
                        <mat-form-field class="flexible">
                            <mat-label>Webhook URL</mat-label>
                            <input
                                formControlName="webhook_url"
                                matInput
                                placeholder="Webhook"
                                [errorStateMatcher]="errorMatcher"
                            />
                            @if (isProductionService) {
                                <mat-hint>
                                    Make sure the URL starts with starts with https://
                                </mat-hint>
                            } @else {
                                <mat-hint>
                                    Make sure the URL starts with starts with "http://"
                                    or "https://"
                                </mat-hint>
                            }
                            <mat-error *ngIf="webHookControl?.errors">
                                @if (webHookControl?.hasError("invalidUrl")) {
                                    {{ webHookControl?.errors?.invalidUrl }}
                                } @else if (
                                    webHookControl?.hasError("invalidProtocol")
                                ) {
                                    {{ webHookControl?.errors?.invalidProtocol }}
                                }
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-slide-toggle
                        formControlName="webhook_enabled"
                        [style.margin-left]="'1rem'"
                    >
                        Webhook Enabled
                    </mat-slide-toggle>
                </div>
                <div class="flex stack">
                    <div class="flex api-header flexible">
                        <div class="title-2">API Keys</div>
                        <div class="spacer"></div>
                        <div class="header-actions">
                            <button
                                mat-flat-button
                                color="accent"
                                [disabled]="apiKeys.length >= apiKeyLimit"
                                (click)="createAPIKey($event)"
                            >
                                Create API Key
                            </button>
                        </div>
                    </div>
                    <mat-table [dataSource]="apiKeys">
                        <ng-container matColumnDef="key">
                            <mat-header-cell
                                *matHeaderCellDef
                                [class.api-key]="!editingAPIKey"
                                [class.api-key-name]="!!editingAPIKey"
                            >
                                Key
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let key"
                                [class.api-key]="!editingAPIKey"
                                [class.api-key-name]="!!editingAPIKey"
                            >
                                <div class="api-key-display">
                                    {{ key.api_key }}
                                </div>
                                <button
                                    mat-icon-button
                                    class="api-key-copy"
                                    (click)="copyAPIKey($event, key)"
                                >
                                    <mat-icon class="icon-button-small">
                                        content_copy
                                    </mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="created">
                            <mat-header-cell *matHeaderCellDef class="api-key-date">
                                Created
                            </mat-header-cell>
                            <mat-cell *matCellDef="let key" class="api-key-date">
                                {{ key.created_at | date: "medium" }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell
                                *matHeaderCellDef
                                [class.api-key]="!!editingAPIKey"
                                [class.api-key-name]="!editingAPIKey"
                            >
                                Name
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let key"
                                [class.api-key]="!!editingAPIKey"
                                [class.api-key-name]="!editingAPIKey"
                            >
                                @if (editingAPIKey == key) {
                                    <mat-form-field class="flexible">
                                        <mat-label>API Key Name</mat-label>
                                        <input
                                            [formControl]="apiKeyName"
                                            matInput
                                            placeholder="API Key Name"
                                            [errorStateMatcher]="errorMatcher"
                                        />
                                    </mat-form-field>
                                    <button
                                        mat-icon-button
                                        class="api-key-copy"
                                        (click)="updateAPIKeyName($event)"
                                    >
                                        <mat-icon class="icon-button-small green">
                                            done
                                        </mat-icon>
                                    </button>
                                } @else {
                                    <div class="api-key-display">
                                        {{ key.name }}
                                    </div>
                                    <button
                                        mat-icon-button
                                        class="api-key-copy"
                                        (click)="editAPIKey($event, key)"
                                    >
                                        <mat-icon class="icon-button-small">
                                            edit
                                        </mat-icon>
                                    </button>
                                }
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <mat-header-cell
                                *matHeaderCellDef
                                class="api-key-actions"
                            ></mat-header-cell>
                            <mat-cell *matCellDef="let key" class="api-key-actions">
                                <button
                                    mat-icon-button
                                    (click)="deleteAPIKey($event, key)"
                                >
                                    <mat-icon class="error">delete</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="key-count">
                            <mat-footer-cell
                                *matFooterCellDef
                                [attr.colspan]="apiKeyColumns.length"
                                class="not-found"
                            >
                                {{ apiKeys.length }} of
                                {{ apiKeyLimit }}
                                API keys allocated
                            </mat-footer-cell>
                        </ng-container>
                        <mat-header-row
                            *matHeaderRowDef="apiKeyColumns"
                        ></mat-header-row>
                        <mat-row *matRowDef="let row; columns: apiKeyColumns"></mat-row>
                        <mat-footer-row
                            *matFooterRowDef="['key-count']"
                        ></mat-footer-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
    <div class="content-card system-object-settings">
        <div class="title">System Provided Object Settings</div>
        <div class="container" [formGroup]="systemProvidedObjectSetting">
            <mat-checkbox formControlName="hide_all">
                Hide All System Provided Objects
            </mat-checkbox>
            <ul>
                <li>
                    <mat-checkbox formControlName="hide_system_workflow">
                        Hide System Workflows
                    </mat-checkbox>
                </li>
                <li>
                    <mat-checkbox formControlName="hide_system_form">
                        Hide System Forms
                    </mat-checkbox>
                </li>
                <li>
                    <mat-checkbox formControlName="hide_system_role">
                        Hide System Roles
                    </mat-checkbox>
                </li>
                <li>
                    <mat-checkbox formControlName="hide_system_case_status">
                        Hide System Case Statuses
                    </mat-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <div class="content-card" *ngIf="!isHcpStaff">
        <div *ngIf="!intakeSettingsLoaded" class="error not-found text-center">
            <span>
                <mat-progress-spinner
                    diameter="20"
                    class="inline"
                    mode="indeterminate"
                ></mat-progress-spinner>
                Loading Intake Settings...
            </span>
        </div>
        <div class="container" *ngIf="intakeSettingsLoaded">
            <div class="title">Intake</div>
            <div
                class="settings-section-content settings-section-striped flex flexible row"
            >
                <div
                    class="column flexible flex allow-public"
                    [style.width]="'auto !important'"
                    [formGroup]="intakeSettingsGroup"
                >
                    <mat-slide-toggle formControlName="hosted_intake">
                        Allow Public Intake
                    </mat-slide-toggle>
                    <div class="intake-link flex" *ngIf="!publicIntakeDisabled">
                        <button mat-icon-button disabled>
                            <mat-icon>public</mat-icon>
                        </button>
                        <a
                            *ngIf="!publicIntakeDisabled"
                            target="_blank"
                            rel="noopener noreferrer"
                            [href]="intakeWeb"
                        >
                            {{ intakeWeb }}
                        </a>
                        <span *ngIf="publicIntakeDisabled" class="secondary">
                            {{ intakeWeb }}
                        </span>
                        <button
                            mat-icon-button
                            *ngIf="!publicIntakeDisabled"
                            (click)="copyToClipboard(intakeWeb)"
                        >
                            <mat-icon>content_copy</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            *ngIf="!publicIntakeDisabled"
                            (click)="editSlug = !editSlug"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="flex flexible slug-field">
                    <mat-form-field
                        class="flexible header-field"
                        *ngIf="!isHcpStaff && !publicIntakeDisabled && editSlug"
                    >
                        <mat-label>Change Organization Slug</mat-label>
                        <input matInput formControlName="slug" />
                        <mat-hint>
                            This is used in the URL for the intake form.
                        </mat-hint>
                        <mat-error>Slug cannot be empty.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div
                class="settings-section-content flex flexible row stack"
                [formGroup]="intakeSettingsGroup"
                *ngIf="!publicIntakeDisabled"
            >
                <div class="title-2">Intake Forms</div>
                <div class="flex flexible row intake-form-row">
                    <div class="column fixed allow-intake">
                        <mat-slide-toggle style="opacity: 0"></mat-slide-toggle>
                        Provider Intake
                    </div>
                    <div class="column flexible intake-form flex row">
                        <mat-form-field class="flexible">
                            <mat-label>Provider Intake Form</mat-label>
                            <mat-select
                                #providerSelect
                                (openedChange)="providerOpenedChanged($event)"
                                formControlName="hcp"
                            >
                                <mat-select-trigger>
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{
                                                    providerIntakeForm.value
                                                        ?.displayName
                                                }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ providerIntakeForm.value?.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="
                                                    !providerIntakeForm.value
                                                        ?.is_intake_valid.provider
                                                "
                                                class="amber"
                                                [matTooltip]="
                                                    providerIntakeForm.value?.intake_errors?.provider.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-select-trigger>
                                <mat-option
                                    *ngFor="let form of providerForms"
                                    [value]="form"
                                    [disabled]="!form?.is_intake_valid?.provider"
                                    [class.notAllowed]="
                                        !form?.is_intake_valid?.provider
                                    "
                                >
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{ form.displayName }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ form.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="!form.is_intake_valid?.provider"
                                                [matTooltip]="
                                                    form.intake_errors?.provider?.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                                class="amber"
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="version-select">
                            <button mat-icon-button (click)="toggleProviderVersion()">
                                <mat-icon>history</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex flexible row intake-form-row">
                    <div class="column fixed allow-intake">
                        <mat-slide-toggle
                            formControlName="allow_patient"
                        ></mat-slide-toggle>
                        Patient Intake
                    </div>
                    <div class="column flexible intake-form flex row">
                        <mat-form-field class="flexible">
                            <mat-label>Patient Intake Form</mat-label>
                            <mat-select
                                #patientSelect
                                (openedChange)="patientOpenedChanged($event)"
                                formControlName="patient"
                            >
                                <mat-select-trigger>
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{
                                                    patientIntakeForm.value?.displayName
                                                }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ patientIntakeForm.value?.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="
                                                    !patientIntakeForm.value
                                                        ?.is_intake_valid.patient
                                                "
                                                class="amber"
                                                [matTooltip]="
                                                    patientIntakeForm.value?.intake_errors?.patient.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-select-trigger>
                                <mat-option
                                    *ngFor="let form of patientForms"
                                    [value]="form"
                                    [disabled]="!form?.is_intake_valid?.patient"
                                    [class.notAllowed]="!form?.is_intake_valid?.patient"
                                >
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{ form.displayName }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ form.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="!form.is_intake_valid?.patient"
                                                class="amber"
                                                [matTooltip]="
                                                    form.intake_errors?.patient?.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="version-select">
                            <button
                                mat-icon-button
                                [disabled]="!allowPatientIntake.value"
                                (click)="togglePatientVersion()"
                            >
                                <mat-icon>history</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex flexible row intake-form-row">
                    <div class="column fixed allow-intake">
                        <mat-slide-toggle
                            formControlName="allow_family"
                        ></mat-slide-toggle>
                        Caregiver Intake
                    </div>
                    <div class="column flexible intake-form flex row">
                        <mat-form-field class="flexible">
                            <mat-label>Caregiver Intake Form</mat-label>
                            <mat-select
                                #familySelect
                                (openedChange)="familyOpenedChanged($event)"
                                formControlName="family"
                            >
                                <mat-select-trigger>
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{
                                                    familyIntakeForm.value?.displayName
                                                }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ familyIntakeForm.value?.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="
                                                    !familyIntakeForm.value
                                                        ?.is_intake_valid.caregiver
                                                "
                                                class="amber"
                                                [matTooltip]="
                                                    familyIntakeForm.value?.intake_errors?.caregiver.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-select-trigger>
                                <mat-option
                                    *ngFor="let form of familyForms"
                                    [value]="form"
                                    [disabled]="!form?.is_intake_valid?.caregiver"
                                    [class.notAllowed]="
                                        !form?.is_intake_valid?.caregiver
                                    "
                                >
                                    <div class="flex flexible row">
                                        <div class="flex flexible stack">
                                            <div>
                                                {{ form.displayName }}
                                            </div>
                                            <div class="secondary small">
                                                Version
                                                {{ form.version }}
                                            </div>
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="fixed">
                                            <mat-icon
                                                *ngIf="!form.is_intake_valid?.caregiver"
                                                class="amber"
                                                [matTooltip]="
                                                    form.intake_errors?.caregiver?.join(
                                                        '\n'
                                                    ) ?? ''
                                                "
                                            >
                                                warning
                                            </mat-icon>
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="version-select">
                            <button
                                mat-icon-button
                                [disabled]="!allowFamilyIntake.value"
                                (click)="toggleFamilyVersion()"
                            >
                                <mat-icon>history</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="settings-section-content flex flexible row stack"
                [formGroup]="intakeSettingsGroup"
            >
                <div class="title-2" [style.margin-bottom]="'1rem'">Branding</div>
                <div class="title-1">Header Example</div>
                <div
                    class="settings-section-content intake-app-bar flex mat-elevation-z4"
                    [style.background]="brandingBgColor"
                >
                    <div class="title">
                        <img
                            *ngIf="templateLogoSource"
                            [src]="templateLogoSource"
                            class="brand-logo"
                            [alt]="this.displayNameControl.value ?? ''"
                        />
                        <span
                            *ngIf="!templateLogoSource"
                            [style.color]="brandingTextColor"
                        >
                            {{ this.displayNameControl.value ?? "" }}
                        </span>
                    </div>
                    <div class="spacer"></div>
                    <div class="powered-by flex" [style.filter]="templateMedaLogoColor">
                        <div class="tag">powered by</div>
                        <img
                            alt="MedaSystems Logo"
                            class="logo"
                            src="/assets/logo-white.png"
                        />
                    </div>
                </div>

                <div class="title-1" [style.margin-top]="'1rem'">Branding Colors</div>

                <div class="settings-section-content settings-color-picker">
                    <div class="settings-color-picker-row">
                        <div class="settings-color-picker-label">Background Color</div>
                        <input
                            [style.background]="brandingBgColor"
                            [(colorPicker)]="brandingBgColor"
                            [cpAlphaChannel]="'disabled'"
                            [cpOutputFormat]="'hex'"
                            (cpInputChange)="
                                onColorPickerChange('template_bgcolor', $event)
                            "
                            (cpSliderDragEnd)="
                                onColorPickerChange('template_bgcolor', $event)
                            "
                            class="settings-color-picker-swatch"
                            readonly
                        />
                    </div>

                    <div class="settings-color-picker-row">
                        <div class="settings-color-picker-label">Text Color</div>
                        <input
                            [style.background]="brandingTextColor"
                            [(colorPicker)]="brandingTextColor"
                            [cpAlphaChannel]="'disabled'"
                            [cpOutputFormat]="'hex'"
                            (cpInputChange)="
                                onColorPickerChange('template_textcolor', $event)
                            "
                            (cpSliderDragEnd)="
                                onColorPickerChange('template_textcolor', $event)
                            "
                            class="settings-color-picker-swatch"
                            readonly
                        />
                    </div>
                </div>

                <div class="title-1" [style.margin-top]="'1rem'">Branding Logo</div>
                <object-form
                    [hideHeader]="!!dialogReference"
                    [hideControls]="!!dialogReference"
                    [controller]="controller"
                    [formGroup]="settingsFormGroup"
                >
                    <div class="settings-section-content">
                        <div
                            class="flex flexible template-logo"
                            *ngIf="!!fullObject?.settings?.template_logo"
                        >
                            <div class="flexible">
                                <div class="flex" [style.align-items]="'center'">
                                    <img
                                        [src]="templateLogoSource"
                                        class="template-logo-image"
                                        alt="Organization Logo"
                                    />
                                    <button
                                        mat-icon-button
                                        (click)="removeLogo($event)"
                                        matTooltip="Remove Logo"
                                    >
                                        <mat-icon class="red">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="template-logo uploader"
                            *ngIf="!fullObject?.settings?.template_logo && uploader"
                        >
                            <div
                                ng2FileDrop
                                class="drop-zone"
                                [class.over]="fileOver"
                                (fileOver)="fileOver = $event"
                                [uploader]="uploader"
                            >
                                <input
                                    type="file"
                                    ng2FileSelect
                                    id="file-select"
                                    [uploader]="uploader"
                                    hidden
                                />
                                Drop an image file
                                <strong>here</strong>
                                or
                                <input
                                    type="file"
                                    ng2FileSelect
                                    id="file-select"
                                    [uploader]="uploader"
                                    hidden
                                />
                                <button mat-raised-button [style.margin]="' 0 0.25rem'">
                                    <label for="file-select">select</label>
                                </button>
                                to set a logo.
                            </div>
                        </div>
                    </div>

                    <div class="settings-section-striped">
                        <div class="title-2" [style.margin-top]="'1rem'">
                            Organization Information
                        </div>
                        <div class="settings-section-content">
                            <div class="row flex">
                                <mat-form-field class="full">
                                    <mat-label>Address (Optional)</mat-label>
                                    <input
                                        matInput
                                        placeholder="Contact Address (Optional)"
                                        formControlName="contact_address"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row flex">
                                <mat-form-field class="column flexible">
                                    <mat-label>Phone (Optional)</mat-label>
                                    <input
                                        matInput
                                        placeholder="Contact Phone (Optional)"
                                        formControlName="contact_phone"
                                    />
                                </mat-form-field>
                                <mat-form-field class="column flexible">
                                    <mat-label>Email (Optional)</mat-label>
                                    <input
                                        matInput
                                        placeholder="Contact Email (Optional)"
                                        formControlName="contact_email"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </object-form>
            </div>
        </div>
    </div>

    <div class="content-card" *ngIf="!isHcpStaff">
        <case-status-admin
            [owner]="fullObject"
            [allowSystemProvided]="!hideSystemCaseStatuses"
            displayName="Case Statuses"
        ></case-status-admin>
    </div>
    <div class="content-card">
        <capability-admin
            [organization]="fullObject"
            [isPharma]="!isHcpStaff"
            [hideSystemRoles]="fullObject?.hideSystemRoles!!"
        ></capability-admin>
    </div>
</div>
