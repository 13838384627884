<div class="container">
    <div class="programs-header flex">
        <div class="title">Staff</div>
        <div class="spacer"></div>
        <div class="header-actions">
            <mat-form-field
                color="primary"
                *ngIf="!isSearchEmpty"
                class="program-search"
            >
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <mat-label>Search Staff</mat-label>
                <input
                    matInput
                    #search
                    placeholder="Enter at least 3 characters to search"
                    [formControl]="searchTermControl"
                    (focusout)="onFocusOut($event)"
                />
                <button
                    matSuffix
                    type="button"
                    mat-icon-button
                    (click)="resetSearchTerm($event)"
                    *ngIf="!!searchTermControl.value"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
            <button
                mat-flat-button
                class="brand-medium"
                *ngIf="isSearchEmpty"
                (click)="toggleSearch($event)"
                matTooltip="Search Staff"
            >
                <mat-icon>search</mat-icon>
                Search
            </button>
            <button
                mat-flat-button
                class="brand-medium"
                (click)="createObject($event, true)"
            >
                <mat-icon>open_in_new</mat-icon>
                Add Staff
            </button>
        </div>
    </div>
    <mat-table [dataSource]="list" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="account__email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell
                *matCellDef="let countryStaff"
                (click)="
                    !isInheritedAccessUser(countryStaff) &&
                        !staffIsCurrentUser(countryStaff) &&
                        editObject($event, countryStaff, true)
                "
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
                class="cell-flex-column-start"
            >
                <div>{{ countryStaff.account.name }}</div>
                <div class="small">{{ countryStaff.email }}</div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="capabilities__display_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
            <mat-cell
                *matCellDef="let countryStaff"
                (click)="
                    !isInheritedAccessUser(countryStaff) &&
                        !staffIsCurrentUser(countryStaff) &&
                        editObject($event, countryStaff, true)
                "
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
            >
                {{ countryStaff.capabilityDisplay }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permission__role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Permission
            </mat-header-cell>
            <mat-cell
                *matCellDef="let countryStaff"
                (click)="
                    !isInheritedAccessUser(countryStaff) &&
                        !staffIsCurrentUser(countryStaff) &&
                        editObject($event, countryStaff, true)
                "
                (keydown)="onKeyPress($event)"
                (keyup)="onKeyUp($event)"
                (keypress)="onKeyDown($event)"
                class="cell-flex-column-start"
            >
                <div>{{ getRoleDisplay(countryStaff) }}</div>
                <div *ngIf="isInheritedAccessUser(countryStaff)" class="small">
                    {{ getRoleInheritedHint(countryStaff) }}
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="overflow"></mat-header-cell>
            <mat-cell *matCellDef="let countryStaff" class="overflow">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="overflow"
                    [matMenuTriggerData]="{ countryStaff: countryStaff }"
                    [disabled]="
                        isInheritedAccessUser(countryStaff) ||
                        staffIsCurrentUser(countryStaff)
                    "
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="no-records">
            <mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="displayedColumns.length"
                class="error not-found"
            >
                No country staff found.
            </mat-footer-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
            [class.country-staff-inherited-user-row]="isInheritedAccessUser(row)"
            *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
        <mat-footer-row
            *matFooterRowDef="['no-records']"
            [class.hidden-footer]="!!list.list.items.length"
        ></mat-footer-row>
        <mat-menu #overflow="matMenu">
            <ng-template matMenuContent let-countryStaff="countryStaff">
                <button
                    mat-menu-item
                    [disabled]="staffIsCurrentUser(countryStaff)"
                    (click)="
                        editObject(
                            $event,
                            countryStaff,
                            true,
                            staffIsCurrentUser(countryStaff)
                        )
                    "
                >
                    Edit
                </button>
                <!-- TODO: Change to removeObject method so confirmation dialog text can be customized to clarify user is only being removed from this country-staff team-->
                <button
                    mat-menu-item
                    (click)="
                        isOrganizationAdministrator &&
                            deleteObject($event, countryStaff)
                    "
                    [disabled]="
                        !isOrganizationAdministrator ||
                        countryStaff?.account?.id === currentAccount?.id
                    "
                >
                    Remove Staff
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator
        (page)="list.handlePageEvent($event)"
        [pageSizeOptions]="list.paginatorSizeOptions"
        [pageSize]="list.pageSize"
        [length]="list.paginatorLength"
        [disabled]="list.searching"
        [showFirstLastButtons]="true"
        [pageIndex]="list.currentPage"
    ></mat-paginator>
</div>
