import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    Inject,
    Input,
    ViewChild,
    inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Sort } from "@angular/material/sort";
import { MatFooterRowDef, MatTable } from "@angular/material/table";
import { FileUploaderOptions } from "ng2-file-upload";
import { Observable, forkJoin, of } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";

import {
    DateFilterConfig,
    FilterDefinition,
} from "src/common/components/data-filter/data-filter.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { SendTemplateDialog } from "src/common/components/template/send-template.dialog";
import { setDateDisableControls } from "src/common/utilities/date-control-utils";
import { RequestFilter } from "src/common/utilities/request";
import { convertDate, DisplayNameMap } from "src/common/utilities/utilities";
import { CaseComponent } from "src/program/components/case/case.component";
import { ConfirmBulkUploadDialog } from "src/program/components/document-repository/ConfirmBulkUpload";
import { DocumentComponent } from "src/program/components/document-repository/document.component";
import { InquiryComponent } from "src/program/components/inquiry/inquiry.component";
import {
    AccountService,
    OrganizationService,
    OrganizationSettingsFactory,
} from "src/services/iam.services";
import {
    ObjectOrReference,
    ObjectReference,
    OptionalObjectOrReference,
    ProgramReference,
} from "src/services/models/api-object";
import { Case, Status } from "src/services/models/case";
import { Document } from "src/services/models/document";
import { Inquiry } from "src/services/models/inquiry";
import { Organization } from "src/services/models/organization";
import { Patient } from "src/services/models/patient";
import { CaseTeam, TeamMember, TeamMemberFactory } from "src/services/models/team";
import {
    StatusService,
    PatientService,
    CaseService,
    InquiryService,
    CaseTeamService,
    TeamService,
    ProgramService,
    CountryService,
} from "src/services/program.services";
import { BulkMessageData, MessageService } from "src/services/notification.services";
import { Message } from "src/services/models/message";
import { Program } from "src/services/models/program";
import { Country } from "src/services/models/country";
import { Account } from "src/services/models/account";
import { Product } from "src/services/models/product";
import { DerivedPermission } from "src/services/models/role";

export type PatientListMode = "physician" | "recent" | "pharma";
@Component({
    selector: "patients",
    templateUrl: "./patients.component.html",
    styleUrls: ["./patients.component.scss"],
})
export class PatientsComponent extends ObjectAdminComponent<Patient> {
    @ViewChild(MatFooterRowDef, { static: true }) footerDef?: MatFooterRowDef;
    @ViewChild(MatTable, { static: true }) table?: MatTable<Document>;
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: MatSelect;

    @Input() enablePagination = false;
    @Input() title: string = "Cases";
    @Input() set initialFilter(f: string[]) {
        this.filterControl.setValue(f);
    }
    @Input() set mode(v: PatientListMode) {
        this._mode = v;
        if (v == "recent")
            this.list.ordering = [{ field: "received_at", ascending: false }];
        else if (v == "pharma")
            this.list.ordering = [{ field: "created_at", ascending: false }];
    }
    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe((role: any) => {
                this.dashboardRole = role["role"];
                this.showBulkUpload = this.dashboardRole === "pharma";
            });
    }
    protected caseStatusService: StatusService;

    _caseStauses?: Status[];
    _mode: PatientListMode = "pharma";
    caseService: CaseService;
    caseTeamService: CaseTeamService;

    casesBetweenEndDateFilterControl = new FormControl();
    casesBetweenStartDateFilterControl = new FormControl();
    casesCountryFilterControl = new FormControl();
    casesDateAfterFilterControl = new FormControl();
    casesDateBeforeFilterControl = new FormControl();
    casesDateFilterConfigurations: DateFilterConfig[] = [
        {
            betweenStartDateControlName: "casesBetweenStartDateFilterControl",
            betweenEndDateControlName: "casesBetweenEndDateFilterControl",
            dateBeforeControlName: "casesDateBeforeFilterControl",
            dateAfterControlName: "casesDateAfterFilterControl",
            dateAfterLabel: "Created After",
            dateBeforeLabel: "Created Before",
            betweenDateLabel: "Created Between",
        },
    ];
    casesOrgsFilterControl = new FormControl();
    casesOwnerFilterControl = new FormControl();
    casesProgramFilterControl = new FormControl();
    casesTypeFilterControl = new FormControl();
    contact: UntypedFormControl = new UntypedFormControl();
    countries: Country[] = [];
    // protected readonly countryService = inject(CountryService); // --> countryService is declared and initialized in the constructor, hence this is not needed. Remove comment after testing.
    protected readonly destroy = inject(DestroyRef);

    filterCasesDefinitions: FilterDefinition[] = [];
    filterCasesFormGroup: FormGroup;
    filterControl: UntypedFormControl = new UntypedFormControl();
    filterPendingInquiriesDefinitions: FilterDefinition[] = [];
    filterPendingInquiriesFormGroup: FormGroup;
    filterPendingInquiriesOwners: FilterDefinition[] = [];
    inquiryService: InquiryService;
    organizationService: OrganizationService;

    pendingInquiriesBetweenEndDateFilterControl = new FormControl();
    pendingInquiriesBetweenStartDateFilterControl = new FormControl();
    pendingInquiriesCountryFilterControl = new FormControl();
    pendingInquiriesDateAfterFilterControl = new FormControl();
    pendingInquiriesDateBeforeFilterControl = new FormControl();
    pendingInquiriesDateFilterConfigurations: DateFilterConfig[] = [
        {
            betweenStartDateControlName:
                "pendingInquiriesBetweenStartDateFilterControl",
            betweenEndDateControlName: "pendingInquiriesBetweenEndDateFilterControl",
            dateBeforeControlName: "pendingInquiriesDateBeforeFilterControl",
            dateAfterControlName: "pendingInquiriesDateAfterFilterControl",
            dateAfterLabel: "Received After",
            dateBeforeLabel: "Received Before",
            betweenDateLabel: "Received Between",
        },
    ];
    pendingInquiriesFilterControl = new FormControl();
    pendingInquiriesOrgsFilterControl = new FormControl();
    pendingInquiriesOwnerFilterControl = new FormControl();
    pendingInquiriesProgramFilterControl = new FormControl();
    pendingInquiriesTypeFilterControl = new FormControl();
    pendingInquiriesTypeMapping: { [key: string]: string } = {
        Physician: "provider.physician",
        Internal: "internal",
        Patient: "patient",
        Caregiver: "patient.caregiver",
        "Physician Staff": "provider.staff",
    };
    physicianFilterControl = new FormControl();
    physicianOrgs: ObjectOrReference<Organization>[] = [];
    physicianOrgsFilterControl = new FormControl();
    physicians: ObjectReference[] = [];
    owners: ObjectOrReference<Account>[] = [];
    searchTermControl: UntypedFormControl = new UntypedFormControl();
    showBulkUpload = false;
    showCasesFilter: boolean = false;
    showPendingInquiriesFilter: boolean = false;
    showSearch: boolean = false;
    statusControl = new FormControl();
    orgService: OrganizationService;
    protected snackbar: MatSnackBar;
    countryColumnName = "country__display_name";
    countryValidColumnName = "country_valid";
    get displayedColumns(): string[] {
        if (this.mode === "recent") {
            return [
                this.countryValidColumnName,
                "contact",
                "institution_name",
                this.countryColumnName,
                "received_at",
                "owners__full_name",
                "source",
            ];
        } else if (this.mode === "physician") {
            return [
                "reference_identifier",
                "name",
                "case_status__name",
                "organization_name",
            ];
        }
        return [
            this.countryValidColumnName,

            "reference_identifier",
            "name",
            "owners__full_name",
            this.countryColumnName,
            "physician_name",
            "type",
            "case_status__name",
            "created_at",
        ];
    }

    get availableStatuses(): DisplayNameMap[] {
        return Inquiry.InquiryStatus;
    }

    //per the tickets, only admins and managers can use bulk action
    get canBulkAction() {
        const isAdmin = this.currentAccount?.hasRole("object.admin");
        const isManager =
            this.currentAccount?.hasRole("organization.manager") ||
            this.currentAccount?.hasRole("object.manager");

        return !!isAdmin || !!isManager || !!this.currentAccount?.isSystemAdministrator;
    }

    get caseStatuses() {
        return this._caseStauses ?? [];
    }

    get casesType() {
        return ["Case", "Inquiry"];
    }

    get countryArray() {
        return this.countryService.countries ?? [];
    }

    get defaultCasesFilter() {
        return {
            type: [],
            status: this.defaultStatusFilters,
            physician: [],
            program: [],
            country: [],
            owner: [],
            organizations: [],
            physicianOrgs: [],
        };
    }

    get defaultPendingInquiriesFilter() {
        return {
            contact: [],
        };
    }

    get defaultStatusFilters() {
        //MED-2794 - default to all cases
        //let open: string[] = this.caseStatuses
        //    ?.filter((s) => !s?.attributes.closes_case)
        //    ?.map((s) => `status.${s.id}`);
        //open = open?.length ? open : ["status.open"];
        return [];
    }

    get dynamicCasesOptions() {
        const options: any = {
            casesType: this.casesType,
            status: this.caseStatuses,
            physician: this.physicianArray,
            casesProgram: this.programArray,
            casesCountry: this.countryArray,
            casesOwner: this.ownerArray,
            physicianOrgs: this.physicianOrgsArray,
        };

        if (this.userOrgs!.length > 1) {
            options.casesOrganizations = this.userOrgs;
        }

        return options;
    }

    get dynamicPendingInquiriesOptions() {
        const options: any = {
            pendingInquiriesType: this.pendingInquiriesType,
            pendingInquiriesProgram: this.programArray,
            pendingInquiriesCountry: this.countryArray,
        };

        if (this.owners.length) {
            options.pendingInquiriesOwners = this.ownerArray;
        }

        if (this.userOrgs!.length > 1) {
            options.pendingInquiriesOrganizations = this.userOrgs;
        }

        return options;
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    get mode(): PatientListMode {
        return this._mode;
    }

    get ownerArray() {
        return Array.from(this.owners);
    }

    get pendingInquiriesType() {
        return Object.keys(this.pendingInquiriesTypeMapping);
    }

    get physicianArray() {
        return Array.from(this.physicians);
    }

    get physicianOrgsArray() {
        return this.physicianOrgs;
    }

    get programArray(): string[] {
        return this.programs
            .map((p) => p.object_display_name!)
            .sort((a, b) => a.localeCompare(b));
    }

    get today() {
        return new Date();
    }

    get userOrgs() {
        return this.currentAccount?.orgs;
    }

    set caseStatuses(v: Status[]) {
        this._caseStauses = v;

        // Update the disabled state of the form control whenever caseStatuses changes
        if (!this.caseStatuses || this.caseStatuses.length === 0) {
            this.filterCasesFormGroup.get("status")?.disable();
        } else {
            this.filterCasesFormGroup.get("status")?.enable();
        }

        if (
            !this.statusControl.value &&
            !this.statusControl.touched &&
            this.mode !== "recent"
        ) {
            this.statusControl.setValue(this.defaultStatusFilters);
        }
    }
    messageService: MessageService;
    accountService: AccountService;
    programService: ProgramService;
    protected countryService: CountryService;
    constructor(
        protected service: PatientService,
        protected changeDetection: ChangeDetectorRef,
        @Inject(Number) pageSize: number = -1,
        @Inject(String) listTitle: string = "base-patients-title",
    ) {
        super(service, changeDetection, pageSize, listTitle);
        this.list.ordering = [{ field: "created_at", ascending: false }];
        this.caseStatusService = inject(StatusService);
        this.caseService = inject(CaseService);
        this.caseTeamService = inject(CaseTeamService);
        this.inquiryService = inject(InquiryService);
        this.organizationService = inject(OrganizationService);
        this.orgService = inject(OrganizationService);
        this.snackbar = inject(MatSnackBar);
        this.messageService = inject(MessageService);
        this.accountService = inject(AccountService);
        this.programService = inject(ProgramService);
        this.countryService = inject(CountryService);
        inject(TeamService);
        inject(TeamMemberFactory);
        inject(OrganizationSettingsFactory);
        this.filterCasesFormGroup = new FormGroup({
            casesBetweenEndDateFilterControl: this.casesBetweenEndDateFilterControl,
            casesBetweenStartDateFilterControl: this.casesBetweenStartDateFilterControl,
            casesCountry: this.casesCountryFilterControl,
            casesDateAfterFilterControl: this.casesDateAfterFilterControl,
            casesDateBeforeFilterControl: this.casesDateBeforeFilterControl,
            casesOrganizations: this.casesOrgsFilterControl,
            casesOwner: this.casesOwnerFilterControl,
            casesProgram: this.casesProgramFilterControl,
            casesType: this.casesTypeFilterControl,
            physician: this.physicianFilterControl,
            physicianOrgs: this.physicianOrgsFilterControl,
            status: this.statusControl,
        });
        this.filterPendingInquiriesFormGroup = new FormGroup({
            pendingInquiriesBetweenEndDateFilterControl:
                this.pendingInquiriesBetweenEndDateFilterControl,
            pendingInquiriesBetweenStartDateFilterControl:
                this.pendingInquiriesBetweenStartDateFilterControl,

            pendingInquiriesCountry: this.pendingInquiriesCountryFilterControl,
            pendingInquiriesDateAfterFilterControl:
                this.pendingInquiriesDateAfterFilterControl,
            pendingInquiriesDateBeforeFilterControl:
                this.pendingInquiriesDateBeforeFilterControl,
            pendingInquiriesOrganizations: this.pendingInquiriesOrgsFilterControl,
            pendingInquiriesOwner: this.pendingInquiriesOwnerFilterControl,
            pendingInquiriesProgram: this.pendingInquiriesProgramFilterControl,
            pendingInquiriesType: this.pendingInquiriesTypeFilterControl,
        });
        this.setupCasesFilterDefinitions();
        this.setupPendingInquiriesDefinitions();
    }

    ngOnInit() {
        if (this.mode !== "recent" && this.canBulkAction) {
            if (!this.dashboardRoleDefined || !this.dashboardRole) {
                this.updateDashboardRole();
            } else {
                this.showBulkUpload = this.dashboardRole === "pharma";
            }
        }

        this.mode !== "recent" ?
            setDateDisableControls([
                {
                    betweenStartDate: this.casesBetweenStartDateFilterControl,
                    betweenEndDate: this.casesBetweenEndDateFilterControl,
                    dateAfter: this.casesDateAfterFilterControl,
                    dateBefore: this.casesDateBeforeFilterControl,
                },
            ])
        :   setDateDisableControls([
                {
                    betweenStartDate:
                        this.pendingInquiriesBetweenStartDateFilterControl,
                    betweenEndDate: this.pendingInquiriesBetweenEndDateFilterControl,
                    dateAfter: this.pendingInquiriesDateAfterFilterControl,
                    dateBefore: this.pendingInquiriesDateBeforeFilterControl,
                },
            ]);
        this.getCountries();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (this.mode !== "recent") {
            this.filterCasesFormGroup.valueChanges
                .pipe(debounceTime(600))
                .subscribe(() => {
                    this.list.refresh();
                });
        } else if (this.mode === "recent") {
            this.filterPendingInquiriesFormGroup.valueChanges
                .pipe(debounceTime(600))
                .subscribe(() => {
                    this.list.refresh();
                });
        }

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => {
                this.updateList(term);
            });
        this.list.refresh();
    }

    _isOrganizationAdministrator(p: Patient): boolean {
        const account = this.currentAccount;
        return !!p.pharma && !!account?.hasRole("object.admin", p.pharma); // has the object.admin role on the case owner
    }

    _isProgramAdministrator(p: Patient): boolean {
        const account = this.currentAccount;
        return !!p.program && !!account?.hasRole("object.admin", p.program);
    }

    accountHasRole(patient: Patient, role: string): boolean {
        const account = this.currentAccount;
        if (
            this._isProgramAdministrator(patient) ||
            this._isOrganizationAdministrator(patient)
        )
            return true;
        return !!account?.hasRole(role, patient.object);
    }

    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }

    bulkDocumentUpload(e: MouseEvent) {
        this.terminateEvent(e);
        if (!this.canBulkAction || !this.showBulkUpload) return;

        this.dialog
            .open(ConfirmBulkUploadDialog, {
                data: { patients: this.list.items },
            })
            .afterClosed()
            .subscribe((patients?: Patient[]) => {
                if (!patients) {
                    return;
                }
                const caseIds = patients
                    .filter((p) => p.object.type === "program.case")
                    .map((p) => p.object.id!);
                const inquiryIds = patients
                    .filter((p) => p.object.type !== "program.case")
                    .map((p) => p.object.id!);

                const inquiries =
                    inquiryIds.length ?
                        this.inquiryService.list({ id: inquiryIds.join(",") })
                    :   of([]);
                const cases =
                    caseIds.length ?
                        this.caseService.list({ id: caseIds.join(",") })
                    :   of([]);
                const orgIds = this.userOrgs!.map((o: ObjectReference) => o.id);
                const organizations = this.orgService.list({ id: orgIds.join(",") });

                const mode = ObjectViewMode.Create;
                const doc = new Document();
                const config = this.objectDialogConfiguration(new Patient(), mode);
                const instance = ObjectAdminComponent.showObject<Document>(
                    doc,
                    DocumentComponent,
                    mode,
                    config,
                ) as DocumentComponent;
                instance.bulkMode = true;

                forkJoin({ inquiries, cases, organizations })
                    .pipe(
                        map(({ inquiries, cases, organizations }) => ({
                            patients: [
                                ...(inquiries as Inquiry[]),
                                ...(cases as Case[]),
                            ],
                            organizations: organizations as Organization[],
                        })),
                    )
                    .subscribe(({ patients, organizations }) => {
                        let initial: Case | Inquiry | undefined = undefined;
                        let redactionEnabledOrg: any = undefined;
                        const sources = [this.currentAccount?.asReference!];
                        const seenIds = new Set<string>();
                        patients.forEach((obj) => {
                            if (obj?.owner?.id && !seenIds.has(obj.owner.id)) {
                                sources.push(obj.owner);
                                seenIds.add(obj.owner.id);
                            }

                            if (
                                obj instanceof Inquiry &&
                                obj?.program?.id &&
                                !seenIds.has(obj.program.id)
                            ) {
                                sources.push(obj.program);
                                seenIds.add(obj.program.id);
                            } else if (
                                obj instanceof Case &&
                                obj?.shared?.program?.id &&
                                !seenIds.has(obj?.shared.program?.id)
                            ) {
                                sources.push(obj.shared.program);
                                seenIds.add(obj.shared.program.id);
                            }

                            if (!initial) {
                                const org = organizations.find(
                                    (o: Organization) => o.id === obj.owner?.id,
                                );
                                if (org?.isEntitlementEnabled("redaction")) {
                                    redactionEnabledOrg = org;
                                    initial = obj;
                                }
                            }
                        });

                        const repository =
                            initial && redactionEnabledOrg ?
                                this.currentAccount!
                            :   patients.pop();
                        instance.patients = patients;
                        const options: FileUploaderOptions = {
                            additionalParameter: {
                                notify: !initial && !redactionEnabledOrg,
                                asyncRedaction: false,
                                redactionEnabledOrg:
                                    redactionEnabledOrg instanceof Organization ?
                                        redactionEnabledOrg.id!
                                    :   undefined,
                            },
                            url: "",
                        };
                        instance.initializeUploader(
                            this.currentAccount!,
                            repository!,
                            sources,
                            undefined,
                            options,
                        );
                    });
            });
    }

    cancel(event: MouseEvent) {
        this.terminateEvent(event);
        this.showCasesFilter = false;
        this.showPendingInquiriesFilter = false;

        if (this.mode !== "recent") {
            this.filterCasesFormGroup.markAsUntouched();
            this.filterCasesFormGroup.markAsPristine();
            this.filterCasesFormGroup.reset(this.defaultCasesFilter);
        } else if (this.mode === "recent") {
            this.filterPendingInquiriesFormGroup.markAsUntouched();
            this.filterPendingInquiriesFormGroup.markAsPristine();
            this.filterPendingInquiriesFormGroup.reset(
                this.defaultPendingInquiriesFilter,
            );
        }
    }

    canEdit(c: Patient): boolean {
        return (
            this.accountHasRole(c, "object.edit") ||
            this.accountHasRole(c, "organization.manager")
        );
    }

    caseCreatedAt(c: Patient): Date | null {
        return c.created_at ? new Date(c.created_at) : null;
    }

    caseReference(c: Patient): number {
        return c.reference_identifier;
    }

    caseStatus(patient: Patient): string {
        let statusId = patient?.status;
        if (patient.object instanceof Case) {
            statusId = patient.object.shared.status;
        } else if (patient.object instanceof Inquiry) {
            statusId = patient.object.status;
        }

        return this.caseStatuses?.find((s) => s.id === statusId)
            ?.display_name as string;
    }

    clearAllFilters(event: MouseEvent) {
        this.terminateEvent(event);
        if (this.mode !== "recent") {
            this.filterCasesFormGroup.reset();
            this.filterCasesFormGroup.markAllAsTouched();
        } else if (this.mode === "recent") {
            this.filterPendingInquiriesFormGroup.reset();
            this.filterPendingInquiriesFormGroup.markAllAsTouched();
        }
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["access"] = this.currentAccount?.id ?? "0";
        const orgs =
            this.mode !== "recent" ?
                this.casesOrgsFilterControl.value || []
            :   this.pendingInquiriesOrgsFilterControl.value || [];
        const programs =
            this.mode !== "recent" ?
                this.casesProgramFilterControl.value || []
            :   this.pendingInquiriesProgramFilterControl.value || [];
        const countries =
            (this.casesCountryFilterControl.value ||
                this?.pendingInquiriesCountryFilterControl?.value) ??
            [];
        const owners = this?.casesOwnerFilterControl?.value ?? [];
        const statuses = (this.statusControl.value || this.defaultStatusFilters || [])
            .filter((f: string) => f.startsWith("status."))
            .map((f: string) => f.replace("status.", ""));

        const physicians = this.physicianFilterControl.value || [];
        const physicianOrgs = this.physicianOrgsFilterControl.value || [];

        if (physicianOrgs?.length) filters["physician_org"] = physicianOrgs.join(",");
        if (owners?.length) filters["owner"] = owners.join(",");
        if (physicians.length) filters["physician"] = physicians.join(",");
        if (programs.length) filters["program"] = programs.join(",");
        if (orgs.length) filters["orgs"] = orgs.join(",");
        if (statuses.length) filters["status"] = statuses.join(",");
        if (countries.length) {
            filters["country"] = countries.join(",");
        }

        if (this.mode !== "recent") {
            const casesTypes = this.casesTypeFilterControl.value || [];

            if (casesTypes.length) {
                const lowercaseTypes = casesTypes.map((type: string) =>
                    type.toLowerCase(),
                );
                filters["type"] = lowercaseTypes.join(",");
            }
        } else if (this.mode === "recent") {
            const pendingInquiriesTypes =
                this.pendingInquiriesTypeFilterControl.value || [];

            if (pendingInquiriesTypes.length) {
                const dataTypes = pendingInquiriesTypes
                    .map(
                        (type: string) =>
                            this.pendingInquiriesTypeMapping[type] || type,
                    )
                    .filter((type: string) => type !== "");

                filters["source"] = dataTypes.join(",");
            }

            //pending inquiries should only house inquiries that haven’t been
            filters["type"] = "inquiry";
        }

        let betweenStartDate =
            this.mode !== "recent" ?
                this.casesBetweenStartDateFilterControl.value
            :   this.pendingInquiriesBetweenStartDateFilterControl.value;
        let betweenEndDate =
            this.mode !== "recent" ?
                this.casesBetweenEndDateFilterControl.value
            :   this.pendingInquiriesBetweenEndDateFilterControl.value;
        if (betweenStartDate instanceof Date && betweenEndDate instanceof Date) {
            betweenStartDate = convertDate(betweenStartDate).join("-");
            betweenEndDate = convertDate(betweenEndDate).join("-");

            filters["date_between"] = `${betweenStartDate},${betweenEndDate}`;
        }

        let beforeDate =
            this.mode !== "recent" ?
                this.casesDateBeforeFilterControl.value
            :   this.pendingInquiriesDateBeforeFilterControl.value;
        if (beforeDate instanceof Date) {
            beforeDate = convertDate(beforeDate).join("-");
            filters["date_before"] = beforeDate;
        }

        let afterDate =
            this.mode !== "recent" ?
                this.casesDateAfterFilterControl.value
            :   this.pendingInquiriesDateAfterFilterControl.value;
        if (afterDate instanceof Date) {
            afterDate = convertDate(afterDate).join("-");
            filters["date_after"] = afterDate;
        }

        return filters;
    }

    getFilterTooltipText(): string {
        if (this.mode === "recent") {
            return "Filter Pending Inquiries";
        } else if (this.mode === "pharma") {
            return "Filter Cases";
        } else {
            return "Filter";
        }
    }

    getMode(viewOnly: boolean, canEdit: boolean): ObjectViewMode {
        if (canEdit) {
            return ObjectViewMode.Edit;
        } else {
            return ObjectViewMode.View;
        }
    }

    getOptionValue(option: any, controlName: string): any {
        if (controlName === "type") {
            return option.toLowerCase();
        } else if (controlName === "status") {
            return `status.${option.id}`;
        } else if (option?.id) {
            return option.id;
        }

        return option;
    }

    getOptionDisplay(option: any): string {
        if (option?.display_name) {
            return option.display_name;
        } else if (option?.displayName) {
            return option.displayName;
        } else if (option?.name) {
            return option.name;
        } else {
            return option;
        }
    }

    institutionName(c: Patient): string | undefined {
        return c.institution_name;
    }

    isCaregiver(c: Patient): boolean {
        return c.source == "patient.caregiver";
    }

    isInternal(c: Patient): boolean {
        return !c.source || c.source == "internal";
    }

    isInquiry(c: Patient): boolean {
        return c?.object?.type == "program.inquiry";
    }

    isPatient(c: Patient): boolean {
        return !!c.source?.startsWith("patient");
    }

    isPhysicianInquiry(c: Patient): boolean {
        return c.source == "provider.physician";
    }

    isPhysicianStaff(c: Patient): boolean {
        return c.source == "provider.staff";
    }

    onFocusOut(event: any): void {
        this.autoToggleSearch();
    }

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    organizationName(c: Patient): string | undefined {
        return c.pharma.displayName;
    }

    physicianName(c: Patient): string | undefined {
        return c.physician_name;
    }

    protected override postSearch(list: Patient[]): Patient[] {
        if (!this._caseStauses?.length && list.length) this.setStatuses(list);

        return list;
    }

    protected override resolveReferenceObservable(
        ref: OptionalObjectOrReference<Patient>,
    ): Observable<Patient | undefined> {
        if (!ref || ref instanceof Patient) return of(ref);
        if (!ref.id) return of(undefined);
        return this.service
            .retrieve(ref.id)
            .pipe(tap((o: Patient | undefined) => this.list.replaceReference(o)));
    }

    programName(c: Patient): string | undefined {
        return c.program?.displayName;
    }

    resetFilter(event: MouseEvent) {
        this.terminateEvent(event);

        if (this.mode !== "recent") {
            this.filterCasesFormGroup.reset(this.defaultCasesFilter);
        } else if (this.mode === "recent") {
            this.filterPendingInquiriesFormGroup.reset(
                this.defaultPendingInquiriesFilter,
            );
        }
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
    }

    showObject(
        event: MouseEvent,
        obj: ObjectOrReference<Patient>,
        viewOnly: boolean = false,
    ) {
        if (obj instanceof ObjectReference) return;
        const canEdit = this.canEdit(obj);
        const view =
            obj.object.type == "program.case" ? CaseComponent : InquiryComponent;
        const mode = viewOnly || !canEdit ? ObjectViewMode.View : ObjectViewMode.Edit;
        ObjectAdminComponent.showObject<Case | Inquiry | ObjectReference>(
            obj.object,
            view,
            mode,
        );
    }

    setStatuses(patients: Patient[]): void {
        const obj: { [key: string]: boolean } = {};
        patients?.forEach((p: Patient) => (obj[p.pharma.id!] = true));

        const organization_ids = Object.keys(obj).join(",");
        this.caseStatusService
            .list({ owner: `0,${organization_ids}` })
            .subscribe((s) => {
                this.caseStatuses = s as Status[];
            });
    }

    setupCasesFilterDefinitions(): void {
        this.filterCasesDefinitions = [
            {
                displayName: "Type",
                controlName: "casesType",
                placeholder: "Type",
            },
            {
                displayName: "Status",
                controlName: "status",
                placeholder: "Status",
            },
            {
                displayName: "Physician",
                controlName: "physician",
                placeholder: "Physician",
            },
            {
                displayName: "Program",
                controlName: "casesProgram",
                placeholder: "Program",
            },
            {
                displayName: "Country",
                controlName: "casesCountry",
                placeholder: "Country",
            },
            {
                displayName: "Owner",
                controlName: "casesOwner",
                placeholder: "Owner",
            },
            {
                displayName: "Physician Institution",
                controlName: "physicianOrgs",
                placeholder: "Physician Institution",
            },
        ];

        if (this.userOrgs!.length > 1) {
            this.filterPendingInquiriesDefinitions.push({
                displayName: "Organization",
                controlName: "pendingInquiriesOrganizations",
                placeholder: "Organization",
            });
        }
    }

    setupPendingInquiriesDefinitions(): void {
        this.filterPendingInquiriesDefinitions = [
            {
                displayName: "Type",
                controlName: "pendingInquiriesType",
                placeholder: "Type",
            },
            {
                displayName: "Country",
                controlName: "pendingInquiriesCountry",
                placeholder: "Country",
            },
        ];

        if (this.owners.length) {
            this.filterPendingInquiriesOwners.push({
                displayName: "Assigned To",
                controlName: "pendingInquiriesOwner",
                placeholder: "Assigned To",
            });
        }

        if (this.userOrgs!.length > 1) {
            this.filterPendingInquiriesDefinitions.push({
                displayName: "Organization",
                controlName: "pendingInquiriesOrganizations",
                placeholder: "Organization",
            });
        }
    }

    get programs() {
        const programs = this?.currentAccount?.derived_permissions?.filter(
            (p) =>
                p.object_type === Program.object_type &&
                p.permission !== "descendant.object.none" &&
                p.permission !== "object.none",
        );
        const uniquePrograms = new Map<string, DerivedPermission>();
        programs?.forEach((program) => {
            uniquePrograms.set(program.object_id, program);
        });
        return Array.from(uniquePrograms.values());
    }
    getCasesFilterOptions() {
        this.getPhysicians();
        this.getOwners();
        this.getPhysicianOrgs();
    }

    getCountries() {
        this.countryService
            .getCountries()
            .pipe(takeUntilDestroyed(this.destroy))
            .subscribe(() => {
                this.countries = this.countryService.countries;
            });
    }

    getInquiryFilterOptions() {}

    getPhysicians() {
        if (!this.currentAccount?.id) return;

        this.service.getAccountsByKey("physicians").subscribe((res) => {
            this.physicians = res;
        });
    }

    getOwners() {
        this.service.getAccountsByKey("owners").subscribe((res) => {
            this.owners = res;
        });
    }

    getPhysicianOrgs() {
        this.service.physicianOrgs().subscribe((res) => {
            this.physicianOrgs = res;
        });
    }

    sendBulkMessage(e: MouseEvent) {
        this.terminateEvent(e);
        if (!this.canBulkAction) return;

        const patients = this.list.items;

        let owners = this.userOrgs;
        let sources = this.userOrgs;
        if (
            this.casesOrgsFilterControl?.value?.length &&
            this.casesOrgsFilterControl.touched
        ) {
            owners = this.casesOrgsFilterControl.value;
            sources = this.userOrgs?.filter((o) =>
                this.casesOrgsFilterControl?.value?.includes(o?.id),
            );
        }

        const owner = owners?.map((o: any) => (o?.id ? o.id : o)).join(",") ?? "";

        this.dialog
            .open(SendTemplateDialog, {
                data: {
                    owner: owner,
                    bulkMode: true,
                    allowInvite: false,
                    sources: sources,
                    repository: this.currentAccount,
                    uploadOwner: this.currentAccount,
                    contacts: patients,
                    send_notification: false,
                },
                disableClose: true,
                hasBackdrop: true,
            })
            .afterClosed()
            .subscribe((data: BulkMessageData | undefined) => {
                if (!data || !data.targets?.length) return;

                this.messageService.bulkMessage(data).subscribe({
                    next: () => {
                        this.snackbar.open(
                            "Message successfully sent to all recipients.",
                            undefined,
                            { duration: 5000 },
                        );
                    },
                    error: (err) => this.session.handleError(err),
                });
            });
    }

    toggleFilter(event: MouseEvent): void {
        if (this.mode !== "recent") {
            this.showCasesFilter = !this.showCasesFilter;
            if (this.showCasesFilter && !this.physicianOrgs.length) {
                this.getCasesFilterOptions();
            }
            this.showPendingInquiriesFilter = false;
        } else {
            this.showPendingInquiriesFilter = !this.showPendingInquiriesFilter;
            if (this.showPendingInquiriesFilter && !this.programs.length) {
                this.getInquiryFilterOptions();
            }
            this.showCasesFilter = false;
        }
    }

    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
    }

    handleCountryFlagError(country: Country) {
        if (country instanceof Country) {
            country.flag_url = this.countryService.fallbackFlagUrl;
        }
    }
    invalidRequestToolTip(request: Patient) {
        if (!request.country_valid) {
            return Country.countryInvalidToolTip;
        }

        if (!request.product_valid && request.object.type === Inquiry.object_type) {
            return Product.invalidProductInquiryToolTip;
        } else if (!request.product_valid && request.object.type === Case.object_type) {
            return Product.invalidProductCaseToolTip;
        }

        return "";
    }
}
