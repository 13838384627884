import { AfterViewInit, Component, Input } from "@angular/core";
import { Program } from "src/services/models/program";
import { BaseDataTypeComponent } from "./data-type.component";
import { Product } from "src/services/models/product";

@Component({
    selector: "dt-program",
    template: `
        <p *ngIf="viewOnly && !hasValues && compoundIndex == 0" class="secondary">
            Value not provided
        </p>
        <p *ngIf="viewOnly && hasValues">{{ displayValue }}</p>
        <mat-form-field class="column" [ngClass]="inputClass" *ngIf="!viewOnly">
            <mat-label>{{ label }}</mat-label>
            <mat-select
                [placeholder]="placeholder"
                [formControl]="control"
                [required]="isRequired"
            >
                <mat-option *ngFor="let product of productOptions" [value]="product">
                    {{ productDisplay(product) }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="!!instructions">
                <quill-view-html [content]="instructions"></quill-view-html>
            </mat-hint>
            <mat-error *ngIf="hasError">{{ errorMessage }}</mat-error>
        </mat-form-field>
    `,
    styleUrls: ["../data-form.component.scss"],
})
export class DataTypeProgram extends BaseDataTypeComponent implements AfterViewInit {
    protected _productOptions: Program[] = [];
    @Input() set productOptions(v: Program[]) {
        this._productOptions = v;
        this.updateSelection();
    }
    get productOptions(): Program[] {
        return this._productOptions;
    }

    updateSelection(): void {
        let option = undefined;
        if (this.control?.value) {
            if (
                this.control.value instanceof Program ||
                (typeof this.control.value == "object" &&
                    this.control.value.hasOwnProperty("id"))
            ) {
                option = this.productOptions.find(
                    (opt: Program) => opt.id == this.control.value.id,
                );
            } else if (typeof this.control.value == "string") {
                const id = this.control.value.split(":");
                if (id.length > 1)
                    option = this.productOptions.find(
                        (opt: Program) => opt.id == id[1],
                    );
            }
            if (option) this.control?.setValue(option);
        } else this.control?.setValue(option);
    }
    ngAfterViewInit(): void {
        this.updateSelection();
    }

    productDisplay(product?: Program | Product | string): any {
        if (product instanceof Product) return product.displayName;
        if (
            product instanceof Program ||
            (typeof product === "object" &&
                (product as any).hasOwnProperty("drug_name"))
        ) {
            return product.drug_name ?? product.displayName;
        }
        return product;
    }
    get displayValue(): string {
        return this.productDisplay(this.control.value);
    }

    protected getErrorMessage(): string | undefined {
        const msg = super.getErrorMessage();
        if (msg) return msg;
        else if (this.control.hasError("invalidAutoComplete"))
            return "Invalid selection, please select an option from the dropdown.";
        return undefined;
    }
}
