import { TeamMember, TeamOwner } from "src/services/models/team";
import { Component, inject } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, of } from "rxjs";
import { first, map, mergeMap, tap } from "rxjs/operators";
import { ConfirmDialog } from "src/common/components/confirm.dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { UpdateEmailDialog } from "./updateEmail.dialog";
import { AccountService, OrganizationService } from "src/services/iam.services";
import {
    ObjectComponent,
    ObjectViewMode,
} from "src/common/components/object.component";
import { Account } from "src/services/models/account";
import { ObjectFactory, ObjectReference } from "src/services/models/api-object";
import { EmailValidator } from "src/common/utilities/validators";
import {
    DerivedPermission,
    Role,
    RoleDefinition,
    roleDisplay,
} from "src/services/models/role";
import {
    ProgramCountryService,
    ProgramService,
    TeamMemberService,
} from "src/services/program.services";
import { Organization } from "src/services/models/organization";
import { Capability } from "src/services/models/capability";
import { Program, ProgramCountry } from "src/services/models/program";

@Component({
    selector: "account",
    templateUrl: "./account.component.html",
    styleUrls: ["./account.component.scss"],
})
export class AccountComponent extends ObjectComponent<Account> {
    protected organizationService: OrganizationService;
    protected teamMemberService: TeamMemberService;
    protected programService: ProgramService;
    filteredOrganizations_: BehaviorSubject<ObjectReference[]> = new BehaviorSubject<
        ObjectReference[]
    >([]);
    isLoading: boolean = false;
    _teamMember?: TeamMember;
    organization?: Organization | ObjectReference;
    teamMembers: TeamMember[] = [];
    orgEmails: string[] = [];
    objectName: string = "Account";
    capabilities: Capability[] = [];
    countryNames: string = "";
    countryList: string[] = [];
    permissionList: string = "";
    selectedPermission: string = "";
    selectedProgramId: string | null = null;
    programs: Program[] = [];
    selectedTabIndex: number = 0;
    teamOwner?: TeamOwner;

    get availableRoles(): RoleDefinition[] {
        return Role.roles;
    }
    get teamMember(): TeamMember | undefined {
        return this._teamMember;
    }
    set teamMember(v: TeamMember | undefined) {
        this._teamMember = v;
        const isPublic = !this.teamMember?.private;
        this.publicControl.setValue(isPublic, { emitEvent: false });
        this.publicControl.valueChanges.pipe(first()).subscribe(() => {
            this.formGroup.markAsDirty();
        });
        this.updateTeamMemberPermission();
    }
    get isOrganizationAdministrator(): boolean {
        return !!this.currentAccount?.hasRole(
            "object.admin",
            this.teamMember?.permission?.object,
        );
    }

    getProgramOptions(): Observable<Program[]> {
        const organization = this.organization;
        if (!organization?.id || !this.currentAccount?.id) {
            return of([]);
        }
        const programs = this.programService
            .list({
                organization: organization.id,
                admin: this.currentAccount.id,
                use_reference: "True",
            })
            .pipe(
                map((response) => response as Program[]),
                map((programs) => programs.filter((program) => !program.deleted)),
                tap((programs) => {
                    this.programs = programs;
                }),
            );
        return programs;
    }

    programCountrySerivce: ProgramCountryService;
    constructor(protected service: AccountService) {
        super(service);
        this.organizationService = inject(OrganizationService);
        this.teamMemberService = inject(TeamMemberService);
        this.programService = inject(ProgramService);
        this.programCountrySerivce = inject(ProgramCountryService);
    }

    protected onAutosave(value: any): void {
        this.emailNotificationsControl?.disable();
        super.onAutosave(value);
    }
    ngOnInit() {
        // no dialog means user is looking at their account settings, otherwise they are adding/editing staff in a dialog from the staff component
        if (this.teamMember && !this.teamMember?.account && this.dialogReference) {
            this.object = ObjectFactory.makeObject<Account>(
                {},
                Account.object_type,
            ) as Account;
            if (this.teamMember?.permission && this.isSystemAdministrator) {
                this.fullObject?.roles.push(this.teamMember.permission);
            }
        }
    }
    get teamOwnerIsProgram() {
        return this?.teamOwner instanceof Program;
    }

    get teamOwnerIsOrganization() {
        return this.teamOwner instanceof Organization;
    }

    private _programCountries: ProgramCountry[] = [];

    get programCountries(): ProgramCountry[] {
        return this._programCountries;
    }

    set programCountries(value: ProgramCountry[]) {
        this._programCountries = value;
        // Add any additional logic here if needed
    }

    getProgramCountryOptions() {
        if (!this.teamOwner?.id) {
            return of([]);
        }

        const obs = this.programCountrySerivce
            .list({ program: this.teamOwner.id })
            .pipe(
                map((response) => response as ProgramCountry[]),
                tap((programCountries) => {
                    this.programCountries = programCountries;
                }),
            );

        return obs;
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();

        if (this.mode != this.ObjectViewMode.Create) {
            this.formGroup.removeControl("email");
        } else {
            this.enforceTeamMemberUniqueEmail();
        }
        //disabling it this way will prevent the 'changed after checked' errors
        if (this.disablePermissionInput) {
            this.formGroup.controls["permission"].disable();
        }
        this.getEmails();
    }
    get emailControl() {
        return this.formGroup.get("email");
    }
    enforceTeamMemberUniqueEmail() {
        this.emailControl?.valueChanges.subscribe((v) => {
            let memberOfOrgTeam = this.teamMembers.some(
                (tm) => tm.email.toLowerCase() === v.toLowerCase(),
            );
            if (this.orgEmails) {
                memberOfOrgTeam ||= this.orgEmails.includes(v.toLowerCase());
            }
            let errors = this.emailControl?.errors || null;
            if (!memberOfOrgTeam) {
                if (errors?.["memberOfOrgTeam"]) {
                    delete errors["memberOfOrgTeam"];
                    if (errors.keys().length == 0) {
                        errors = null;
                    }
                }
            } else {
                if (errors === null) errors = {};
                errors["memberOfOrgTeam"] = true;
            }

            this.emailControl?.setErrors(errors);
        });
    }
    get disablePermissionInput() {
        //if there is a team member account, means user is looking at existing account
        if (this.teamMember?.account) {
            return this.currentAccount?.id == this.teamMember.account?.id;
        }
        //otherwise user is creating new account so account will be undefined
        return false;
    }

    publicControl = new FormControl<boolean>(true);

    protected createObjectForm(): UntypedFormGroup {
        if (this.mode !== ObjectViewMode.Create) {
            return this.formBuilder.group({
                email: [null, [Validators.required, EmailValidator]],
                first_name: [null, Validators.required],
                last_name: [null, Validators.required],
                phone: [null],
                roles: [[]],
                permission: [null],
                status: [null],
                settings: this.formBuilder.group({
                    email_notifications: [true],
                }),
                capability: [null],
                countries: [null],
                country_permission: [null],
                program_id_permission: [null],
            });
        }

        return this.formBuilder.group({
            email: [null, [Validators.required, EmailValidator]],
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            phone: [null],
            roles: [[]],
            permission: [null, Validators.required],
            status: [null],
            capability: [null],
            countries: [null],
            country_permission: [null],
            program_id_permission: [null],
        });
    }

    get accountSettingsFormGroup() {
        return this.formGroup.get("settings") as UntypedFormGroup;
    }
    get emailNotificationsControl() {
        return this.accountSettingsFormGroup.get("email_notifications");
    }
    get emailNotificationsEnabled() {
        return this.emailNotificationsControl?.value;
    }
    get roles(): Role[] {
        return this.formGroup?.controls.roles?.value;
    }
    set roles(v: Role[]) {
        this.formGroup?.controls.roles?.patchValue(v);
        this.formGroup?.controls.roles?.markAsDirty();
    }

    get canResetPassword(): boolean {
        return (
            (this.fullObject?.jwt_sub !== "sso" &&
                (this.fullObject?.is_active || this.fullObject?.is_invited)) ??
            false
        );
    }

    resetPassword(event: MouseEvent): void {
        this.terminateEvent(event);

        // as a precaution, do not initiate password reset for SSO users
        if (this.fullObject?.jwt_sub === "sso") return;

        this.service.resetPassword(this.object!).subscribe({
            next: () => {
                if (this.currentAccount?.id == this.object?.id) {
                    this.session.logout();
                } else
                    this.session.message =
                        "Password reset email successfully sent. Please check your email for the next step.";
            },
            error: (err: HttpErrorResponse) => {
                this.session.message = "Unable to reset password.";
            },
        });
    }

    get accountSetUp(): boolean {
        return (this.object as Account)?.is_active;
    }
    get canResendActivation(): boolean {
        return !!(this.object as Account)?.is_invited;
    }

    get canEditEmail(): boolean {
        // accounts can only edit their email
        return (this.object as Account)?.email === this.currentAccount?.email;
    }

    resendActivationEmail(event: MouseEvent) {
        this.terminateEvent(event);

        this.dialog
            .open(ConfirmDialog, {
                data: {
                    message:
                        "Are you sure you want to resend the activation email to this account?",
                },
                disableClose: true,
                hasBackdrop: true,
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                mergeMap((confirm: boolean) => {
                    if (confirm) {
                        return this.service.resendActivationEmail(this.object!);
                    } else {
                        return of(null);
                    }
                }),
            )
            .subscribe();
    }
    get capabilityControl() {
        return this.formGroup.get("capability") as FormControl<Capability[]>;
    }
    protected updatePermission(
        account: Account | undefined,
    ): Observable<Account | undefined> {
        const oldPermission = this.teamMember?.permission?.role ?? "object.view";
        const newPermission =
            this.formGroup.controls.permission.value?.value || oldPermission;
        const exists = this.teamMember?.id ?? false;
        const capabilityChanged =
            this.capabilityControl?.value &&
            this.capabilityControl.touched &&
            this.capabilityControl.dirty;

        if (
            !account ||
            !this.teamMember ||
            (exists &&
                oldPermission == newPermission &&
                !this.publicControl.dirty &&
                !capabilityChanged)
        ) {
            return of(account);
        }

        this.teamMember.private = !this.publicControl.value;
        const accountReference = account.asReference;

        if (this.teamMember.permission) {
            this.teamMember.permission.role = newPermission;
        } else {
            this.teamMember.permission = ObjectFactory.makeObject<Role>(
                {
                    object: this.organization?.asReference,
                    account: accountReference,
                    role: newPermission,
                    private: this.teamMember.private,
                },
                Role.object_type,
            ) as Role;
        }

        if (!exists) {
            this.teamMember.account = accountReference;
            // FIX FOR MED-1539, creating duplicate permissions.
            // Otherwise the teamMember service creates another role object when the account service already creates it
            if (
                account.roles.length == 1 &&
                account.roles[0].role === this.teamMember.permission?.role
            ) {
                this.teamMember.permission = account.roles[0];
            } else if (this.teamMember.permission) {
                this.teamMember.permission.account = accountReference;
            }
        }
        if (this.teamMember.permission)
            this.teamMember.permission.private = this.teamMember.private;

        if (capabilityChanged) {
            this.teamMember.capabilities = this.capabilityControl?.value.map((v) => {
                const obj = ObjectFactory.makeObject<Capability>(
                    {
                        ...v,
                    },
                    Capability.object_type,
                );
                return obj as Capability;
            });
        }

        const obs =
            exists ?
                this.teamMemberService.update(this.teamMember)
            :   this.teamMemberService.create(this.teamMember);
        return obs.pipe(map(() => account));
    }

    protected precommitTransform(v: any) {
        if (this.mode !== ObjectViewMode.Create) {
            v.settings =
                v.settings ?
                    v.settings
                :   (this.formGroup.controls.settings.value ?? null);
        }
        return v;
    }

    protected commit(v: any): Observable<Account | undefined> {
        const obs = super.commit(v);

        this.emailNotificationsControl?.disable();
        return obs.pipe(mergeMap((v: Account | undefined) => this.updatePermission(v)));
    }

    protected onCommitSuccess(v: Account): boolean {
        const result = super.onCommitSuccess(v);
        this.snackbar.open("Account changes successfully saved.", undefined, {
            duration: 2000,
        });
        this.emailNotificationsControl?.enable();
        return result;
    }

    get filteredCount(): number {
        return this.filteredOrganizations_.getValue().length;
    }
    get filteredOrganizations(): Observable<ObjectReference[]> {
        return this.filteredOrganizations_.asObservable();
    }
    objectDisplay(object?: ObjectReference): string {
        return object?.displayName ?? "";
    }

    protected setObject(v?: Account | undefined): void {
        super.setObject(v);
        if (this.teamOwnerIsOrganization) {
            this.getProgramOptions().subscribe();
        } else if (this.teamOwnerIsProgram && this.teamOwner?.id) {
            this.onProgramChange(this.teamOwner.id);
            this.getProgramCountryOptions().subscribe();
        }

        this.updateTeamMemberPermission();
    }

    protected onCommitError(res: HttpErrorResponse): void {
        super.onCommitError(res);

        if (res.error && "email" in res.error) {
            this.snackbar.open(
                "An account already exists with this email.",
                undefined,
                { duration: 4000 },
            );
        }
        this.emailNotificationsControl?.enable();
        this.session.handleError(res);
    }

    protected onProgramChange(programId: string): void {
        this.selectedProgramId = programId;

        const account =
            this.teamMember?.account instanceof Account ?
                this.teamMember.account
            :   this.fullObject;

        if (!account?.derived_permissions) {
            this.countryList = [];
            this.formGroup.controls.countries.setValue([]);
            return;
        }

        const matchingItems = account.derived_permissions.filter((item: any) => {
            return (
                item.root_program?.id === programId &&
                item.object_type === "program.programcountry"
            );
        });

        const countryNames = matchingItems.map((item: any) => {
            const parts = item.object_display_name.split("|");
            return parts.length > 1 ? parts[1].trim() : "";
        });

        this.countryList = countryNames;
        this.setFormControlValues({
            countries: countryNames,
            program_id_permission: programId,
            country_permission: this.getPermissions(programId),
        });
    }

    protected getPermissions(programId: string): string {
        const account = this.teamMember?.account as Account;

        if (!account?.roles) {
            return "";
        }

        const permissions = account.roles
            .filter((role: any) => role.object?.id === programId)
            .map((role: any) => role.role);

        const matchingRole = Role.roles.find((role) =>
            permissions.includes(role.value),
        );

        return matchingRole ? matchingRole.value : "";
    }

    protected onPermissionChange(permission: string): void {
        if (this.formGroup) {
            this.formGroup.markAsDirty();
        }
        if (this.teamOwnerIsProgram) {
            //handle creating/updating program country permissions
            const countries = this.formGroup?.get("countries")?.value ?? [];

            const roles: Role[] = countries
                .map((country: string) => {
                    //first find role that matches the permission and program country
                    // if found, update role with new permission and return that
                    //otherwise return new Role with no id
                    let role = this.fullObject?.roles.find((role) => {
                        return (
                            role?.object?.displayName?.includes(country) &&
                            role.object?.type === ProgramCountry.object_type &&
                            (role.object as ProgramCountry).program?.id ===
                                this.teamOwner?.id //by the time we are in this view, the programcountry object should already within the cache
                        );
                    });

                    if (role) {
                        role = new Role({ ...role });
                        role.role = permission;
                        return role;
                    }
                    const derivedPermission = this.fullObject?.derived_permissions.find(
                        (permission) => {
                            return (
                                permission.object_display_name.includes(country) &&
                                permission.object_type === ProgramCountry.object_type &&
                                permission.root_program?.id === this.teamOwner?.id
                            );
                        },
                    );

                    if (!derivedPermission) return undefined;
                    return new Role({
                        account: this.teamMember?.account,
                        role: permission,
                        object: new ObjectReference({
                            id: derivedPermission?.object_id,
                            name: derivedPermission?.object_display_name,
                            type: ProgramCountry.object_type,
                        }),
                    });
                })
                .filter((role?: Role) => !!role);

            const currentRoles = this.formGroup.controls.roles.value ?? [];
            const updatedRoles = currentRoles.map((role: Role) => {
                const updatedRole = roles.find(
                    (r: Role) => r.object?.id === role.object?.id,
                );
                return updatedRole ?? role;
            });

            this.formGroup.controls.roles.setValue(updatedRoles);
        } else {
            this.selectedPermission = permission;
            this.setFormControlValues({
                country_permission: permission,
            });
        }
    }

    protected onCountriesChange(countries: string): void {
        if (this.formGroup) {
            this.formGroup.markAsDirty();
        } else {
            console.error("formGroup is not available");
        }
        this.countryNames = countries;
        this.setFormControlValues({
            countries,
        });
    }

    protected updateTeamMemberPermission(): void {
        const roles = (this.teamMember?.permission?.role.split("|") ?? [])
            .map((r: string) =>
                this.availableRoles.find((ar: RoleDefinition) => ar.value == r),
            )
            .filter((rd: RoleDefinition | undefined) => !!rd) as RoleDefinition[];
        const defaultRole = this.availableRoles.find(
            (ar: RoleDefinition) => ar.value == "object.view",
        );
        this.formGroup.controls.permission.setValue(
            roles?.length ? roles[0] : defaultRole,
        );

        if (this.teamMember?.capabilities) {
            this.capabilityControl?.setValue(this.teamMember.capabilities);
        }
    }

    updateEmail(event: MouseEvent) {
        this.terminateEvent(event);
        if (this.currentAccount?.email) {
            const { email } = this.currentAccount;
            this.dialog.open(UpdateEmailDialog, {
                width: "40%",
                data: {
                    email,
                },
                disableClose: true,
                hasBackdrop: true,
            });
        }
    }

    get publicCheckboxToolTip() {
        return "By making this user public they will be visible to external users. If you want this user to have tasks assigned to them or have messages sent to them, leave this box checked.";
    }

    get accountIsAlreadyMemberMessage() {
        return "An account with this email is already a member of your organization.";
    }

    private setFormControlValues(values: { [key: string]: any }): void {
        Object.keys(values).forEach((key) => {
            if (this.formGroup.controls[key]) {
                this.formGroup.controls[key].setValue(values[key]);
            }
        });
    }

    programDerivedPermissionDisplay(permission: DerivedPermission | string) {
        const displayName =
            typeof permission === "object" && "object_display_name" in permission ?
                permission.object_display_name
            :   permission;

        const parts = displayName.split("|");
        return parts.length > 1 ? parts[1].trim() : displayName;
    }

    get programCountryTableColumns() {
        return ["country", "actions"];
    }

    roleDisplay = roleDisplay;

    permissionIsInherited(permission: DerivedPermission) {
        //return permission.inherited;
        return permission.permission_type === "inherited";
    }

    get teamOwnerType() {
        return this.teamOwner?.type;
    }
    get addStaffPermissionHint() {
        const orgStaffHint =
            "Staff that do not require Organization level permission should be added through Program Settings or Country Settings.";
        const prgStaffHint =
            "Staff that do not require Program level permission should be added through Country Settings.";
        if (this.teamOwnerType == "program.program") return prgStaffHint;
        return orgStaffHint;
    }

    getEmails() {
        const list = [];
        if (
            this.teamOwner instanceof Organization ||
            this.teamOwner instanceof Program
        ) {
            const org_id = this.organization?.id;
            if (org_id) list.push(org_id);
        }

        if (!!this.organization && this.teamOwner instanceof Program) {
            const prog_id = this.teamOwner?.id;
            if (prog_id) list.push(prog_id);
        }

        this.teamMemberService.getMemberEmails(list).subscribe((data) => {
            this.orgEmails = data;
        });
    }
}
