import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
    inject,
} from "@angular/core";
import { FormControl, FormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { Sort } from "@angular/material/sort";
import { debounceTime, filter, map, of } from "rxjs";

import {
    DateFilterConfig,
    FilterDefinition,
} from "src/common/components/data-filter/data-filter.component";
import { DataFormComponent } from "src/common/components/data-form/data-form.component";
import { ObjectViewMode } from "src/common/components/object.component";
import { ObjectAdminComponent } from "src/common/components/object-admin.component";
import { setDateDisableControls } from "src/common/utilities/date-control-utils";
import { compareArrayValues, convertDate } from "src/common/utilities/utilities";
import { CaseComponent } from "src/program/components/case/case.component";
import { Case } from "src/services/models/case";
import { DataForm } from "src/services/models/data";
import { Shipment } from "src/services/models/shipment";
import {
    CaseService,
    CaseTeamService,
    InquiryService,
    PatientService,
    ProgramService,
} from "src/services/program.services";
import { RequestFilter } from "src/common/utilities/request";
import { ShipmentService } from "src/services/shipping.services";
import { DataFieldValueService } from "src/services/data.services";
import { CaseTeam } from "src/services/models/team";
import {
    ObjectOrReference,
    PaginatedList,
    ProgramReference,
} from "src/services/models/api-object";
import { Account } from "src/services/models/account";
import { AccountService } from "src/services/iam.services";
import { Program } from "src/services/models/program";

@Component({
    selector: "global-shipment-list",
    templateUrl: "./global-shipment-list.component.html",
    styleUrls: ["./global-shipment.component.scss"],
})
export class GlobalShipmentListComponent extends ObjectAdminComponent<Shipment> {
    @ViewChild("search") searchElement?: ElementRef;
    @ViewChild("filter") filterElement?: MatSelect;

    betweenReceivedEndDate = new FormControl();
    betweenReceivedStartDate = new FormControl();
    betweenRequestedEndDate = new FormControl();
    betweenRequestedStartDate = new FormControl();
    caseService: CaseService;
    inquiryService: InquiryService;
    dateFilterConfigurations: DateFilterConfig[] = [
        {
            betweenDateLabel: "Requested Date Between",
            betweenStartDateControlName: "betweenRequestedStartDate",
            betweenEndDateControlName: "betweenRequestedEndDate",
            dateBeforeLabel: "Requested Date Before",
            dateBeforeControlName: "dateRequestedBefore",
            dateAfterLabel: "Requested Date After",
            dateAfterControlName: "dateRequestedAfter",
        },
        {
            betweenDateLabel: "Received Date Between",
            betweenStartDateControlName: "betweenReceivedStartDate",
            betweenEndDateControlName: "betweenReceivedEndDate",
            dateBeforeLabel: "Received Date Before",
            dateBeforeControlName: "dateReceivedBefore",
            dateAfterLabel: "Received Date After",
            dateAfterControlName: "dateReceivedAfter",
        },
    ];
    dateReceivedAfter = new FormControl();
    dateReceivedBefore = new FormControl();
    dateRequestedAfter = new FormControl();
    dateRequestedBefore = new FormControl();
    filterDefinitions: FilterDefinition[] = [];
    filterFormGroup: FormGroup;
    institutionFilterControl = new FormControl();
    institutions: string[] = [];
    physicianFilterControl = new FormControl();
    physicians: ObjectOrReference<Account>[] = [];
    programFilterControl = new FormControl();

    searchTermControl: UntypedFormControl = new UntypedFormControl();
    shipmentTypeFilterControl: UntypedFormControl = new UntypedFormControl();
    shipmentTypes: string[] = ["Initial", "Resupply"];
    showFilter: boolean = false;
    showSearch: boolean = false;

    get columns() {
        return [
            "reference_identifier",
            "case_reference",
            "case_name",
            "program_name",
            "physician",
            "physician_institution",
            "request_type",
            "date_requested",
            "date_received",
        ];
    }

    get defaultFilter() {
        return {
            program: [],
            physician: [],
            institution: [],
            shipmentType: this.shipmentTypes,
        };
    }

    get isSearchEmpty(): boolean {
        return !this.showSearch && this.searchTermControl.value == undefined;
    }

    get isFilterDefault(): boolean {
        const defaultFilter = this.defaultFilter;

        return (
            compareArrayValues(
                this.programFilterControl.value,
                defaultFilter.program,
            ) &&
            compareArrayValues(
                this.physicianFilterControl.value,
                defaultFilter.physician,
            ) &&
            compareArrayValues(
                this.institutionFilterControl.value,
                defaultFilter.institution,
            ) &&
            compareArrayValues(
                this.shipmentTypeFilterControl.value,
                defaultFilter.shipmentType,
            )
        );
    }

    get shipmentTypesArray() {
        return this.shipmentTypes;
    }
    programService: ProgramService;
    patientService: PatientService;
    accountService: AccountService;
    caseTeamService: CaseTeamService;
    constructor(
        protected service: ShipmentService,
        protected changeDetection: ChangeDetectorRef,
    ) {
        super(service, changeDetection, 10, "shipments");
        this.caseService = inject(CaseService);
        this.programService = inject(ProgramService);
        this.inquiryService = inject(InquiryService);
        this.patientService = inject(PatientService);
        this.accountService = inject(AccountService);
        this.caseTeamService = inject(CaseTeamService);
        inject(DataFieldValueService);
        this.list.ordering = [{ field: "created_at", ascending: false }];
        this.filterFormGroup = new FormGroup({
            betweenReceivedEndDate: this.betweenReceivedEndDate,
            betweenReceivedStartDate: this.betweenReceivedStartDate,
            betweenRequestedEndDate: this.betweenRequestedEndDate,
            betweenRequestedStartDate: this.betweenRequestedStartDate,
            dateReceivedAfter: this.dateReceivedAfter,
            dateReceivedBefore: this.dateReceivedBefore,
            dateRequestedAfter: this.dateRequestedAfter,
            dateRequestedBefore: this.dateRequestedBefore,
            institution: this.institutionFilterControl,
            physician: this.physicianFilterControl,
            program: this.programFilterControl,
            shipmentType: this.shipmentTypeFilterControl,
        });
        this.setupFilterDefinitions();
    }
    updateDashboardRole(): void {
        this.accountService
            .dashboardRole(this.currentAccount)
            .subscribe(
                (role: any) => (this.dashboardRole = role ? role["role"] : "none"),
            );
    }

    ngOnInit() {
        this.updateDashboardRole();
        setDateDisableControls([
            {
                betweenStartDate: this.betweenRequestedStartDate,
                betweenEndDate: this.betweenRequestedEndDate,
                dateBefore: this.dateRequestedBefore,
                dateAfter: this.dateRequestedAfter,
            },
            {
                betweenStartDate: this.betweenReceivedStartDate,
                betweenEndDate: this.betweenReceivedEndDate,
                dateBefore: this.dateReceivedBefore,
                dateAfter: this.dateReceivedAfter,
            },
        ]);
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        if (!this.shipmentTypeFilterControl.value) {
            this.shipmentTypeFilterControl.setValue(this.defaultFilter);
        }

        this.searchTermControl.valueChanges
            .pipe(
                debounceTime(400),
                filter(() => !!this.list),
                filter(
                    (term: string) =>
                        !term || term.length >= this.list.minimumFilterLength,
                ),
            )
            .subscribe((term: string) => {
                this.updateList(term);
            });

        this.filterFormGroup.valueChanges
            .pipe(debounceTime(400))
            .subscribe(() => this.list.refresh());
        this.updateList();
    }

    autoToggleFilter(): void {
        if (!this.shipmentTypeFilterControl.value || this.isFilterDefault) {
            this.shipmentTypeFilterControl.setValue(this.defaultFilter);
            this.showFilter = false;
        }
    }

    autoToggleSearch(): void {
        if (!this.searchTermControl.value) {
            this.searchTermControl.setValue(undefined);
            this.showSearch = false;
        }
    }

    cancel(event: MouseEvent) {
        this.terminateEvent(event);
        this.showFilter = false;
        this.filterFormGroup.markAsUntouched();
        this.filterFormGroup.markAsPristine();
        this.filterFormGroup.reset(this.defaultFilter);
    }

    clearAllFilters(event: MouseEvent) {
        this.terminateEvent(event);
        this.filterFormGroup.reset();
        this.filterFormGroup.markAllAsTouched();
    }

    caseTeamForUser(shipment: Shipment) {
        return shipment.inquiry?.caseTeam(this.currentAccount);
    }

    editForm(shipment: Shipment) {
        if (!this.isObject(shipment)) return;
        const dialogReference = this.showDataFormDialog(ObjectViewMode.Edit, shipment);
        dialogReference?.afterClosed().subscribe((form?: DataForm) => {
            // JT - Data normalization moved to backend
        });
    }

    protected filter(filters: RequestFilter): RequestFilter {
        filters = super.filter(filters);
        filters["access"] = this.currentAccount?.id ?? "0";
        const programs = this.programFilterControl.value || [];

        if (programs.length) filters["program"] = programs.join(",");
        const physicians = this.physicianFilterControl.value || [];
        if (physicians.length) filters["physician"] = physicians.join(",");
        const institutions = this.institutionFilterControl.value || [];
        if (institutions.length) filters["institution"] = institutions.join(",");
        if (Array.isArray(this.shipmentTypeFilterControl.value)) {
            filters["status"] = this.shipmentTypeFilterControl.value.join(",");
        }
        applyDateFilters(
            filters,
            this.betweenReceivedStartDate?.value,
            this.betweenReceivedEndDate?.value,
            this.betweenRequestedStartDate?.value,
            this.betweenRequestedEndDate?.value,
            this.dateReceivedAfter?.value,
            this.dateReceivedBefore?.value,
            this.dateRequestedAfter?.value,
            this.dateRequestedBefore?.value,
        );
        return filters;
    }

    getOptionValue(option: any): any {
        if (option?.id) {
            return option.id;
        }
        return option;
    }

    getOptionDisplay(option: any): string {
        if (option?.name) {
            return option.name;
        }
        return option.displayName ? option.displayName : option;
    }

    isResupply(shipment: Shipment): boolean {
        return shipment.request_type?.toLowerCase() == "resupply";
    }

    onFocusOut(event: any): void {
        this.autoToggleFilter();
        this.autoToggleSearch();
    }

    // for now, these don't do anything but to satify the linter
    onKeyDown(event: KeyboardEvent): void {}
    onKeyPress(event: KeyboardEvent): void {}
    onKeyUp(event: KeyboardEvent): void {}

    onSortChange(event: Sort): void {
        if (event.direction) {
            this.list.ordering = [
                { field: event.active, ascending: event.direction == "asc" },
            ];
        } else this.list.ordering = [];
    }

    openCase(shipment: Shipment) {
        if (!this.isObject(shipment)) return;

        const teams =
            shipment.inquiry?.teams ?
                of(shipment.inquiry?.teams)
            :   this.caseTeamService.getCaseTeams(
                    shipment.inquiry!,
                    this.dashboardRole!,
                );
        const caseTeam = teams.pipe(
            map((teams: CaseTeam[]) => {
                shipment.inquiry!.teams = teams;
                return this.caseTeamForUser(shipment);
            }),
        );
        caseTeam.subscribe((team: CaseTeam | undefined) => {
            if (team?.case)
                ObjectAdminComponent.showObject<Case>(
                    team?.case,
                    CaseComponent,
                    ObjectViewMode.Edit,
                );
            this.ngOnDestroy();
        });
    }
    protected postSearch(items: Shipment[]): Shipment[] {
        items = super.postSearch(items);

        return items;
    }
    resetFilter(event: MouseEvent) {
        this.terminateEvent(event);
        this.filterFormGroup.reset(this.defaultFilter);
    }

    resetFilters(event?: MouseEvent): void {
        const defaultFilter = this.defaultFilter;

        this.programFilterControl.setValue(defaultFilter.program);
        this.physicianFilterControl.setValue(defaultFilter.physician);
        this.institutionFilterControl.setValue(defaultFilter.institution);
        this.shipmentTypeFilterControl.setValue(defaultFilter.shipmentType);

        this.showFilter = false;
        this.updateList();
        this.autoToggleSearch();
    }

    resetSearchTerm(event?: MouseEvent): void {
        this.searchTermControl.setValue(undefined);
        this.showSearch = false;
        this.updateList(null);
        this.autoToggleFilter();
    }

    setupFilterDefinitions(): void {
        this.filterDefinitions = [
            {
                displayName: "Program",
                controlName: "program",
                placeholder: "Program",
            },
            {
                displayName: "Physician",
                controlName: "physician",
                placeholder: "Physician",
            },
            {
                displayName: "Institution",
                controlName: "institution",
                placeholder: "Institution",
            },
            {
                displayName: "Shipment Type",
                controlName: "shipmentType",
                placeholder: "Shipment Type",
            },
        ];
    }

    showDataFormDialog(
        mode: ObjectViewMode,
        shipment: Shipment,
    ): MatDialogRef<DataFormComponent> {
        const dialogRef = this.dialog.open(DataFormComponent, {
            maxWidth: "90vw",
            maxHeight: "90vh",
            minWidth: "75vw",
        });

        const componentInstance = dialogRef.componentInstance;
        componentInstance.dialogReference = dialogRef;
        componentInstance.mode = mode;
        componentInstance.autosave = false;
        componentInstance.autosaveOnCreate = false;
        componentInstance.object = shipment.data_form;
        return dialogRef;
    }

    toggleFilter(event: MouseEvent): void {
        this.showFilter = !this.showFilter;
        if (this.showFilter && !this.programs?.length) {
            this.getFilterOptions();
        }

        if (this.showFilter) setTimeout(() => this.filterElement?.focus());
        this.autoToggleSearch();
    }

    toggleSearch(event: MouseEvent): void {
        this.showSearch = !this.showSearch;
        if (this.showSearch)
            setTimeout(() => this.searchElement?.nativeElement.focus());
        this.autoToggleFilter();
    }

    getFilterOptions() {
        this.getPhysicians();
        this.getPhysicianOrgs();
    }

    get programs() {
        const programs = this?.currentAccount?.derived_permissions?.filter(
            (p) =>
                p.object_type === Program.object_type &&
                p.permission !== "descendant.object.none" &&
                p.permission !== "object.none",
        );
        const uniquePrograms = new Map<string, ObjectOrReference<Program>>();
        programs?.forEach((program) => {
            uniquePrograms.set(program.object_id, program.root_program);
        });
        return Array.from(uniquePrograms.values()).sort((a, b) =>
            a.name!.localeCompare(b.name!),
        );
    }

    getPhysicians() {
        if (!this.currentAccount?.id) return;

        this.patientService.getAccountsByKey("physicians").subscribe((res) => {
            this.physicians = res;
        });
    }

    getPhysicianOrgs() {
        this.patientService.physicianOrgs().subscribe((res) => {
            this.institutions = res;
        });
    }
}

function applyDateFilters(
    filters: RequestFilter,
    betweenReceivedStartDate: Date | null,
    betweenReceivedEndDate: Date | null,
    betweenRequestedStartDate: Date | null,
    betweenRequestedEndDate: Date | null,
    dateReceivedAfter: Date | null,
    dateReceivedBefore: Date | null,
    dateRequestedAfter: Date | null,
    dateRequestedBefore: Date | null,
) {
    if (
        betweenReceivedStartDate instanceof Date &&
        betweenReceivedEndDate instanceof Date
    ) {
        const startDateStr = convertDate(betweenReceivedStartDate).join("-");
        const endDateStr = convertDate(betweenReceivedEndDate).join("-");

        filters["between_received"] = `${startDateStr},${endDateStr}`;
    }

    if (
        betweenRequestedStartDate instanceof Date &&
        betweenRequestedEndDate instanceof Date
    ) {
        const startDateStr = convertDate(betweenRequestedStartDate).join("-");
        const endDateStr = convertDate(betweenRequestedEndDate).join("-");
        filters["between_requested"] = `${startDateStr},${endDateStr}`;
    }

    if (dateReceivedAfter instanceof Date) {
        const afterDateStr = convertDate(dateReceivedAfter).join("-");
        filters["date_received_after"] = afterDateStr;
    }

    if (dateReceivedBefore instanceof Date) {
        const beforeDateStr = convertDate(dateReceivedBefore).join("-");
        filters["date_received_before"] = beforeDateStr;
    }

    if (dateRequestedAfter instanceof Date) {
        const afterDateStr = convertDate(dateRequestedAfter).join("-");
        filters["date_requested_after"] = afterDateStr;
    }

    if (dateRequestedBefore instanceof Date) {
        const beforeDateStr = convertDate(dateRequestedBefore).join("-");
        filters["date_requested_before"] = beforeDateStr;
    }
}
