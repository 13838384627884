import { TranslateService } from "@ngx-translate/core";
import { Sort } from "@angular/material/sort";
import { CaseService, InquiryService } from "src/services/program.services";
import {
    ObjectReference,
    OptionalObjectOrReference,
} from "src/services/models/api-object";
import { Component, Input, inject } from "@angular/core";
import { SessionComponent } from "src/services/components/session.component";
import { Case, CaseAudit } from "src/services/models/case";
import { LocalizedDatePipe } from "src/common/utilities/localized-date.pipe";
import { debounceTime, filter, merge, Subscription } from "rxjs";

@Component({
    selector: "case-audit",
    templateUrl: "./case-audit.component.html",
    styleUrls: ["./case-audit.component.scss"],
})
export class CaseAuditComponent extends SessionComponent {
    caseService: CaseService;
    inquiryService: InquiryService;
    auditTrail?: CaseAudit[];
    datePipe: LocalizedDatePipe;

    displayedColumns: string[] = ["timestamp", "account", "type", "event"];
    private subscription?: Subscription;
    protected _case: OptionalObjectOrReference<Case>;
    @Input() set case(v: OptionalObjectOrReference<Case>) {
        this._case = v;
        this.updateAuditTrail(v);
    }
    get case(): OptionalObjectOrReference<Case> {
        return this._case;
    }

    @Input() selected: boolean = false;

    constructor() {
        super();
        this.caseService = inject(CaseService);
        this.inquiryService = inject(InquiryService);
        let translateService = inject(TranslateService);
        this.datePipe = new LocalizedDatePipe(translateService);
    }
    ngOnInit(): void {
        this.subscription = merge(
            this.caseService.relatedObjectEvent,
            this.inquiryService.relatedObjectEvent,
        )
            .pipe(
                filter((event) => {
                    return (
                        event.relation?.id == this.case?.id ||
                        (this.case instanceof Case &&
                            event.relation?.id === this.case?.shared?.id)
                    );
                }),
                debounceTime(1000),
            )
            .subscribe(() => {
                console.log("audit trail updated");
                this.updateAuditTrail(this.case);
            });
    }
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    }

    onSortChange(event: Sort): void {}
    hasGroupDetails(entry: CaseAudit): boolean {
        return (
            !!entry.details?.hasOwnProperty("group_details") ||
            !!entry.details?.hasOwnProperty("updates")
        );
    }
    groupDetails(entry: CaseAudit): any[] {
        if (entry.details?.group_details) return entry.details.group_details;
        if (entry.details?.updates) return [entry.details];
        return [];
    }
    formatAsDate(value: any): any {
        try {
            if (value && typeof value === "string") {
                let date = new Date(value);
                return this.datePipe.transform(date, "medium");
            }
        } catch {}
        return value;
    }
    formatValue(field: string, value: any, dates?: string[], oldValue?: any): string[] {
        let events: string[] = [];
        let fieldIsDate = dates?.indexOf(field) != -1;
        if (Array.isArray(value)) {
            value.forEach((v: any, index: number) => {
                const o =
                    oldValue && oldValue.length > index ? oldValue[index] : undefined;
                events = events.concat(...this.formatValue(field, v, dates, o));
            });
        } else if (value && typeof value == "object") {
            // this is a compound type
            events = events.concat(
                ...this.formatCompoundValue(field, value, dates, oldValue),
            );
        } else if (value || oldValue) {
            let nv = fieldIsDate ? this.formatAsDate(value) : value;
            let ov = fieldIsDate && !!oldValue ? this.formatAsDate(oldValue) : oldValue;

            if (oldValue == value) {
                // do nothing, there's no change
            } else if (oldValue && value) {
                events.push("Changed '" + field + "' from " + ov + " to " + nv);
            } else if (value) {
                events.push("Set '" + field + "' to " + nv);
            } else if (oldValue) {
                events.push("Removed value for '" + field + "'");
            }
        }
        return events;
    }

    formatCompoundValue(
        field: string,
        value: any,
        dates?: string[],
        oldValue?: any,
    ): string[] {
        let events: string[] = [];
        const newValueKeys = Object.keys(value);
        const oldValueKeys = oldValue ? Object.keys(oldValue) : [];
        const keys = new Set([...newValueKeys, ...oldValueKeys]);
        keys.forEach((key: string) => {
            let childField = field + "." + key;
            if (value.hasOwnProperty(key)) {
                let childValue = value[key];
                let childOldValue = oldValue ? oldValue[key] : undefined;
                events = events.concat(
                    ...this.formatValue(childField, childValue, dates, childOldValue),
                );
            } else {
                events.push("Removed value for " + childField);
            }
        });

        return events;
    }

    groupEvent(entry: CaseAudit, detail: any): string[] {
        let events: string[] = [];
        if (entry.details?.group_type == "program.datafieldvalue") {
            let deets = detail.details;
            let field = deets.field;
            let value = deets.value;
            let oldValue = undefined;
            if (deets.action == 2) {
                // this is an update, so pull old a new values
                let valueUpdate = deets.updates.find(
                    (update: any) => update.field == "value",
                );
                value = valueUpdate.value;
                oldValue = valueUpdate.old_value;
            }
            events = this.formatValue(field, value, deets.dates, oldValue);
        } else if (entry.details?.updates) {
            for (let update of entry.details?.updates ?? []) {
                let event = this.formatValue(
                    update.field,
                    update.value,
                    update.dates,
                    update.old_value,
                );
                events = events.concat(...event);
            }
        } else {
            events.push(detail.event);
        }
        return events;
    }

    protected updateAuditTrail(v: Case | ObjectReference | undefined) {
        if (v) {
            this.caseService.audit(v).subscribe((items: CaseAudit[]) => {
                items.reverse();
                this.auditTrail = items;
            });
        } else {
            this.auditTrail = [];
        }
    }
}
