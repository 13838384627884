<div class="intake-app-bar flex mat-elevation-z4" [style.background]="templateBgColor">
    <a [href]="intake?.url ?? activateRouteURL" class="title">
        <img
            *ngIf="templateLogoSource"
            [src]="templateLogoSource"
            class="brand-logo"
            [alt]="intake?.organization?.displayName ?? ''"
        />
        <span *ngIf="!templateLogoSource" [style.color]="templateTextColor">
            {{ intake?.organization?.displayName ?? "" }}
        </span>
    </a>
    <div class="spacer"></div>
    <div class="powered-by flex" [style.filter]="templateMedaLogoColor">
        <div class="tag">powered by</div>
        <img alt="MedaSystems Logo" class="logo" src="/assets/logo-white.png" />
    </div>
</div>
<div class="intake-content-wrapper">
    <div class="content flex">
        <div
            class="loading"
            *ngIf="!loaded || (currentStep == steps.Intake && !selectedForm)"
        >
            <div class="flex stack center-align">
                <div class="title" *ngIf="currentStep == steps.Intake">
                    Retrieving request form...
                </div>
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
        </div>
        <div *ngIf="!intake && loaded" class="flex center-align no-intake">
            <div>
                Please visit the
                <a href="https://medasystems.com">MedaSystems website</a>
                for more information about our Expanded Access management tools.
            </div>
        </div>
        <div
            *ngIf="intake && currentStep == steps.Role"
            class="flex center-align intake-role"
        >
            <div class="flex stack">
                <div>
                    Thanks for your interest.
                    <br />
                    Please tell us about yourself.
                    <br />
                    <span class="ima">I'm a...</span>
                </div>
                <div class="flex row role-actions">
                    <div>
                        <button
                            mat-flat-button
                            class="role-button"
                            (click)="setRole('provider.physician')"
                        >
                            <mat-icon>assignment_turned_in</mat-icon>
                            Healthcare Provider
                        </button>
                    </div>
                    <div *ngIf="allowPatientIntake">
                        <button
                            mat-flat-button
                            class="role-button"
                            (click)="setRole('patient')"
                        >
                            <mat-icon>personal_injury</mat-icon>
                            Patient
                        </button>
                    </div>
                    <div *ngIf="allowFamilyIntake">
                        <button
                            mat-flat-button
                            class="role-button"
                            (click)="setRole('patient.caregiver')"
                        >
                            <mat-icon>family_restroom</mat-icon>
                            Family Member
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="currentStep == steps.CountryProduct" class="flex country-product">
            <div class="flex stack intake-form" [formGroup]="countryProductGroup">
                <div>Tell Us More</div>

                <div class="flex stack">
                    <div class="dropdown-label">Choose your country</div>
                    <mat-form-field class="full">
                        <mat-select
                            formControlName="country"
                            placeholder="Select a Country"
                        >
                            <mat-select-trigger *ngIf="countryControl?.value">
                                <img
                                    *ngIf="countryControl.value"
                                    [src]="countryControl.value.flag_url"
                                    alt="({{ countryControl.value.code }})"
                                    class="country-flag-img"
                                    (error)="
                                        handleCountryFlagError(countryControl.value)
                                    "
                                />
                                {{ countryControl.value.display_name }}
                            </mat-select-trigger>
                            <ng-container *ngIf="availableCountries.length">
                                <mat-option
                                    *ngFor="let country of availableCountries"
                                    [value]="country"
                                >
                                    <img
                                        [src]="country.flag_url"
                                        alt="({{ country.code }})"
                                        class="country-flag-img"
                                        (error)="handleCountryFlagError(country)"
                                    />
                                    {{ country.display_name }}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!availableCountries.length">
                                <mat-option disabled>
                                    Error - Countries list not found
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex stack" *ngIf="hasCountry && productOptions.length">
                    <div class="dropdown-label">Choose a product</div>
                    <mat-form-field class="full">
                        <mat-select
                            formControlName="product"
                            placeholder="Select a Product"
                        >
                            <ng-container *ngIf="productOptions.length">
                                <mat-option
                                    *ngFor="let product of productOptions"
                                    [value]="product"
                                >
                                    {{ product.displayName }}
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!productOptions.length">
                                <mat-option disabled>No products available</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="flex row intake-actions">
                    <div class="spacer"></div>
                    <button mat-raised-button (click)="onBackButton($event)">
                        Back
                    </button>
                    <button
                        [disabled]="!hasCountry || !hasProduct"
                        (click)="hasCountry && hasProduct && onNextButton($event)"
                        mat-raised-button
                        color="accent"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <div
            [class.visible]="currentStep == steps.Intake"
            class="flex intake-data-form"
            *ngIf="!!selectedForm"
        >
            <div class="flex stack intake-form">
                <div class="additional-information">Additional Information</div>
                <data-form
                    [object]="selectedForm"
                    [mode]="ObjectViewMode.Create"
                    [organization]="intake?.organization"
                    [productOptions]="intake?.products?.length ? intake!.products : []"
                    [intake]="true"
                    [hideControls]="true"
                ></data-form>
                <div class="flex row intake-actions">
                    <div class="spacer"></div>
                    <button mat-raised-button (click)="onBackButton($event)">
                        Back
                    </button>
                    <button
                        [disabled]="!formComponent?.isValid"
                        (click)="!!formComponent?.isValid && onNextButton($event)"
                        mat-raised-button
                        color="accent"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="currentStep == steps.Legal" class="flex intake-data-form visible">
            <div class="flex stack intake-form">
                <div class="additional-information">Legal</div>
                <div class="flex stack legal-checks" [formGroup]="legalGroup">
                    <mat-checkbox formControlName="share_auth">
                        I confirm that I have all authorizations required by applicable
                        local law to share the patient data for this submission
                        regardless of whether such data is identified, de-identified, or
                        identifiable.
                    </mat-checkbox>
                    <mat-checkbox formControlName="acknowledge_phi">
                        I understand that any personal health information which I submit
                        will be handled in accordance with the privacy policies of
                        {{ intake!.organization!.displayName }} and MedaSystems.
                    </mat-checkbox>
                    <mat-checkbox formControlName="comm_consent">
                        By submitting this inquiry I consent to receive communications
                        from {{ intake!.organization!.displayName }}.
                    </mat-checkbox>
                </div>
                <div class="flex row intake-actions">
                    <div class="spacer"></div>
                    <button mat-raised-button (click)="onBackButton($event)">
                        Back
                    </button>
                    <button
                        [disabled]="!legalValid"
                        (click)="!!legalValid && onNextButton($event)"
                        mat-raised-button
                        color="accent"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
        <div
            *ngIf="loaded && currentStep == steps.Submitted"
            class="flex center-align intake-role"
        >
            <div class="flex stack">
                <div>
                    <p>Thank you!</p>
                    <p>
                        We’ve received your inquiry. You should get an email
                        confirmation in your inbox shortly.
                    </p>
                    <p *ngIf="responseTime">
                        We usually respond to inquiries within {{ responseTime }}.
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="currentStep == steps.Error" class="flex center-align intake-role">
            <div class="flex stack">
                <div>
                    <p>
                        There was an error processing your inquiry. Please contact the
                        system administrator.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
