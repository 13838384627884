import {
    InquiryService,
    CaseService,
    ProgramService,
    ProgramCountryService,
} from "src/services/program.services";
import {
    OrganizationService,
    AccountService,
    RoleService,
} from "src/services/iam.services";
import { ObjectComponent } from "src/common/components/object.component";
import { Component, inject } from "@angular/core";
import { Role, RoleDefinition } from "src/services/models/role";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Account } from "src/services/models/account";
import {
    APIListResult,
    APIObject,
    ObjectReference,
} from "src/services/models/api-object";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "role",
    templateUrl: "./role.component.html",
    styleUrls: ["./account.component.scss"],
})
export class RoleComponent extends ObjectComponent<Role> {
    objectName: string = "Role/Permission";
    accountService: AccountService;
    caseService: CaseService;
    programService: ProgramService;
    programCountryService: ProgramCountryService;
    organizationService: OrganizationService;
    inquiryService: InquiryService;

    availableAccounts: Account[] = [];
    availableObjects: ObjectReference[] = [];
    availableObjectTypes = [
        { displayName: "Organization", value: "iam.organization" },
        { displayName: "Program", value: "program.program" },
        { displayName: "Program Country", value: "program.programcountry" },
        { displayName: "Case", value: "program.case" },
        { displayName: "Inquiry", value: "program.inquiry" },
    ];

    get availableRoles(): RoleDefinition[] {
        return Role.roles;
    }

    objectType: UntypedFormControl = new UntypedFormControl();

    constructor(protected service: RoleService) {
        super(service);
        this.accountService = inject(AccountService);
        this.caseService = inject(CaseService);
        this.programService = inject(ProgramService);
        this.programCountryService = inject(ProgramCountryService);
        this.organizationService = inject(OrganizationService);
        this.inquiryService = inject(InquiryService);

        this.objectType.valueChanges.subscribe((value: any) =>
            this.onObjectTypeChanged(value),
        );
    }

    protected createObjectForm(): UntypedFormGroup {
        return this.formBuilder.group({
            account: [null, Validators.required],
            object: [null, Validators.required],
            roles: [null, Validators.required],
            private: [false],
        });
    }

    protected setObject(v?: Role): void {
        super.setObject(v);

        if (v?.account) {
            this.availableAccounts = [v.account as Account];
            this.formGroup?.controls.account.disable();
        } else {
            this.formGroup?.controls.account.enable();
            this.updateAvailableAccounts();
        }

        const type = this.availableObjectTypes.find(
            (t: any) => t.value == v?.object?.type,
        );
        this.objectType.setValue(type);
        const roles = (v?.role?.split("|") ?? [])
            .map((r: string) =>
                this.availableRoles.find((ar: RoleDefinition) => ar.value == r),
            )
            .filter((rd: RoleDefinition | undefined) => !!rd) as RoleDefinition[];
        this.formGroup.controls.roles.setValue(roles?.length ? roles[0] : undefined);
    }

    protected precommitTransform(v: any) {
        return {
            account: v.account,
            object: v.object,
            role: v.roles?.value,
            private: v.private,
        };
    }

    protected onObjectTypeChanged(type: any): void {
        let obs: Observable<APIListResult<APIObject>> = of([]);
        if (type?.value == "iam.organization") obs = this.organizationService.list();
        else if (type?.value == "program.program") obs = this.programService.list();
        else if (type?.value == "program.programcountry")
            obs = this.programCountryService.list();
        else if (type?.value == "program.case") obs = this.caseService.list();
        else if (type?.value == "program.inquiry") obs = this.inquiryService.list();
        obs.pipe(
            map((list: APIListResult<APIObject>) =>
                (list as APIObject[])?.map((obj: APIObject) => obj.asReference),
            ),
        ).subscribe((objects: ObjectReference[]) => {
            this.availableObjects = objects;
            const found = this.availableObjects.find(
                (o: ObjectReference) =>
                    o.id == this.formGroup.controls.object?.value?.id,
            );
            this.formGroup.controls.object.setValue(found);
        });
    }

    protected updateAvailableAccounts(): void {
        this.accountService.list().subscribe((accounts: APIListResult<Account>) => {
            this.availableAccounts = (accounts as Account[]) || [];
            const found = this.availableAccounts.find(
                (a: Account) => a.id == this.formGroup.controls.account?.value?.id,
            );
            this.formGroup.controls.account.setValue(found);
        });
    }
}
