<div cdkScrollable (wheel)="onScroll($event)">
    <div class="flex" *ngIf="showExportButton">
        <div class="spacer"></div>
        <button
            mat-flat-button
            class="brand-medium"
            (click)="export($event)"
            [disabled]="exporting"
        >
            <mat-icon matTooltip="Download">file_download</mat-icon>
            Export Details
        </button>
    </div>

    <div *ngFor="let form of list.items; let index = index" class="form-summary">
        <div class="title">{{ form.displayName }}</div>
        <data-form
            [object]="form"
            [repository]="repository"
            [mode]="viewMode"
            [viewOnly]="true"
            [allowExport]="false"
            [useSpinner]="false"
            [organization]="organization"
        ></data-form>
    </div>
    <mat-spinner
        *ngIf="list.searching && canHaveMore"
        mode="indeterminate"
        [diameter]="50"
    ></mat-spinner>
    <div #bottom></div>
</div>
